import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function CollegeConnectSearch({ handleSearchSubmit, handleReset }) {
  const [isFocused, setIsFocused] = useState(false);
  const [query, setQuery] = useState('');

  const searchInputRef = useRef(null);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearchSubmit(query);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);
  return (
    <div className="mx-[10%] text-white flex flex-col md:flex-row gap-4">
      <div className="flex bg-[#000000] custom-border-gradient w-full">
        <FontAwesomeIcon
          icon={faSearch}
          size="lg"
          className="text-white p-4 cursor-pointer"
          onClick={handleSearchSubmit}
        />
        <input
          type="text"
          placeholder={isFocused ? '' : 'Search for colleges'}
          className="bg-transparent p-2 w-full focus:outline-none focus:ring-0"
          value={query}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />        
      </div>
      <input 
        type="submit"
        value="Search"
        className="bg-blue text-black border-blue border-solid border px-4 p-1 cursor-pointer hover:bg-[#000000] hover:text-blue duration-200 ease-in"
        onClick={handleSubmit}
      />
      <button
        className="bg-orange text-black border-red border-solid border px-4 p-1 cursor-pointer hover:bg-[#000000] hover:text-orange duration-200 ease-in"
        onClick={handleReset}
      >Reset</button>
    </div>
  );
}

export default CollegeConnectSearch;

