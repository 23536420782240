// basic react component

import React from 'react';
import PlayerInformation from '../components/search-players/PlayerInformation';

const PlayerInformationPage = () => {
  return (
    <>
        <PlayerInformation />
    </>
  );
}

export default PlayerInformationPage;