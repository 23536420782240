import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { useMultistepContext } from '../../contexts/MultistepProvider';
import { useAuth } from '../../contexts/AuthProvider';

function SportSelection() {
    const { tokens, role } = useAuth();
    const {
        video,
        setStep,
        sports,
        fetchSports,
        selectedSport,
        setSelectedSport,
    } = useMultistepContext();

    useEffect(() => {
        fetchSports();
    }, []);

    const handleChangeSport = (e) => {
        setSelectedSport(e.target.value);
    };
    const toCollegeConnect = () => {
        setStep(5);
    };

    return (
        <div className="bg-[#000000] text-white py-24 px-[10%] h-fit flex flex-col gap-4">
            <div className="flex flex-col justify-center items-center lg:items-start gap-2">
                <h1 className="text-6xl font-bold mb-2 text-center">College Connect</h1>
                <span className="text-center lg:text-start">
                    Select your sport for College Connect. We strive to connect you with your desired school as efficiently as possible.
                </span>
                <span className="text-3xl font-bold flex gap-2 text-nowrap">
                    You have: {(role === "premium user") ? <span>Unlimited</span> : <span>{tokens?.toLocaleString()}</span>}
                    <FontAwesomeIcon icon={faCoins} size="sm" className="text-blue" />
                </span>
                    <select
                        value={selectedSport}
                        onChange={handleChangeSport}
                        className="bg-orange w-full lg:w-[33%] text-black text-center p-2 text-xl font-semibold rounded-md border-solid border-2 hover:bg-[#000000] hover:text-white hover:border-orange duration-200 ease-in-out"
                    >
                        <option value="">Select a Sport</option>
                        {sports.map((sport, index) => (
                            <option key={index} value={sport}>
                                {sport}
                            </option>
                        ))}
                    </select>
                    <button
                        disabled={!selectedSport}
                        onClick={toCollegeConnect}
                        className={`bg-orange w-full lg:w-[33%] text-black p-2 text-xl font-semibold rounded-md border-solid border-2 hover:bg-[#000000] hover:text-white hover:border-orange duration-200 ease-in-out ${
                            !selectedSport ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        Use College Connect
                    </button>

                <div className='flex flex-col font-semibold text-3xl text-center md:text-start'>
                    <span>Check out a video on how it works:</span>

                    <video
                        src={video?.url} // Assuming CollegeConnectTutorial is the imported video path
                        controls
                        width="100%"
                        className="mt-4 border-solid border-2 border-orange rounded-lg"
                    />
                </div>
            </div>
        </div>
    );
}

export default SportSelection;
