import React from 'react'
import TORQvideo from "../videos/prospect-video.mp4"
import { Link } from 'react-router-dom'

function Highlights() {
  return (
    <div className="bg-black text-white text-start w-full text-2xl mx-auto flex flex-col items-center gap-12 px-[10%] py-24">
      <div className="flex flex-col justify-center items-center gap-12 ">
        <div className="flex flex-col gap-8 items-start">
          <h1 className="text-5xl md:text-7xl font-bold">Get your own personalized highlight</h1>
          <div className="border w-full "/>
        </div>
        <div className="flex flex-col-reverse md:flex-row items-center gap-16">
          <ul className="text-start">
            <li className="flex gap-4"><span class="text-nowrap text-blue">Step 1:</span><Link to="/signup" className="hover:underline">Sign up for an account</Link></li>
            <li className="flex gap-4"><span class="text-nowrap text-blue">Step 2:</span>Purchase a premium account</li>
            <li className="flex gap-4"><span class="text-nowrap text-blue">Step 3:</span>Upload your video</li>
            <li className="flex gap-4"><span class="text-nowrap text-blue">Step 4:</span>Download your personalized highlight</li>
            <li className="italic pt-8"><span >It's that easy...</span></li>
            <li className="pt-8"><Link to="/login" className=" text-white border-solid border-2 border-orange rounded-full py-2 px-8 hover:bg-orange duration-300 ease-in">Get Started</Link></li>
          </ul>
          <video
            autoPlay
            loop
            muted
            className="object-fill aspect-4/3 border-solid border-4 border-orange w-[400px] md:w-[500px] h-[300px]">
            <source src={TORQvideo} />
          </video>
        </div>
      </div>
    </div>
  )
}

export default Highlights