import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import BeachCityLogo from "../images/logos/partners/beachcity.png"
import TORQimg from "../images/Torq-Wordmark-Sports.png"
import { useAuth } from "../contexts/AuthProvider";

const debug = +process.env.REACT_APP_DEBUG

function BeachCity() {
    const [video, setVideo] = useState("");
    const [video2, setVideo2] = useState("");
    const { token } = useAuth();

    const getTutorialVideo = async () => {
        const res = await fetch("/api/college-connect/tutorial-video/beach-cities.mov", {
            method: "GET",
            headers: {
              'Content-Type': 'multipart/form-data',
              "Authorization": `Bearer ${token}`,
            }
          })
      
          if(!res.ok) {
            console.error("Failed to fetch tutorial video");
            return;
          }
      
          const data = await res.json();
      
          if(debug) console.log("Link: ", data);
          sessionStorage.setItem("video", data);
          setVideo(data)
    }

    const getTutorialVideo2 = async () => {
        const res = await fetch("/api/college-connect/tutorial-video/torq-bc-hypevid.mov", {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          })
      
          if(!res.ok) {
            console.error("Failed to fetch tutorial video");
            return;
          }
      
          const data = await res.json();
      
          if(debug) console.log("Link: ", data);
          sessionStorage.setItem("video2", data);
          setVideo2(data)
    }

useEffect(() => {
    if(!video) {
        getTutorialVideo()
        getTutorialVideo2()
    } 
}, [video, video2])

  return (
    <div className='bg-[#000000]'>
    <div className='bg-white text-black min-h-screen lg:mx-[15%] py-[5%] space-y-4'>
        <div className=' flex flex-wrap items-center justify-center'>
            <img src={BeachCityLogo} className='h-[200px] w-[200px] p-2' alt="" />
        </div>
        <div className="flex flex-col gap-4">
            <div className='flex justify-center items-center'>
                <video
                    src={video2?.url} // Assuming CollegeConnectTutorial is the imported video path
                    controls
                    autoPlay
                    muted
                    className="w-[100%] md:w-[50%] border-solid border-2 border-orange rounded-lg"
                />
            </div>
            <div className='flex flex-col items-center gap-2'>
                <h2 className='text-3xl font-semibold text-nowrap'>Description of Services</h2>
                <span className='w-[100%] md:w-[60%] text-wrap text-center'>
                    TORQ Sports offers 2 types of services to help you get recruited to play college sports. Our <i>College Connect</i> makes it seamless to connect with college coaches and our <i>MyLight</i> video automation service helps you create professional highlight videos to showcase your skills. 
                </span>
                <div className='flex gap-2 items-start justify-start'>
                    <Link to="/college-connect" className='bg-orange text-black  rounded-full text-nowrap py-1 px-4'>College Connect</Link>
                    <Link to="/video-automation" className='bg-orange text-black  rounded-full text-nowrap py-1 px-4'>MyLight</Link>
                </div>
            </div>
          {video?.url && (
            <div className="flex flex-col gap-4 items-center">
            <h2 className="text-3xl">How it Create Account:</h2>
            <Link to="/signup" className='bg-orange text-black  rounded-full text-nowrap py-1 px-4'>Create Account</Link>
            <video
                    src={video?.url} // Assuming CollegeConnectTutorial is the imported video path
                    controls
                    className="w-[100%] md:w-[50%] border-solid border-2 border-orange rounded-lg"
                />
        </div>
          ) }
            
        </div>
    </div>
    </div>
  )
}

export default BeachCity