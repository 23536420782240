// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Set the environment (default to 'development' if not set)
const env = process.env.REACT_APP_ENV || 'development';
const debug = +process.env.REACT_APP_DEBUG;
if (debug) console.log('Environment', env);

// Determine the correct Firebase configuration based on the environment
const firebaseConfig = env === 'production' ? {
    apiKey: process.env.REACT_APP_PROD_API_KEY,
    authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROD_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PROD_APP_ID,
    measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID
} : {
    apiKey: process.env.REACT_APP_DEV_API_KEY,
    authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_DEV_APP_ID,
    measurementId: process.env.REACT_APP_DEV_MEASUREMENT_ID
};

if(debug)console.log("Environment: ", env);
if(debug)console.log("Firebase Config: ", firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);

if (!app) {
    if(debug)console.log("Firestore not connected...");
} else {
    if(debug)console.log("Firestore connected...");
}

const auth = getAuth(app);


export default auth;

