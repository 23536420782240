import React, { useState, useEffect } from 'react';
import SearchResults from './SearchResults';
import { useAuth } from '../../contexts/AuthProvider';
import axios from 'axios';


// const sportsOptions = [
//     'Golf', 'Baseball', 'Volleyball', 'Basketball', 'Football', 'Soccer', 'Softball', 'Track/Field', 
//     'Swimming', 'Hockey', 'Cross Country', 'Water Polo', 'Snowboarding', 'Skiing', 'Skateboarding', 
//     'Crew', 'Tennis', 'Lacrosse'
// ];
  

const sportsOptions = [
  "Beach Volleyball",
  "Baseball",
  "Football",
  "Men's Volleyball",
  "Men's Basketball",
  "Softball",
  "Women's Basketball",
  "Women's Volleyball",
];

const SPORTS_GENDERS = {
  "baseball": "male",
  "men's volleyball": "male",
  "men's basketball": "male",
  "football": "male",
  "softball": "female",
  "women's volleyball": "female",
  "women's basketball": "female"
}

const GPA_RANGES =[
  "1.0", "1.1", "1.2", "1.3", "1.4", "1.5", "1.6", "1.7", "1.8", "1.9",
  "2.0", "2.1", "2.2", "2.3", "2.4", "2.5", "2.6", "2.7", "2.8", "2.9",
  "3.0", "3.1", "3.2", "3.3", "3.4", "3.5", "3.6", "3.7", "3.8", "3.9",
  "4.0"
]

const positions = {
  Golf: ['Player'],
  Baseball: [
    'Pitcher', 'Catcher', 'First Baseman', 'Second Baseman', 'Third Baseman', 'Shortstop', 
    'Left Fielder', 'Center Fielder', 'Right Fielder', 'Designated Hitter'
  ],
  "Men's Volleyball": [
    'Outside Hitter', 'Opposite Hitter', 'Setter', 'Middle Blocker', 'Libero', 'Defensive Specialist', 
    'Serving Specialist'
  ],
  "Women's Volleyball": [
    'Outside Hitter', 'Opposite Hitter', 'Setter', 'Middle Blocker', 'Libero', 'Defensive Specialist', 
    'Serving Specialist'
  ],
  "Men's Basketball": ['PG', 'SG', 'SF', 'PF', 'C'],
  "Women's Basketball": ['PG', 'SG', 'SF', 'PF', 'C'],
  Football: [
    'Quarterback', 'Running Back', 'Wide Receiver', 'Tight End', 'Offensive Lineman', 
    'Defensive Lineman', 'Linebacker', 'Cornerback', 'Safety', 'Kicker', 'Punter'
  ],
  Soccer: [
    'Goalkeeper', 'Defender', 'Midfielder', 'Forward'
  ],
  Softball: [
    'Pitcher', 'Catcher', 'First Baseman', 'Second Baseman', 'Third Baseman', 'Shortstop', 
    'Left Fielder', 'Center Fielder', 'Right Fielder', 'Designated Player'
  ],
  'Track/Field': [
    'Sprinter', 'Middle-distance Runner', 'Long-distance Runner', 'Hurdler', 'Jumper', 'Thrower', 'Decathlete/Heptathlete'
  ],
  Swimming: [
    'Freestyle Swimmer', 'Backstroke Swimmer', 'Breaststroke Swimmer', 'Butterfly Swimmer', 
    'Individual Medley Swimmer', 'Relay Swimmer', 'Distance Swimmer', 'Sprint Swimmer'
  ],
  Hockey: ['Goaltender', 'Defenseman', 'Left Wing', 'Center', 'Right Wing'],
  'Cross Country': ['Runner'],
  'Water Polo': ['Goalkeeper', 'Center Forward', 'Center Back', 'Driver', 'Winger'],
  Snowboarding: ['Freestyle', 'Alpine', 'Boardercross Racer'],
  Skiing: ['Alpine', 'Freestyle', 'Cross-country', 'Ski Jumper'],
  Skateboarding: ['Street', 'Vert', 'Park', 'Freestyle'],
  Crew: [
    'Coxswain', 'Stroke', 'Bow', 'Port Rower', 'Starboard Rower', 'Lightweight Rower', 'Heavyweight Rower'
  ],
  Tennis: ['Singles Player', 'Doubles Player'],
  Lacrosse: ['Goalkeeper', 'Defenseman', 'Midfielder', 'Attackman', 'Faceoff Specialist'],
  "Beach Volleyball": ["Player"]
};

const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
  'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
  'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
  'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina',
  'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
  'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];
  
const Search = () => {
  const [filters, setFilters] = useState({
    name: '',
    sport: '',
    school: '',
    age: '',
    position: '',
    gender: '',
    state: '',
    gradYear: '',
    travelClub: '',
    gpa: '',
  });

  const { token, role } = useAuth();
  const [players, setPlayers] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [pageSize] = useState(10);
  const [isLastUser, setIsLastUser] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Retrieve search state from sessionStorage
    const savedFilters = sessionStorage.getItem('searchFilters');
    const savedResults = sessionStorage.getItem('searchResults');

    if (savedFilters && savedFilters !== 'undefined') {
      setFilters(JSON.parse(savedFilters));
    }
    if (savedResults && savedResults !== 'undefined') {
      setPlayers(JSON.parse(savedResults));
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'sport' && value === '') {
      setError('Please select a sport.');
    }else{
      setError('');
    }

    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleSearch = async () => {
    if (!filters.sport) {
      setError('Please select a sport.');
      return;
    }
    if (role !== "premium") {
      setHasSearched(true);
      setPlayers([]);
      setError('');
      return;
    }
    setError('');
    setHasSearched(true);
    setPlayers([]);
    fetchPlayers(true);
  };

  const handleLoadMore = () => {
    fetchPlayers(false);
  };

  const fetchPlayers = async (isNewSearch) => {
    try {
      filters['gender'] = filters.sport ? SPORTS_GENDERS[filters.sport.toLowerCase()] : "";
      const queryParams = new URLSearchParams({
        ...filters,
        lastUserId: isNewSearch ? '' : players[players.length - 1]?.id,
        pageSize
      }).toString();

      const response = await axios.get(`/api/user/search?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      const newPlayers = isNewSearch ? response.data.results : [...players, ...response.data.results];
      if (!isNewSearch) {
        if (response.data.results.length === 0 || response.data.results.length < pageSize){
          setIsLastUser(true);
        }
      }else if (response.data.results.length < pageSize){
        setIsLastUser(true);
      }else {
        setIsLastUser(false);
      }
      setPlayers(newPlayers);

      sessionStorage.setItem('searchFilters', JSON.stringify(filters));
      sessionStorage.setItem('searchResults', JSON.stringify(newPlayers));

    } catch (error) {
      console.error('Error fetching players:', error);
    }
  };
  
  return (
    <div className="container mx-auto p-8 bg-black shadow-inner-black rounded-lg m-10 shadow-orange mt-20">
      <h1 className="text-4xl font-bold mb-6 text-orange font-a4">Find Athletes</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
        <div>
          <label className="block text-white text-sm font-bold mb-2">*Sport</label>
          <select
            name="sport"
            value={filters.sport}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-700 bg-gray-800 text-black shadow-sm focus:border-blue focus:ring focus:ring-blue focus:ring-opacity-50"
          >
            <option value="">Select a sport</option>
            {sportsOptions.map((sport) => (
              <option key={sport} value={sport}>
                {sport}
              </option>
            ))}
          </select>
          {error && <p className="text-red-500 mt-1">{error}</p>}
        </div>
        <div>
          <label className="block text-white text-sm font-bold mb-2">Name</label>
          <input
            type="text"
            name="name"
            value={filters.name}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-700 bg-gray-800 text-black shadow-sm focus:border-blue focus:ring focus:ring-blue focus:ring-opacity-50 pl-2"
          />
        </div>
        <div>
          <label className="block text-white text-sm font-bold mb-2">Graduation Year</label>
          <input
            type="number"
            name="age"
            value={filters.gradYear}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-700 bg-gray-800 text-black shadow-sm focus:border-blue focus:ring focus:ring-blue focus:ring-opacity-50 pl-2"
          />
        </div>
        <div>
          <label className="block text-white text-sm font-bold mb-2">Minimum GPA</label>
          <select
            name="gpa"
            value={filters.gpa}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-700 bg-gray-800 text-black shadow-sm focus:border-blue focus:ring focus:ring-blue focus:ring-opacity-50" 
          >
            <option value="">Select a value</option>
            {GPA_RANGES.map((range) => (
              <option key={range} value={range}>
                {range}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-white text-sm font-bold mb-2">School</label>
          <input
            type="text"
            name="school"
            value={filters.school}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-700 bg-gray-800 text-black shadow-sm focus:border-blue focus:ring focus:ring-blue focus:ring-opacity-50 pl-2"
          />
        </div>
        <div>
          <label target="travel-club" className="block text-white text-sm font-bold mb-2">Travel Club</label>
          <input
            name="travel-club"
            value={filters.travelClub}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-700 bg-gray-800 text-black shadow-sm focus:border-blue focus:ring focus:ring-blue focus:ring-opacity-50 pl-2"
          />
        </div>
        <div>
          <label className="block text-white text-sm font-bold mb-2">State</label>
          <select
            name="state"
            value={filters.state}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-700 bg-gray-800 text-black shadow-sm focus:border-blue focus:ring focus:ring-blue focus:ring-opacity-50" 
          >
            <option value="">Select a state</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-white text-sm font-bold mb-2">Position</label>
          <select
            name="position"
            value={filters.position}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-700 bg-gray-800 text-black shadow-sm focus:border-blue focus:ring focus:ring-blue focus:ring-opacity-50"
          >
            <option value="">Select a position</option>
            {(positions[filters.sport] || []).map((position) => (
              <option key={position} value={position}>
                {position}
              </option>
            ))}
          </select>
        </div>
      </div>
      
      <button
        onClick={handleSearch}
        type="submit"
        className="bg-orange text-white font-bold py-2 px-6 rounded hover:bg-orange-700 transition-all duration-300"
      >
        Search
      </button>
      <SearchResults players={players} hasSearched={hasSearched} filters={filters} onLoadMore={handleLoadMore} isLastUser={isLastUser}/>
    </div>
  );
};

export default Search;