// basic react component
import React from 'react';
import EmailForm from '../components/email/EmailForm';


const SendMassEmail = () => {

    return (
        <>
            <EmailForm />
        </>
    );
}

export default SendMassEmail;