import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faMailBulk, faBalanceScale, faPhotoVideo } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../contexts/AuthProvider'
import { useMultistepContext } from '../../contexts/MultistepProvider'

function Card({title, tokens, emails, mylight, message, titleColor, price, description, handleSelection }) {
    return (
        <div className="flex flex-col gap-4 border-solid border border-white rounded-md p-4 w-[80%] lg:w-[33%]"> {/* Set a fixed height for the card */}
            <h2 className={`text-3xl ${titleColor}`}>{title}</h2>
            <span className="text-5xl font-bold">$ {price}</span>
            <button 
                onClick={handleSelection} 
                className="bg-orange text-black mx-[10%] py-2 text-xl font-semibold rounded-md border-solid border-2 hover:bg-[#000000] hover:text-white hover:border-orange duration-200 ease-in-out"
            >
                Buy Now
            </button>
            <span className="h-fit lg:h-[8ch]">{description}</span>
            <div className="border-solid border-b border-white"></div>
            <div className="flex gap-2 px-4 mt-4 lg:mt-0 h-fit"> {/* Set a fixed height for the icons and text container */}
                <div className="flex flex-col justify-center gap-3">
                    <FontAwesomeIcon icon={faMailBulk} size="lg" />
                    <FontAwesomeIcon icon={faBalanceScale} size="lg" />
                    {mylight ? <FontAwesomeIcon icon={faPhotoVideo} size="lg" /> : <br/>}
                </div>
                <div className="flex flex-col justify-center gap-3">
                    <span>{emails} emails</span>
                    <span>{message}</span>
                    <span>{mylight ? "MyLight INCLUDED" : <br/>}</span>
                </div>
            </div>
        </div>
    )
}

function PaymentOptions() {
  const { tokens } = useAuth()
  const { handlePaymentOption, nextStep } = useMultistepContext()

  const available = tokens > 0


  return (
    <div className="bg-[#000000] text-white py-[5%] px-[10%] flex flex-col gap-4">
            <div className="flex flex-col justify-center items-center lg:items-start gap-2">
                <h1 className="text-6xl font-bold mb-2 text-center">Buy Tokens</h1>
                <span className="text-center lg:text-start">Select your option for our college connect. We strive to connect you with your desired school as efficiently as possible.</span>
                <span className="text-3xl font-bold flex gap-2">
                    You have: {tokens.toLocaleString()} 
                    <FontAwesomeIcon icon={faCoins} size="sm" className="text-blue" />
                </span>
                
            </div>
                <div className="flex flex-col lg:flex-row justify-center items-center gap-4">
                <Card
                    title="Rookie Package"
                    tokens={1}
                    emails={10}
                    mylight={0}
                    message="10 emails per token"
                    titleColor="text-amber-600"
                    price={99.95}
                    description="Get in contact with 10 colleges of your choice through our College Connect Finder."
                    handleSelection={nextStep}
                    // handleSelection={() => handlePaymentOption({ title: 'One-time Package', tokens: 1, emails: 10, price: 99.95 })}
                />
                <Card
                    title="All-Star Package"
                    tokens={5}
                    emails={75}
                    mylight={1}
                    message="15 emails per token"
                    titleColor="text-gray-800"
                    price={349.95}
                    description="Our most popular package. Get in contact with 75 colleges of your choice through our College Connect Finder."
                    handleSelection={nextStep}
                    // handleSelection={() => handlePaymentOption({ title: 'Small Package', tokens: 5, emails: 75, price: 349.95 })}
                />
                <Card 
                    title="MVP Package"
                    tokens={10}
                    emails={150}
                    mylight={1}
                    message="15 emails per token"
                    titleColor="text-yellow-400"
                    price={499.95}
                    description="The best value package. Get in contact with 150 colleges of your choice through our College Connect Finder."
                    handleSelection={nextStep}
                    // handleSelection={() => handlePaymentOption({ title: 'Baller Bundle', tokens: 10, emails: 150, price: 499.95 })}
                />
                </div>
            </div>
  )
}

export default PaymentOptions