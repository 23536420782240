import { createContext, useContext, useEffect, useState } from 'react';
import { onIdTokenChanged, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import auth from '../config/firebase.js';

const debug = +process.env.REACT_APP_DEBUG;

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [authentication, setAuthentication] = useState(false || sessionStorage.getItem('auth') === 'true');
  const [token, setToken] = useState(sessionStorage.getItem('token') || '');
  const [tokens, setTokens] = useState(sessionStorage.getItem('tokens'));
  const [userId, setUserId] = useState(sessionStorage.getItem('userId') || '');
  const [role, setRole] = useState(sessionStorage.getItem('role') || '');
  const [user, setUser] = useState(sessionStorage.getItem("user") || null);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to fetch user data from API
  const fetchUserData = async () => {
    if (userId && token) {
        setLoading(true);
        try {
            const response = await fetch(`/api/user/get/${userId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
              const data = await response.json();
              sessionStorage.setItem('user', JSON.stringify(data));
              setUser(JSON.stringify(data));

              const accountType = data?.accountType || 'user';
              
              setRole(accountType);

              sessionStorage.setItem('tokens', data?.tokens);
              setTokens(data?.tokens);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            setLoading(false);
        }
    }
};

  useEffect(() => {
      fetchUserData();
  }, [userId, token]); // Fetch user data when userId or token changes


  const createUser = async (newUserMetadata) => {
    setLoading(true);
    const { email, password } = newUserMetadata;
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      const response = await fetch('/api/user/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({newUserMetadata, id: userCredential.user.uid, userRecord: userCredential.user}),
      });

      const data = await response.json();

      if(debug) console.log("Response from /api/user/signup ::\n", data);
      
      if (response.ok) {
        const { auth } = data;
        sessionStorage.setItem('auth', auth);
        setAuthentication(auth);
      } else {
        if (debug) console.error("Error from /api/user/signup ::\n", data?.message);
      }
    } catch (error) {
      if (debug) console.error("Error creating user ::\n", error);
    } finally {
      setLoading(false);
    }
  };

  const loginUser = async (email, password) => {
    setLoading(true);
    if(debug && 0) {
      console.log("Email:", email);
      console.log("Password", password);
      console.log("Auth: ", auth);
    }
   
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        console.error("Error from sign in:", error.code, error.message);
      });

      const idToken = await userCredential.user.getIdToken();
      if(debug) console.log("ID Token: ", idToken);
      // Sign in user with fetch request
      const response = await fetch('/api/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ email, password, idToken })
      });
      
      if (response.ok) {
        const data = await response.json();
        const { user, auth , userId } = data;

        if(debug) {
          console.log("Data ++ \n", data);
          console.log("USER ID ++ \n", userId);
          console.log("USER RECORD ++ \n", user);
        }

        sessionStorage.setItem('auth', auth);
        sessionStorage.setItem('token', idToken);
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('role', user.accountType);
        setAuthentication(auth);
        setToken(idToken);
        setUser(JSON.stringify(user));
        setUserId(userId);
        setRole(user.accountType);
      } else {
        if (debug) console.error("Error creating user:");
      }
    } catch (error) {
      if(debug) console.error("Error logging in user:", error.code, error.message);
    } finally {
      setLoading(false);
    }
  };

  const logOut = () => {
    setLoading(true);
    signOut(auth)
      .then(() => {
        setUser(null);
        setAuthentication(false);
        setToken('');
        sessionStorage.clear();
        sessionStorage.setItem('auth', false);
        if(debug) console.log("Logged out successfully.");
      })
      .catch((error) => console.error("Error logging out:", error))
      .finally(() => { setLoading(false)});
  };

  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = sessionStorage.getItem('token');
          if (!token) {
            setTokenExpired(true);
            return;
          }

          const tokenResult = await user.getIdTokenResult();
          var hour = new Date(tokenResult.expirationTime).getHours();
          var minute = new Date(tokenResult.expirationTime).getMinutes();
          var second = new Date(tokenResult.expirationTime).getSeconds();
          
          if(debug && !tokenResult) {
            console.log("Token result not found.");
          }
          const idToken = await user.getIdToken(true);
          sessionStorage.setItem('token', idToken);
        } catch (error) {
          console.error("Error checking token expiry:", error);
          setTokenExpired(true);
        }
      } else {
        setTokenExpired(true);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
    if (currentUser && debug) {
      console.log('User is logged in');
    } else {
      logOut();
    }
    return unsubscribe
  })}, []);

  const authValue = {
    createUser,
    loginUser,
    logOut,
    setAuthentication,
    authentication,
    loading,
    user,
    userId,
    token,
    tokens,
    role,
    fetchUserData
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;
