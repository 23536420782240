import React, { useState, useContext } from 'react';
import { useAuth } from '../../contexts/AuthProvider';

function UploadProfilePhoto() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const {token} = useAuth();
  
    const handleOpenClick = () => {
      setIsOpen(true);
      setSelectedFile(null);
      setErrorMessage(null);
    };
  
    const handleCloseClick = () => {
      setIsOpen(false);
      setSelectedFile(null);
      setErrorMessage(null); // Clear error message on close
    };

    const handleRemoveClick = async (e) => {
      const res = await fetch('/api/upload/profile-picture', {
        method: 'DELETE', 
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      if(!res.ok) {
        setErrorMessage('An error occurred while removing the profile picture. Please try again later.');
        return;
      }

      setSelectedFile(null); // Clear selected file after successful removal
      setIsOpen(false);  // Close the pop-up after successful removal
    }
  
    // Ensures that the files is mp4 or mov and that there is only one file uploaded.
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      // Add allowed extensions for profile picture
      const allowedExtensions = ['jpg', 'jpeg','png', 'gif', 'heif', 'hevc' ];
      const extension = file.name.split('.').pop().toLowerCase();
  
      if (!allowedExtensions.includes(extension)) {
        setErrorMessage('Invalid file format. Please upload an JPG, JPEG, PNG, GIF, HEIF, or HEVC video.');
        setSelectedFile(null);
        return;
      }
  
      setSelectedFile(file);
      setErrorMessage(null); // Clear error message on valid file selection
    };
  
    const handleUpload = async (e) => {
      var file = e.target.files[0];
      console.log('Uploading new video...');
      if (!file) {
        setErrorMessage('Please select a video file to upload.');
        return;
      }
  
      const formData = new FormData();
      formData.set('image', file);

      try {
          // Upload video file to server.
          const response = await fetch('/api/upload/profile-picture', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
              },
              body: formData
          });
          
          // Successful upload.
          if (response.ok) {
              console.log('Video uploaded successfully!');
          } else {
              throw new Error()
          }
      } catch(error) {
          setErrorMessage('An error occurred while uploading the video. Please try again later.');
          return;
      }
  
      setSelectedFile(null); // Clear selected file after successful upload
      setIsOpen(false);  // Close the pop-up after successful upload
    };
  
    return (
      <>
        {isOpen ? ( // Conditionally render the pop-up only when isOpen is true
          <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg text-black">
              <div className="flex flex-col items-center text-xl font-semibold w-full">
                <h2 className="text-2xl font-normal p-8">Change Profile Picture</h2>
                {errorMessage && <p className="text-red-900 text-md">{errorMessage}</p>}
                <div className='border-solid border-b border-black w-full'></div>
                <div className="flex flex-col   p-4">
                  <label htmlFor="upload" className='text-blue cursor-pointer'>
                    <input type="file" name="upload" id="upload" accept=".jpg, .jpeg, .png, .gif, .heif, .hevc" onChange={handleUpload} className="hidden" />
                    Upload Photo
                  </label>
                </div>
                {selectedFile ? (
                  <>
                    <div className='border-solid border-b border-black w-full'></div>
                    <div className='flex flex-col p-4'>
                      <button className="text-red-500" onClick={handleRemoveClick}>Remove Current Photo</button>
                    </div>
                  </>
                  
                ) : (
                  <></>
                )}
                
                <div className='border-solid border-b border-black w-full'></div>

                <div className='flex flex-col p-4'>
                  <button className="" onClick={handleCloseClick}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <button className="border-dotted border-b border-black font-thin" onClick={handleOpenClick}>
              Add a Profile Picture
          </button>
        )}
      </>
    );
}

export default UploadProfilePhoto