import React from 'react'

function RequestQuote() {
  return (
    <div className=" bg-black text-white text-center w-full text-2xl mx-auto flex flex-col items-center gap-12 px-4 py-24">
        
    </div>
  )
}

export default RequestQuote