import React from 'react';

const StatsCard = ({ profile, isEditing, handleChange }) => {
  return (
    <div className="w-full mt-8 md:mt-0 flex-grow">
      <div className="p-6 rounded-lg shadow-lg h-full" style={{ backgroundColor: "#1E1F1F" }}>
        <h2 className="font-a4 text-orange text-3xl mb-2">Stats</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8">
          {/* Age */}
          <div className="flex flex-col">
            <span className="text-sm text-white font-bold">Age</span>
            {isEditing ? (
              <input 
                type="text" 
                name="age" 
                value={profile?.age} 
                onChange={handleChange} 
                className="mt-2 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none" 
              />
            ) : (
              <span className="text-xl text-orange">{profile?.age}</span>
            )}
          </div>
          
          {/* Height */}
          <div className="flex flex-col">
            <span className="text-sm text-white font-bold">Height</span>
            {isEditing ? (
              <input 
                type="text" 
                name="height" 
                value={profile?.height} 
                onChange={handleChange} 
                className="mt-2 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none" 
              />
            ) : (
              <span className="text-xl text-orange">{profile?.height}</span>
            )}
          </div>

          {/* Weight */}
          <div className="flex flex-col">
            <span className="text-sm text-white font-bold">Weight</span>
            {isEditing ? (
              <input 
                type="text" 
                name="weight" 
                value={profile?.weight} 
                onChange={handleChange} 
                className="mt-2 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none" 
              />
            ) : (
              <span className="text-xl text-orange">{profile?.weight}</span>
            )}
          </div>

          {/* Handedness */}
          <div className="flex flex-col">
            <span className="text-sm text-white font-bold">Handedness</span>
            {isEditing ? (
              <input 
                type="text" 
                name="handedness" 
                value={profile?.handedness} 
                onChange={handleChange} 
                className="mt-2 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none" 
              />
            ) : (
              <span className="text-xl text-orange">{profile?.handedness}</span>
            )}
          </div>

          {/* Primary Position */}
          <div className="flex flex-col">
            <span className="text-sm text-white font-bold">Primary Position</span>
            {isEditing ? (
              <input 
                type="text" 
                name="position" 
                value={profile?.position} 
                onChange={handleChange} 
                className="mt-2 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none"
              />
            ) : (
              <span className="text-xl text-orange">{profile?.position}</span>
            )}
          </div>

          {/* Secondary Position */}
          <div className="flex flex-col">
            <span className="text-sm text-white font-bold">Secondary Position</span>
            {isEditing ? (
              <input 
                type="text" 
                name="secondaryPosition" 
                value={profile?.secondaryPosition} 
                onChange={handleChange} 
                className="mt-2 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none"
              />
            ) : (
              <span className="text-xl text-orange">{profile?.secondaryPosition}</span>
            )}
          </div>

          {/* GPA */}
          <div className="flex flex-col">
            <span className="text-sm text-white font-bold">GPA</span>
            {isEditing ? (
              <input 
                type="text" 
                name="gpa" 
                value={profile?.gpa} 
                onChange={handleChange} 
                className="mt-2 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none"
              />
            ) : (
              <span className="text-xl text-orange">{profile?.gpa}</span>
            )}
          </div>

          {/* SAT Score */}
          <div className="flex flex-col">
            <span className="text-sm text-white font-bold">SAT Score</span>
            {isEditing ? (
              <input 
                type="text" 
                name="satScore" 
                value={profile?.satScore} 
                onChange={handleChange} 
                className="mt-2 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none"
              />
            ) : (
              <span className="text-xl text-orange">{profile?.satScore}</span>
            )}
          </div>

          {/* ACT Score */}
          <div className="flex flex-col">
            <span className="text-sm text-white font-bold">ACT Score</span>
            {isEditing ? (
              <input 
                type="text" 
                name="actScore" 
                value={profile?.actScore} 
                onChange={handleChange} 
                className="mt-2 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none"
              />
            ) : (
              <span className="text-xl text-orange">{profile?.actScore}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;