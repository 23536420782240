import React from 'react'
import { Link } from "react-router-dom";
import TorqIcon from "../../images/icons/Torq-Logo-TightCrop.png";
import TorqSports from "../../images/TorqSports.png";

const Footer = () => {
    return (
        <footer className="flex flex-col relative z-30 overflow-hidden py-10 bg-black text-white font-thin">
            <div className="flex flex-col sm:flex-row gap-8 md:gap-16 px-10 lg:px-20">
                <div className="text-lg font-semibold">
                    <img src={TorqIcon} alt="Torq logo" className="w-20 mx-auto"/>
                    <img src={TorqSports} alt="Torq Watermark" className="w-36 mt-4 mx-auto" />
                </div>
                <div className="flex flex-col gap-1 ">
                    <h2 className="text-3xl font-bold pb-4">Menu</h2>
                    <Link to="/">Home</Link>
                    <Link to="/torq-ai">TORQ AI</Link>
                    <Link to="/livestreams">Livestreams</Link>
                    <Link to="/highlights">Highlights</Link>
                </div>
                <div className="flex flex-col gap-1">
                    <h2 className="text-3xl font-bold pb-4">Contact Us</h2>
                    <p>(818) 575-6168</p>
                    <p>jake@torqsports.com</p>
                    <address className="">5959 Topanga Canyon Blvd. Woodland Hills, CA 91367</address>
                </div>
                <div className="flex flex-col gap-1 ">
                    <h2 className="text-3xl font-bold pb-4">Socials</h2>
                    <Link to="https://www.facebook.com/torqsportshq/">Facebook</Link>
                    <Link to="https://twitter.com/torqagency">Twitter</Link>
                    <Link to="https://www.youtube.com/channel/UCM4XMklclL5OV2zIXEsoYCQ">Youtube</Link>
                </div>
            </div>
            <div className='text-sm mx-auto pt-2'>
                © 2024 ALL Rights Reserved by TORQ Sports.
            </div>
        </footer>
    )
}

export default Footer