import React from 'react'
function SocialMediaContent() {
  return (
    <div className="flex flex-col justify-center items-center text-3xl sm:text-4xl gap-4 font-semibold text-center">
        <div className="flex flex-col gap-8 items-start">
          <h1 className="text-5xl md:text-7xl font-bold">Social Media</h1>
        </div>
        <div className="block gap-4 text-start text-xl font-light px-4 w-full lg:w-[65%]">
            <span className="">TORQ Sports has established a strong social media presence with over 1 billion views and 500,000 followers. Our daily content includes updates, highlights, and analysis that keep sports fans engaged and informed. From breaking news to player interviews, TORQ Sports delivers the latest in sports entertainment, making us a go-to source for fans everywhere. Join our community and stay connected with the sports world!</span>
        </div>
    </div>
  )
}

export default SocialMediaContent