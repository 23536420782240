import { useState, useEffect } from 'react';
import VideoPlayer from '../profile/VideoPlayer';
import { useAuth } from '../../contexts/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare, faShare, faCameraRetro } from '@fortawesome/free-solid-svg-icons';

const debug = +process.env.REACT_APP_DEBUG;

const PublicVideoFeed = ({userId}) => {
  const [videos, setVideos] = useState([]); // Array to store video data
  const {token} = useAuth(); // Get user token from context

  // get user id from url
  // const userId = window.location.pathname.split("/")[2];
  console.log('user id:', userId);

  const fetchUserVideos = async () => {
    console.log("Fetching videos...");

    try {
      if (!userId || !token || userId === "undefined") {
        return;
      }
      const response = await fetch(`/api/upload/user/${userId}`, { 
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch videos");
      }

      const data = await response.json();
      if(debug) console.log('data: ', data);
      
      if (!data || data.length === 0) {
        throw new Error("No videos found");
      }
      
      if(debug) console.log("Videos found:", data)
      setVideos(data); // Update state with video data
    } catch (err) {
      console.log("No videos found...");
    }
  };

  // Fetch videos only on component mount
  useEffect(() => {
    fetchUserVideos(); // Fetch videos when component mounts
  }, [userId]); // Empty dependency array ensures fetch only happens once on mount

  return (
    <div>
      {videos.length > 0 ? (
        <div className="border-solid border border-white grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {videos.map((post) => (
            <div key={post.title} className="bg-gray-200 p-4 border border-gray-400 rounded-md">
              <div className="flex flex-col items-start justify-between gap-4">
                <VideoPlayer
                  key={post.videoName}
                  userId={userId}
                  videoUrl={post.videoURL}
                  className="w-full lg:w-64 h-auto"
                />

                <div className="flex flex-col items-start">
                  <span className="text-black text-lg lg:text-4xl">{post.title}</span>
                  <span className="text-sm lg:text-xl italic">Posted on {post.posted.substring(0, 10)}</span>
                </div>

                <div className="flex gap-2 lg:gap-4">
                  <div className="hover:cursor-pointer">
                    <FontAwesomeIcon size="lg" icon={faPenToSquare} />
                  </div>
                  <div className="hover:cursor-pointer">
                    <FontAwesomeIcon size="lg" icon={faShare} />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <span className="text-center col-span-full">No more videos to show.</span>
        </div>
      ) : (
        <div className="flex flex-col gap-4 w-fit mx-auto text-center p-24">
          <span className="text-2xl">No Posts Yet.</span>
          <FontAwesomeIcon icon={faCameraRetro} size="4x" />
        </div>
      )}
    </div>
  );
};

export default PublicVideoFeed;
