import React, { useState, useEffect } from 'react';
import ProfileHeader from './ProfileHeader';
import StatsCard from './StatsCard';
import SelfRating from './SelfRating';
import {useAuth} from "../../contexts/AuthProvider"

const debug = +process.env.REACT_APP_DEBUG;


const defaultProfile = {
  name: '',
  position: '',
  team: '',
  age: "",
  height: '',
  weight: "",
  handedness: '',
  secondaryPosition: '',
  gpa: "",
  satScore: "",
  actScore: "",
  commitment: '',
  transcriptUrl: null,
  horizontalPosition: 50,
  verticalPosition: 50,
  selfRating: {
    speed: 0,
    endurance: 0,
    strength: 0,
    coachability: 0,
    jumping: 0,
    agility: 0,
    acceleration: 0,
    gameSense: 0,
    teamwork: 0,
  },
};


const PlayerInformation = () => {
  const { token, userId } = useAuth();

  const [profile, setProfile] = useState(null);
  const [originalProfile, setOriginalProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = () => {
    if (!isEditing) {
      setOriginalProfile(profile);
    }
    setIsEditing(!isEditing);
  };

  const cancelEdit = () => {
    setProfile(originalProfile);
    setIsEditing(false);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      if (debug) console.log('getting profile with token:', token)
      try {
        const response = await fetch(`/api/user/get/${userId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        let userProfile = await response.json();

        // Merge the defaultProfile into the userProfile
        userProfile = {
          ...defaultProfile, // default values
          ...userProfile, // actual values from the backend
          selfRating: userProfile.selfRating ? (
            typeof userProfile.selfRating === 'string' 
            ? JSON.parse(userProfile.selfRating) 
            : userProfile.selfRating
          ) : defaultProfile.selfRating
        };

        if (debug) console.log('received user profile:', userProfile)

        setProfile({
          ...userProfile,
          horizontalPosition: userProfile.horizontalPosition || 50,
          verticalPosition: userProfile.verticalPosition || 50,
        });
      } catch (error) {
        if(debug) console.error("Error fetching profile:", error);
      }
    };
  
    fetchProfile();
}, [userId, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
  
      if (type === 'profilePicture') {
        setProfile({ ...profile, photoUrl: url, profilePictureFile: file });
        if (debug) console.log('Updated Profile:', { ...profile, photoUrl: url, profilePictureFile: file});
      } else if (type === 'transcript') {
        setProfile({ ...profile, transcriptUrl: url, transcriptFile: file});
      }
    }
  };

  const handleSliderChange = (attribute, value) => {
    setProfile({
      ...profile,
      selfRating: {
        ...profile.selfRating,
        [attribute]: value,
      },
    });
  };

  const saveProfile = async () => {
    const formData = new FormData();

    for (const key in profile) {
      if (profile.hasOwnProperty(key)) {
        if (key === 'selfRating') {
          formData.append(key, JSON.stringify(profile[key]));
        } else if (key !== 'profilePictureFile' && key !== "photoUrl" && key !== "transcriptFile") {
          formData.append(key, profile[key]);
        } 
      }
    }
  
    if (profile.profilePictureFile) {
      formData.append('profilePicture', profile.profilePictureFile);
    }
  
    if (profile.transcriptFile) {
      formData.append('transcript', profile.transcriptFile);
    }
  
    try {
      const response = await fetch(`/api/user/put/${userId}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      const result = await response.json();
      if (response.ok) {
        setProfile({
          ...profile,
          transcriptUrl: result.transcriptUrl ? result.transcriptUrl : profile.transcriptUrl, photoUrl: "",
          profilePictureUrl: result.profilePictureUrl ? result.profilePictureUrl : profile.profilePictureUrl
        });
        setIsEditing(false);
      } else {
        if (debug) console.error(result.message);
      }
    } catch (error) {
      if(debug) console.error('Error saving profile:', error);
    }
};

  if (!profile) return <div>Loading...</div>;

  return (
    <div className="profile-container max-w-7xl mx-auto px-40 mt-10 bg-gray-900 rounded-lg">
      <div className="flex flex-col md:flex-row">
        <ProfileHeader
          profile={profile}
          isEditing={isEditing}
          handleChange={handleChange}
          toggleEdit={toggleEdit}
          handleFileChange={handleFileChange}
          saveProfile={saveProfile}
          cancelEdit={cancelEdit}
          setProfile={setProfile}
        />

        <div className="flex flex-col flex-1 md:ml-4">
          <div className="flex-grow">
            <StatsCard
              profile={profile}
              isEditing={isEditing}
              handleChange={handleChange}
            />
          </div>
          <div className="flex-grow mt-4">
            <SelfRating
              selfRating={profile?.selfRating}
              isEditing={isEditing}
              handleSliderChange={handleSliderChange}
            />
          </div>
        </div>
      </div>

      {profile.transcriptUrl && !isEditing && (
        <div className="mt-4">
          <a href={profile.transcriptUrl} target="_blank" rel="noopener noreferrer">
            <button className="bg-orange text-black py-2 px-4 rounded-md w-full text-center transition-transform transition-colors duration-200 hover:scale-105 hover:bg-orange-600">
              Download Transcript
            </button>
          </a>
        </div>
      )}
    </div>
  );
};

export default PlayerInformation;