import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import TORQicon from '../../images/icons/Torq-Logo-TightCrop.png';
import GolfLogo from '../../images/logos/sports/golf.png';
import BaseballLogo from '../../images/logos/sports/baseball.png';
import VolleyballLogo from '../../images/logos/sports/Volleyball.png';
import FootballLogo from '../../images/logos/sports/football.png';
import BasketballLogo from '../../images/logos/sports/basketball.png';
import SoccerLogo from '../../images/logos/sports/Soccer.png';
import SoftballLogo from '../../images/logos/sports/softball.png';
import TrackLogo from '../../images/logos/sports/Track.png';
import WrestlingLogo from '../../images/logos/sports/Wrestling.png';
import SwimmingLogo from '../../images/logos/sports/Swimming.png';
import HockeyLogo from '../../images/logos/sports/hockey.png';
import XCLogo from '../../images/logos/sports/XC.png';
import WaterPoloLogo from '../../images/logos/sports/Water-Polo.png';
import SnowboardingLogo from '../../images/logos/sports/Snowboarding.png';
import SkiingLogo from '../../images/logos/sports/Skiing.png';
import SkateboardingLogo from '../../images/logos/sports/Skateboarding.png';
import RowingLogo from '../../images/logos/sports/Rowing.png';
import PingPongLogo from '../../images/logos/sports/Ping-Pong.png';
import Pickleball from '../../images/logos/sports/Pickleball.png';
import LacrosseLogo from '../../images/logos/sports/Lax.png';
import MMALogo from '../../images/logos/sports/MMA.png';
import NetballLogo from '../../images/logos/sports/Netball.png';
import GymnasticsLogo from '../../images/logos/sports/Gymnastics.png';
import FitnessLogo from '../../images/logos/sports/Fitness.png';
import EsportsLogo from '../../images/logos/sports/Esports.png';
import CheerLogo from '../../images/logos/sports/cheer.png';
import BoxingLogo from '../../images/logos/sports/boxing.png';
import BadmitonLogo from '../../images/logos/sports/Badminton.png';
import CricketLogo from '../../images/logos/sports/Cricket.png';
import DanceLogo from '../../images/logos/sports/Dance.png';
import FencingLogo from '../../images/logos/sports/Fencing.png';
import FieldHockeyLogo from '../../images/logos/sports/Field-Hockey.png';

function IconGrid() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const handleIconClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  const handleCloseClick = () => {
    setActiveIndex(null);
  };

  const general = {
      icon: TORQicon,
      sport: "general",
      instagram: 'https://www.instagram.com/torqsports',
      tiktok: 'https://www.tiktok.com/@torqsports',
      twitter: 'https://x.com/torqsports'
  }

  const socials = [
    {
        icon: GolfLogo,
        sport: "golf",
        instagram: 'https://www.instagram.com/torqgolfing/',
        tiktok: 'https://www.tiktok.com/@torqgolf',
        twitter: 'https://x.com/torqgolf'
    },
    {
        icon: BaseballLogo,
        sport: "baseball",
        instagram: 'https://www.instagram.com/torqbaseball',
        tiktok: 'https://www.tiktok.com/@torqsports',
        twitter: 'https://x.com/torqbaseballs'
    },
    {
        icon: VolleyballLogo,
        sport: "volleyball",
        instagram: 'https://www.instagram.com/torqvolleyball',
        tiktok: 'https://www.tiktok.com/@torqvolleyball',
        twitter: 'https://www.twitter.com/torqvolleyball'
    },
    {
      icon: BasketballLogo,
      sport: "basketball",
      instagram: 'https://www.instagram.com/torqbasketball',
      tiktok: 'https://www.tiktok.com/@torqbasketball',
      twitter: 'https://www.twitter.com/torqbasketball'
  },
  {
    icon: FootballLogo,
    sport: "football",
    instagram: 'https://www.instagram.com/torqfootball',
    tiktok: 'https://www.tiktok.com/@torqfootball',
    twitter: 'https://www.twitter.com/torqfootball'
  },
  {
    icon: SoccerLogo,
    sport: "soccer",
    instagram: 'https://www.instagram.com/torqsoccer',
    tiktok: 'https://www.tiktok.com/@torqsoccer',
    twitter: 'https://www.twitter.com/torqsoccer'
  },
  {
    icon: SoftballLogo,
    sport: "softball",
    instagram: 'https://www.instagram.com/torqsoftball',
    tiktok: 'https://www.tiktok.com/@torqsoftball',
    twitter: 'https://www.twitter.com/torqsoftball'
  },
  {
    icon: TrackLogo,
    sport: "Track",
    instagram: 'https://www.instagram.com/torqtrack',
    tiktok: 'https://www.tiktok.com/@torqtrack',
    twitter: 'https://www.twitter.com/torqtrack'
  },
  {
    icon: SwimmingLogo,
    sport: "wrestling",
    instagram: 'https://www.instagram.com/torqwrestling',
    tiktok: 'https://www.tiktok.com/@torqwrestling',
    twitter: 'https://www.twitter.com/torqwrestling'
  },
  {
    icon: HockeyLogo,
    sport: "wrestling",
    instagram: 'https://www.instagram.com/torqwrestling',
    tiktok: 'https://www.tiktok.com/@torqwrestling',
    twitter: 'https://www.twitter.com/torqwrestling'
  },
  {
    icon: XCLogo,
    sport: "xc",
    instagram: 'https://www.instagram.com/torqxc',
    tiktok: 'https://www.tiktok.com/@torqxc',
    twitter: 'https://www.twitter.com/torqxc'
  },
  {
    icon: WrestlingLogo,
    sport: "wrestling",
    instagram: 'https://www.instagram.com/torqwrestling',
    tiktok: 'https://www.tiktok.com/@torqwrestling',
    twitter: 'https://www.twitter.com/torqwrestling'
  },
  {
    icon: WaterPoloLogo,
    sport: "waterpolo",
    instagram: 'https://www.instagram.com/torqwaterpolo',
    tiktok: 'https://www.tiktok.com/@torqwaterpolo',
    twitter: 'https://www.twitter.com/torqwaterpolo'
  },
  {
    icon: SnowboardingLogo,
    sport: "snowboarding",
    instagram: 'https://www.instagram.com/torqsnowboarding',
    tiktok: 'https://www.tiktok.com/@torqsnowboarding',
    twitter: 'https://www.twitter.com/torqsnowboarding'
  },
  {
    icon: SkiingLogo,
    sport: "skiing",
    instagram: 'https://www.instagram.com/torqskiing',
    tiktok: 'https://www.tiktok.com/@torqskiing',
    twitter: 'https://www.twitter.com/torqskiing'
  },
  {
    icon: SkateboardingLogo,
    sport: "skateboarding",
    instagram: 'https://www.instagram.com/torqskateboarding',
    tiktok: 'https://www.tiktok.com/@torqskateboarding',
    twitter: 'https://www.twitter.com/torqskateboarding'
  },
  {
    icon: RowingLogo,
    sport: "rowing",
    instagram: 'https://www.instagram.com/torqrowing',
    tiktok: 'https://www.tiktok.com/@torqrowing',
    twitter: 'https://www.twitter.com/torqrowing'
  },
  {
    icon: PingPongLogo,
    sport: "pingpong",
    instagram: 'https://www.instagram.com/torqpingpong',
    tiktok: 'https://www.tiktok.com/@torqpingpong',
    twitter: 'https://www.twitter.com/torqpingpong'
  },
  {
    icon: Pickleball,
    sport: "pickleball",
    instagram: 'https://www.instagram.com/torqpickleball',
    tiktok: 'https://www.tiktok.com/@torqpickleball',
    twitter: 'https://www.twitter.com/torqpickleball'
  },
  {
    icon: LacrosseLogo,
    sport: "lacrosse",
    instagram: 'https://www.instagram.com/torqlacrosse',
    tiktok: 'https://www.tiktok.com/@torqlacrosse',
    twitter: 'https://www.twitter.com/torqlacrosse'
  },  
];

  
  return (
    <div className="flex flex-col items-center justify-center gap-8">
      <div className="flex flex-col" onClick={() => toggle()}>
        <img src={TORQicon} alt="TORQ Icon" className="w-24 h-24 cursor-pointer rounded-full border-solid border-orange hover:border-2" />
        {isOpen ? (
          <div className="flex justify-center items-center gap-2 text-white">
            <a href={general.instagram} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href={general.tiktok} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} size="2x" />
            </a>
            <a href={general.twitter} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faXTwitter} size="2x" />
            </a>
        </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="grid grid-cols-3 md:grid-cols-5 gap-10 md:gap-12 w-fit">
        {socials.map((account, index) => (
          <div key={index} className="relative pb-2" onClick={() => handleIconClick(index)}>
            <img src={account.icon} alt={`Icon ${index}`} className="w-24 h-24 cursor-pointer rounded-full border-solid border-orange hover:border-2" />
            {activeIndex === index && (
              <div className="absolute -bottom-10 left-0 bg-black bg-opacity-75 flex justify-center items-center gap-2 text-white">
                <a href={account.instagram} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a href={account.tiktok} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTiktok} size="2x" />
                </a>
                <a href={account.twitter} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faXTwitter} size="2x" />
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default IconGrid;