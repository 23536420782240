import React, { useContext, useEffect } from 'react';

const VideoPlayer = ({ videoUrl, style }) => {

  return (
    <video 
      
      controls
      className={`border-solid border border-black rounded-md ${style}`}
    >
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer