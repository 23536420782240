import React from 'react';

const CollegeConnectFilter = ({ handleFilterChange, formData, states, divisions, totalResults }) => {
  return (
    <div className="text-black flex flex-col justify-center items-start">
      <div className='flex justify-between w-full'>
        <span className=' text-2xl text-blue italic'>Filter By:</span>
        <div className='flex gap-2'>
          <span className=' text-2xl text-blue'>Results:</span>
          <span className=' text-2xl text-blue italic'>{totalResults ? totalResults : 0}</span>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 w-full">
        
        <div className='flex flex-col'>
          
          <select 
            id="region"
            name="region"
            value={formData.region}
            onChange={handleFilterChange}
            className="block w-full mt-2 p-2 bg-[#000000] border-b border-blue text-blue font-light"
          >
            <option value="">Region</option>
            <option value="Northeast">Northeast</option>
            <option value="Mid-Atlantic">Mid-Atlantic</option>
            <option value="Midwest">Midwest</option>
            <option value="South">South</option>
            <option value="Southwest">Southwest</option>
            <option value="West">West</option>
            <option value="Canada">Canada</option>
          </select>
        </div>
      
        <div className="flex flex-col">
         
          <select
            id="state"
            name="state"
            value={formData.state}
            onChange={handleFilterChange}
            className="block w-full mt-2 p-2 bg-[#000000] border-b border-blue text-blue font-light"
          >
            <option value="">State</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>

        <div className='flex flex-col'>
          
          <select
            id="division"
            name="division"
            value={formData.division}
            onChange={handleFilterChange}
            className="block w-full mt-2 p-2 bg-[#000000] border-b border-blue text-blue font-light"
          >
            <option value="">Division</option>
            {divisions.map((division) => (
              <option key={division} value={division}>
                {division}
              </option>
            ))}
            {/* <option value="JC-D1">JC D1</option>
            <option value="JC-D2">JC D2</option>
            <option value="JC-D3">JC D3</option>
            <option value="JC-Canada">JC Canada</option>
            <option value="JC-CCCAA">JC CCCAA</option>
            <option value="JC-NWAC">JC NWAC</option>
            <option value="NAIA">NAIA</option> */}
          </select>
        </div>
        
        <div className='flex flex-col'>
          
          <select
            id="tuition"
            name="tuition"
            value={formData.tuition}
            onChange={handleFilterChange}
            className="block w-full mt-2 p-2 bg-[#000000] border-b border-blue text-blue font-light"
          >
            <option value=''>Tuition</option>
            <option value="0-10000">$0 - $10,000</option>
            <option value="10000-20000">$10,000 - $20,000</option>
            <option value="20000-40000">$20,000 - $40,000</option>
            <option value="40000-60000">$40,000 - $60,000</option>
            <option value="60000-100000">$60,000 - $100,000</option>
          </select>
        </div>

        <div className='flex flex-col'>
          
          <select
            id="gpa"
            name="gpa"
            value={formData.gpa}
            onChange={handleFilterChange}
            className="block w-full mt-2 p-2 bg-[#000000] border-b border-blue text-blue font-light"
          >
            <option value={''}>GPA</option>
            <option value={"3.8-4.2"}>3.8 - 4.2</option>
            <option value={"3.3-3.7"}>3.3 - 3.7</option>
            <option value={"2.8-3.2"}>2.8 - 3.2</option>
            <option value={"2.3-2.7"}>2.3 - 2.7</option>
            <option value={"2.0-2.3"}>2.0 - 2.3</option>
          </select>
        </div>
        
      </div>
    </div>
  );
};

export default CollegeConnectFilter;

