import React from 'react';
import { Link } from 'react-router-dom';
import VideoBG from '../../videos/torq-commercial.mp4';
import TorqSportsLogo from "../../images/Torq-Sports-Horizontal.png";
import { useAuth } from '../../contexts/AuthProvider';

const CommercialVideo = () => {
  const { authentication } = useAuth()
  return (
    <div className="relative w-full h-screen overflow-hidden">
      <video
        src={VideoBG}
        className="absolute inset-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
      />
      <div className="absolute inset-0 bg-black/40"></div>
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white gap-4 px-4 text-center">
        <img src={TorqSportsLogo} className=" w-11/12 sm:w-1/2 lg:w-1/3 object-contain" alt="Torq Sports Logo" />
        <div className="flex flex-wrap justify-center items-center gap-1 text-xl md:text-3xl">
          <span>Track</span>
          <span className="text-orange">-</span>
          <span>Optimize</span>
          <span className="text-orange">-</span>
          <span>Record</span>
          <span className="text-orange">-</span>
          <span>Quantify</span>
        </div>
        <Link to="/college-connect" className="border-solid border border-orange text-3xl py-2 px-8 sm:px-12 rounded-full hover:bg-orange hover:text-black duration-300 ease-linear ">Start Connecting</Link>
        {authentication ? (<div></div>
          ) : (
        //    <Link
        //    to="/signUp"
        //    className="mt-4 text-xl py-2 px-12 font-extralight text-orange rounded-full border-2 border-orange hover:bg-orange hover:border-white hover:text-white duration-200 ease-in-out"
        //  >
        //    Sign Up
        //  </Link> 
        <></>
        )}
      </div>
    </div>
  );
};

export default CommercialVideo;
