import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import TorqIcon from "../../images/icons/Torq-Logo-TightCrop.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowUp, faCrown, faCoins, faUser, faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from "../../contexts/AuthProvider";
import LogOutButton from "../auth/LogOut";
import Loading from "./Loading";

const Navbar = () => {
    const { authentication, userId, role, tokens } = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const location = useLocation();

    const toggle = () => setIsOpen(!isOpen);
    // Close the navbar when the location changes
    useEffect(() => {
        setIsOpen(false);
        
    }, [location, authentication, tokens, userId]);

    return (
        <nav className="bg-black text-white bg-opacity-50 fixed w-full z-50">
            <div className="flex justify-between items-center py-2 px-2 sm:px-[5%]">
                <div className="w-16">
                    <Link to="/" className="block hover:text-white duration-200 ease-in">
                        <img src={TorqIcon} alt="Logo" />
                    </Link>
                </div>
                <div className="hidden lg:flex lg:flex-row lg:items-center lg:gap-6">
                    <ul className="flex flex-row gap-6 font-bold text-xl">
                        <li>
                            <Link to="/partners" className="hover:text-orange duration-200 ease-in">Partners</Link>
                        </li>
                        <li>
                            <Link to="/highlights" className="hover:text-orange duration-200 ease-in">Highlights</Link>
                        </li>
                        <li className="relative group">
                            <Link to="/torq-ai" className="hover:text-orange duration-200 ease-in">
                                TORQ Products <span className="font-extrabold">&#8744;</span>
                            </Link>
                            <ul className="absolute left-0 text-nowrap bg-black bg-opacity-80 text-white shadow-lg opacity-0 group-hover:opacity-100 transform transition-opacity duration-300 ease-in-out hidden group-hover:block">
                                <li className="ml-4 p-2 hover:text-orange">
                                    <Link to="/college-connect" className="block">College Connect</Link>
                                </li>
                                <li className="ml-4 p-2 hover:text-orange">
                                    <Link to="/video-automation" className="block">Create Your MyLight</Link>
                                </li>
                                <li className="flex justify-start items-center gap-2 py-2 px-4 hover:text-orange ml-2">
                                    <Link to="/search-players" className="block">Find Players</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/social-media" className="hover:text-orange duration-200 ease-in">Social Media</Link>
                        </li>
                    </ul>
                    {authentication ? (
                        <div className="flex items-center gap-6">
                            <div className="flex items-center gap-2">
                                <FontAwesomeIcon icon={faCoins} size="lg" />
                               {(role === "premium user") ? <span>Unlimited</span> : <span>{tokens?.toLocaleString()}</span>}  
                            </div>
                            <Link to={`/profile/${userId}`} className="flex flex-col gap-1 hover:text-orange  duration-200 ease-in">
                                <FontAwesomeIcon icon={faUser} size="lg" />
                                <span className="text-sm">Profile</span>
                            </Link>
                            <LogOutButton />
                        </div>
                    ) : (
                        <div className="flex items-center gap-6">
                            <Link to="/login" className="bg-orange py-2 px-8 rounded-md border-solid border-orange hover:bg-transparent hover:border-2 duration-200 ease-in-out">Login</Link>
                            <Link to="/signup" className="bg-blue bg-opacity-75 py-2 px-8 rounded-md border-solid border-blue hover:bg-transparent hover:border-2 duration-200 ease-in-out">Sign Up</Link>
                        </div>
                    )}
                </div>
                <button
                    onClick={toggle}
                    className="block text-white text-3xl cursor-pointer lg:hidden focus:outline-none transition duration-300 ease-in-out"
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div>
            {isOpen && (
                <div className="lg:hidden">
                    <ul className="flex flex-col font-bold text-xl px-2 sm:px-[10%] bg-black bg-opacity-50">
                        {authentication && (
                            <li className="flex justify-between items-center py-3 border-b border-white">
                                <Link to={`/profile/${userId}`} className="flex items-center gap-2 hover:text-orange duration-200 ease-in">
                                    <FontAwesomeIcon icon={faUser} size="lg" />
                                    <span>Profile</span>
                                </Link>
                                <div className="flex items-center gap-2">
                                    <FontAwesomeIcon icon={faCoins} size="lg" />
                                    <span>{tokens?.toLocaleString()}</span>
                                </div>
                                <LogOutButton />

                            </li>
                        )}
                        <li className="py-3 border-b border-white">
                            <Link to="/partners" className="block hover:text-orange duration-200 ease-in">Partners</Link>
                        </li>
                        <li className="py-3 border-b border-white">
                            <Link to="/highlights" className="block hover:text-orange duration-200 ease-in">Highlights</Link>
                        </li>
                        <li className="py-3 border-b border-white relative group">
                            <Link to="/torq-ai" className="block hover:text-orange duration-200 ease-in">
                                TORQ Products <FontAwesomeIcon icon={faCaretDown} />
                            </Link>
                            <ul className="relative left-0 top-full  hidden group-hover:block transition-opacity duration-300 ease-in-out">
                                <li className="flex justify-start items-center gap-2 py-2 px-4 hover:text-orange">
                                    <img src={TorqIcon} alt="TORQ Icon" className="w-4 h-4"/>
                                    <Link to="/college-connect" className="block">College Connect</Link>
                                </li>
                                <li className="flex justify-start items-center gap-2 py-2 px-4 hover:text-orange">
                                    <img src={TorqIcon} alt="TORQ Icon"  className="w-4 h-4"/>
                                    <Link to="/video-automation" className="block">Create Your MyLight</Link>
                                </li>
                                <li className="flex justify-start items-center gap-2 py-2 px-10 hover:text-orange ml-10">
                                    <img src={TorqIcon} alt="TORQ Icon"  className="w-4 h-4"/>
                                    <Link to="/search-players" className="block">Find Players</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="py-3">
                            <Link to="/social-media" className="block hover:text-orange duration-200 ease-in">Social Media</Link>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;