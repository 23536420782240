import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthProvider';
import Loading from '../utilities/Loading';

function Security({}) {
  const { token } = useAuth();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [securityInfo, setSecurityInfo] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    twoFactorAuth: false
  });


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSecurityInfo({
      ...securityInfo,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = fetch('/api/edit-profile/security', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(securityInfo),
    })

    console.log("Response: ", res);

    if(!res.ok) {
      console.log("Failed to update security information");
      setError("Failed to update security information");
      return;
    }

    var data = await res.json();
    console.log("Data: ", data);
    setSuccess(data.message);
    setError('');
    setLoading(false);
  };

  return (
    <div className="text-2xl flex flex-col gap-4">
      <h2 className="text-4xl text-nowrap font-bold">Security Settings</h2>
      <section className="flex flex-col gap-4 lg:flex-row lg:justify-between mb-4">
          <Link to="/terms" className='w-fit border-solid border-2 border-blue py-1 px-4 rounded-lg hover:bg-sky-800 duration-200 ease-in'>Terms of Service</Link>
          <Link to="/terms" className='w-fit border-solid border-2 border-blue py-1 px-4 rounded-lg hover:bg-sky-800 duration-200 ease-in'>Privacy Policy</Link>
      </section>
      <section className="flex flex-col lg:flex-row lg:justify-between">
        <label htmlFor="currentPassword" className="mb-2 lg:mb-0">
          Current Password:
        </label>
        <input
          type="password"
          id="currentPassword"
          name="currentPassword"
          value={securityInfo.currentPassword}
          onChange={handleChange}
          className="bg-white text-black p-2 border-solid border border-black rounded-md font-normal "
        />
      </section>
      <section className="flex flex-col lg:flex-row lg:justify-between">
        <label htmlFor="newPassword" className="mb-2 lg:mb-0">
          New Password:
        </label>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          value={securityInfo.newPassword}
          onChange={handleChange}
          className="bg-white text-black p-2 border-solid border border-black rounded-md font-normal "
        />
      </section>
      <section className="flex flex-col lg:flex-row lg:justify-between">
        <label htmlFor="confirmPassword" className="mb-2 lg:mb-0">
          Confirm New Password:
        </label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={securityInfo.confirmPassword}
          onChange={handleChange}
          className="bg-white text-black p-2 border-solid border border-black rounded-md font-normal"
        />
      </section>
      {error && <p className="text-white bg-red-600 p-2 rounded-lg text-center">{error}</p>}
      {success && <p className="text-white bg-green-600 p-2 rounded-lg text-center">{success}</p>}
      <button
        type="submit"
        onClick={handleSubmit}
        className="bg-white border-solid border-white hover:bg-black hover:text-white hover:border-2 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
      >
        Save
      </button>
    </div>
  );
}

export default Security;
