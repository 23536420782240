import React from 'react'
import LivestreamInformation from '../components/home/LivestreamInformation'
import Upgrade from '../components/utilities/Upgrade'
import OurPartners from '../components/livestream/Information'
import LoginPopup from '../components/popups/LoginPopup'

function Partners() {
  return (
    <div className='bg-pitch-black min-h-screen text-white text-center text-2xl mx-auto flex flex-col items-center gap-12 px-4 py-24'>
      <OurPartners />
    </div>
  )
}

export default Partners