import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthProvider';


const SearchResults = ({ players, hasSearched, onLoadMore, isLastUser }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const {role} = useAuth();

  const sortedPlayers = React.useMemo(() => {
    let sortablePlayers = [...players];
    if (sortConfig.key !== null) {
      sortablePlayers.sort((a, b) => {
          
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
        
        // Handle empty strings
        if (aValue === '') return 1;
        if (bValue === '') return -1;
        
        if (sortConfig.key === 'gradYear' || sortConfig.key === 'gpa' || sortConfig.key === 'weight') {
          aValue = parseFloat(aValue); 
          bValue = parseFloat(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePlayers;
  }, [players, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="container mx-auto p-4 mt-6 bg-black rounded-lg">
      {hasSearched && (
        <h2 className="text-3xl font-bold mb-4 text-white font-a4">Search Results</h2>
      )}

      {hasSearched && role !== "premium" ? (
        <p className="text-red-500 mt-4">To access this feature you must be a premium user.</p>
      ) : (
        <>
          {sortedPlayers.length > 0 ? (
            <>
              <table className="min-w-full bg-gray-800 border border-">
                <thead>
                  <tr className="bg-blue text-white text-left border-blue">
                    <th className="py-2 px-4 border-b border-blue cursor-pointer" onClick={() => requestSort('fullname')}>Name</th>
                    <th className="py-2 px-4 border-b border-blue cursor-pointer" onClick={() => requestSort('gradYear')}>Grad Yr.</th>
                    <th className="py-2 px-4 border-b border-blue cursor-pointer" onClick={() => requestSort('gpa')}>GPA</th>
                    <th className="py-2 px-4 border-b border-blue cursor-pointer" onClick={() => requestSort('currentSchool')}>School</th>
                    <th className="py-2 px-4 border-b border-blue cursor-pointer" onClick={() => requestSort('travelClub')}>Club</th>
                    <th className="py-2 px-4 border-b border-blue cursor-pointer" onClick={() => requestSort('fromState')}>State</th>
                    <th className="py-2 px-4 border-b border-blue cursor-pointer" onClick={() => requestSort('position')}>Pos.</th>
                    <th className="py-2 px-4 border-b border-blue cursor-pointer" onClick={() => requestSort('height')}>Height</th>
                    <th className="py-2 px-4 border-b border-blue cursor-pointer" onClick={() => requestSort('weight')}>Weight</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedPlayers.map((player, index) => (
                    <tr key={index} className="bg-gray-700 hover:bg-gray-600">
                      <td className="py-2 px-4 border-b border-blue text-white">
                        <Link to={`/search-players/${player.id}`}>{player.fullname}</Link>
                      </td>
                      <td className="py-2 px-4 border-b border-blue text-white">{player.gradYear}</td>
                      <td className="py-2 px-4 border-b border-blue text-white">{player.gpa}</td>
                      <td className="py-2 px-4 border-b border-blue text-white">{player.currentSchool}</td>
                      <td className="py-2 px-4 border-b border-blue text-white">{player.travelClub}</td>
                      <td className="py-2 px-4 border-b border-blue text-white">{player.fromState}</td>
                      <td className="py-2 px-4 border-b border-blue text-white">{player.position}</td>
                      <td className="py-2 px-4 border-b border-blue text-white">{player.height}</td>
                      <td className="py-2 px-4 border-b border-blue text-white">{player.weight} {player.weight ? "lbs" : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!isLastUser && (
                <div className="flex flex-col align-center justify-center mt-4">
                  <button 
                    onClick={onLoadMore} 
                    className="mt-4 px-4 bg-blue-500 text-white w-36 h-8 leading-7 font-bold"
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          ) : (
            hasSearched && sortedPlayers.length === 0 && <p className="text-white mt-4">No players found.</p>
          )}
        </>
      )}
    </div>
  );
};


export default SearchResults;