// FormContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';
// Create a context
const MultistepContext = createContext();

const debug = +process.env.REACT_APP_DEBUG;

const sendEmails = async (token, subject, body, recipients, accessToken) => {
  try {
    const response = await fetch('/api/email/user-send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        subject,
        body,
        recipients: recipients.join(','),
        accessToken,
      }),
    });
    if (!response.ok) {
      throw new Error('Error sending email: ', response.statusText);
    }
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error(error);
  }
};

// Create a provider component
export const MultistepProvider = ({ children }) => {
  const { user, token, tokens } = useAuth();
  const navigate = useNavigate();
  const { fullname, gradYear, position, secondaryPosition, currentSchool } = JSON.parse(user);
  const defaultSubject = `${fullname ? fullname : "Your Name"} - ${position ? position : "primary position"}, ${secondaryPosition ? secondaryPosition : "secondary position"} - ${gradYear ? gradYear : "grad year"}`;
  const defaultMessage = `Hi {COACH_LAST_NAME} ,\nMy name is ${fullname  ? fullname : "Your Name"}. I am a ${gradYear} grad year. I am very interested in playing at {UNIVERSITY_NAME}.`;
  const defaultSignature = `Best regards,\n\n${fullname ? fullname : "[Full Name]"}\n${currentSchool ? currentSchool : "[Current School]"}\n[Phone Number]\n[Email Address]`;

  const [step, setStep] = useState(1);
  const [sports, setSports] = useState([]);
  const [gmailAccessToken, setGmailAccessToken] = useState("");
  const [selectedSport, setSelectedSport] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [schoolNameList, setSchoolNameList] = useState([]);
  const [subject, setSubject] = useState(defaultSubject);
  const [message, setMessage] = useState(defaultMessage);
  const [signature, setSignature] = useState(defaultSignature);
  const [includeCoachRecommendations, setIncludeCoachRecommendations] = useState(false);
  const [attachHighlight, setAttachHighlight] = useState(false);
  const [includeTorqLink, setIncludeTorqLink] = useState(false);
  const [video, setVideo] = useState(sessionStorage.getItem("video"));

  const MAX_FILE_SIZE = 100 * 1024 * 1024  // 100mb

  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleSignatureChange = (e) => setSignature(e.target.value);

  const handleIncludeCoachRecommendationsChange = (e) => setIncludeCoachRecommendations(e.target.checked);
  const handleAttachHighlightChange = (e) => setAttachHighlight(e.target.checked);
  const handleIncludeTorqLinkChange = (e) => setIncludeTorqLink(e.target.checked);

  const addSchooltoSchoolNameList = (school) => {
    setSchoolNameList([...schoolNameList, school]);
  };

  const removeSchoolFromSchoolNameList = (school) => {
    setSchoolNameList(schoolNameList.filter((s) => s !== school));
  };

  const getTutorialVideo = async () => {
    const res = await fetch(`/api/college-connect/tutorial-video/college-connect-tutorial.mov`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    });
  
    if (!res.ok) {
      console.error("Failed to fetch tutorial videos");
      return;
    }
  
    const data = await res.json();
  
    if (debug) console.log("Links: ", data);
    sessionStorage.setItem("videos", JSON.stringify(data));
    setVideo(data);
  }

  const handleFileChange = (event) => {
    console.log("Uploading free video...");
    const files = event.target.files;
    const allowedExtensions = ['mp4', 'mov'];
    let newFiles = [];

    if (files.length > 1) {
      setErrorMessage('You can only upload one video.');
      setSelectedFiles([]);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = file.name.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes(extension)) {
        setErrorMessage('Invalid file format. Please upload only MP4 or MOV videos.');
        setSelectedFiles([]);
        return;
      }

      if(file.size > MAX_FILE_SIZE) {
        setErrorMessage('File size exceeds 100MB.');
        setSelectedFiles([]);
        return;
      } else {
        console.log("File size is within limits: ", (file.size / 1024 / 1024).toFixed(2), "MB");
        setErrorMessage('');
      }

      newFiles.push(file);
      setSuccessMessage('Video uploaded successfully');
      setSelectedFiles(newFiles);
    }

  };

  const handleCreateVideoClick = () => {
    navigate("/coming-soon");
    //setStep(4);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const handlePaymentOption = (packageInfo) => {
    setSelectedPackage(packageInfo);
    nextStep()
    // Cole
    navigate("/buy-tokens")
  };

  const fetchSports = async () => {
    getTutorialVideo();

    const res = await fetch("/api/college-connect/sports", {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    });

    if(!res.ok) {
      console.error("Failed to fetch sports");
      return;
    }

    const data = await res.json();
    setSports(data);

    return data;
  }

  

  const submitCollegeConnect = async () => {
    // Create a FormData object
    
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('message', message);
    formData.append('signature', signature);
    formData.append('sport', selectedSport);
    formData.append('schoolNameList', JSON.stringify(schoolNameList)); // Convert array to JSON string
    formData.append('includeCoachRecommendations', includeCoachRecommendations);
    formData.append('attachHighlight', attachHighlight);
    formData.append('includeTorqLink', includeTorqLink);
    formData.append("tokens", tokens);
    formData.append('file', selectedFiles[0]);

    // Check for space in selected sport and replace with "-"
    
    const res = await fetch(`/api/college-connect/submit`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
      },
      body: formData
    });

    // const emailRes = await sendEmails(token, subject, message, schoolNameList, tokens);

    if(!res.ok) {
      console.error("Failed to submit college connect form");
      return;
    }
   
    nextStep();

  }

  const handleSubmit = () => {
    // Add your submit logic here
  };

  useEffect(() => {

  }, [fullname, gradYear, position, secondaryPosition]);

  const value = {
    sports,
    video,
    fetchSports,
    selectedSport,
    setSelectedSport,
    step,
    setStep,
    selectedFiles,
    setSelectedFiles,
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
    selectedPackage,
    setSelectedPackage,
    signature,
    message,
    subject,
    handleSubjectChange,
    handleSignatureChange,
    schoolNameList,
    setSchoolNameList,
    addSchooltoSchoolNameList,
    removeSchoolFromSchoolNameList,
    handleFileChange,
    handleCreateVideoClick,
    nextStep,
    handlePaymentOption,
    handleSubmit,
    submitCollegeConnect,
    handleIncludeCoachRecommendationsChange,
    handleAttachHighlightChange,
    handleIncludeTorqLinkChange,
    includeCoachRecommendations,
    attachHighlight,
    includeTorqLink,
    setGmailAccessToken,
    setMessage
  };

  return <MultistepContext.Provider value={value}>{children}</MultistepContext.Provider>;
};

// Custom hook to use the form context
export const useMultistepContext = () => useContext(MultistepContext);
