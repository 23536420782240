import React, { useState, useEffect } from 'react';
import TorqSportsTightCrop from '../../images/icons/Torq-Logo-TightCrop.png'; // Import PNG image

// Loading component
const Loading = ({ isLoading }) => {
  return (
    isLoading && ( // Only render if isLoading is true
      <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
        <img
          src={TorqSportsTightCrop} // Replace with your PNG image path
          alt="Loading..."
          className="w-20 h-20 animate-spin"
        />
      </div>
    )
  );
};

export default Loading;