// basic react component

import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Search from '../components/search-players/SearchForm';

const SearchPlayers = () => {
    const location = useLocation();
    useEffect(() => {
        // Clear search state from sessionStorage if navigating away from search or player pages
        if (!location.pathname.startsWith('/search-players')) {
          sessionStorage.removeItem('searchFilters');
          sessionStorage.removeItem('searchResults');
        }
      }, [location.pathname]);
    return (
        <div className="bg-black p-10">
            <Search />
        </div>
    );
}

export default SearchPlayers;