import React, { useState } from 'react';
import { useMultistepContext } from '../../contexts/MultistepProvider';

function Card({ data, add, remove, isSelected }) {
  var isSchoolNameLong = data.school_name.length > 25;
  var isConferenceLong = data.Conference.length > 20;
  var isStateLong = data.State.length > 18;

  return (
    <div className="bg-black flex flex-col md:flex-row gap-4 items-start md:items-center rounded-md w-full text-white p-1 md:p-2 border-solid md:border border-white">
      <div className="flex flex-col border-solid mx-auto bg-opacity-80">
          <div className='w-[200px] h-[200px] bg-[#ffffff] rounded-lg'>
            <img className="w-[200px] h-[200px] object-contain" src={data.logo_link} alt="Image of school logo" />
          </div>
      </div>
      
      <div className='flex flex-col justify-between gap-2 lg:h-[200px] md:h-fit w-full'>
        <div className="flex flex-col md:flex-row justify-between md:gap-2">
          
          <div className='flex flex-col text-center'>
            <div className="flex flex-col justify-center md:justify-start md:items-start">
              <span className={`text-3xl font-bold text-center ${isSchoolNameLong ? 'text-wrap' : ''}`}>{data.school_name}</span>
              <span className="text-2xl italic text-blue text-center lg:text-start text-wrap">{data.City}, {data.State}</span>
            </div>

            <div className={`flex flex-col ${isConferenceLong ? 'md:flex-col' : 'md:flex-row md:gap-4'}  text-center lg:text-start`}>
              <span className="text-2xl text-white font-bold">Division {data.division}</span>
              <span className="text-2xl text-white italic">{data.Conference}</span>
            </div>
          </div>

          <div className='flex flex-col lg:flex-row gap-2 justify-center p-4 md:p-0'>
              {isSelected(data.school_name) ? (
                <button onClick={() => remove(data.school_name)} className="bg-orange text-xl text-center text-black font-semibold border-solid border border-orange p-2 w-full md:w-auto hover:bg-blue hover:shadow-blue hover:text-white hover:border-blue duration-200 ease-in h-fit">- Remove</button>
              ) : (
                <button onClick={() => add(data.school_name)} className="bg-blue text-xl text-center text-black font-semibold border-solid border border-blue p-2 w-full md:w-auto hover:bg-orange hover:shadow-orange hover:text-white hover:border-orange duration-200 ease-in h-fit">+ Add to list</button>
              )}
            <div className="flex flex-col border-blue border-solid border-2 h-fit">
              <span className="bg-blue text-black px-4">Rank</span>
              <span className="text-2xl p-2">-</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-2 text-xl p-4 md:p-0">
          <div className="flex flex-col border-orange border-solid border-2 rounded-lg w-full">
            <span className="bg-orange text-black ">Tuition Cost</span>
            <span className="text-xl p-2">{data.MaxTuition ? `$ ${data.MaxTuition.toLocaleString()}` : "-"}</span>
          </div>
          <div className="flex flex-col border-orange border-solid border-2 rounded-lg w-full">
            <span className="bg-orange text-black">Undergrads</span>
            <span className="text-xl p-2">{data.NumOfUndergrads ? data.NumOfUndergrads.toLocaleString() : "-"}</span>
          </div>
          <div className="flex flex-col border-orange border-solid border-2 rounded-lg w-full">
            <span className="bg-orange text-black">Average GPA</span>
            <span className="text-xl p-2">{data.AverageGPA ? data.AverageGPA.toLocaleString() : "-"}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function CollegeConnectResults({ results, incrementEmailCount, decrementEmailCount}) {
  const { addSchooltoSchoolNameList, removeSchoolFromSchoolNameList, schoolNameList } = useMultistepContext();
  const [displayCount, setDisplayCount] = useState(10);
  const [copy, setCopy] = useState(false);
  
  const handleLoadMore = () => {
    // Increase the display count to show more elements
    setDisplayCount(displayCount + 10);
  };

  const add = (college) => {
    if(schoolNameList.length < 10 && !schoolNameList.includes(college)) {
      addSchooltoSchoolNameList(college);
      incrementEmailCount();
    }
  };

  const remove = (college) => {
    if(schoolNameList.length > 0 && schoolNameList.includes(college)) {
      removeSchoolFromSchoolNameList(college);
      decrementEmailCount();
    }
  }

  const isSelected = (college) => {
    return schoolNameList.includes(college);
  }

  return (
    <>
      <div className="w-screen px-[10%]">
        {results.length === 0 ? (
          <div className="h-screen">
            <p className="px-4 py-2 text-center text-white text-2xl text-wrap">
              There are no results to display. Try different filters.
            </p>
          </div> 
        ) : (
          <div className="flex flex-col gap-4 text-white">
            {results.slice(0, displayCount).map((result) => (
              <div key={result.logo_link} className="animate-fadeIn">
                <Card data={result} add={add} remove={remove} isSelected={isSelected}/>
              </div>
            ))}
          </div>
        )}
      </div>
      {results.length > displayCount && (
        <button onClick={handleLoadMore} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-full">
          Load More
        </button>
      )}
    </>
  );
}

export default CollegeConnectResults;