import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthProvider';


const BuyPremiumForm = ({ prevStep, signupData }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const { token, userId, createUser, loginUser, loading } = useAuth();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setIsSubmitting(true);
            if (prevStep) {
                console.log('Creating user...');
                signupData.accountType = 'free' // will set to premium when payment is successful
                await createUser(signupData);
                await loginUser(signupData.email, signupData.password);
            }
        }
    };

    useEffect(() => {
        const makePaymentCall = async () => {
            if (isSubmitting && token && !loading) {
                console.log('Making payment call with token:', token);
                const { firstName, lastName, email, phone } = formData;
                const requestMethod = 'WEB';
                const paymentData = {
                    userId,
                    firstName,
                    lastName,
                    email,
                    phone,
                    request_method: requestMethod,
                };

                try {
                    const response = await fetch('/api/payment/create-payment', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(paymentData)
                    });

                    const data = await response.json();
                    if (response.status === 200) {
                        console.log('Payment URL:', data.payment_url);
                        setPaymentUrl(data.payment_url);
                        setIsSuccess(true);
                    }
                } catch (error) {
                    setIsSuccess(false);
                    console.error('Error:', error);
                } finally {
                    console.log('No longer submitting');
                    setIsSubmitting(false);
                }
            }
        };

        makePaymentCall();
    }, [isSubmitting, token, loading]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let fieldErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\d{10}$|^\d{3}-\d{3}-\d{4}$/;

        switch (name) {
            case 'firstName':
                if (!value) {
                    fieldErrors.firstName = 'First name is required';
                } else {
                    delete fieldErrors.firstName;
                }
                break;
            case 'lastName':
                if (!value) {
                    fieldErrors.lastName = 'Last name is required';
                } else {
                    delete fieldErrors.lastName;
                }
                break;
            case 'email':
                if (!value && !formData.phone) {
                    fieldErrors.email = 'Either email or phone number is required';
                    fieldErrors.phone = 'Either email or phone number is required';
                } else if (value && !emailRegex.test(value)) {
                    fieldErrors.email = 'Invalid email format';
                } else {
                    delete fieldErrors.email;
                    delete fieldErrors.phone;
                }
                break;
            case 'phone':
                if (!value && !formData.email) {
                    fieldErrors.phone = 'Either email or phone number is required';
                    fieldErrors.email = 'Either email or phone number is required';
                } else if (value && !phoneRegex.test(value)) {
                    fieldErrors.phone = 'Invalid phone number format';
                } else {
                    delete fieldErrors.phone;
                    delete fieldErrors.email;
                }
                break;
            default:
                break;
        }

        setErrors(fieldErrors);
    };

    const validate = () => {
        let formErrors = { ...errors };

        if (!formData.firstName) {
            formErrors.firstName = 'First name is required';
        }
        if (!formData.lastName) {
            formErrors.lastName = 'Last name is required';
        }
        if (!formData.email && !formData.phone) {
            formErrors.email = 'Either email or phone number is required';
            formErrors.phone = 'Either email or phone number is required';
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    


    return (
        <form onSubmit={handleSubmit} id="buyPremiumSubscriptionForm">
            <h2 className="text-2xl font-a4 text-center mt-10">Go Premium</h2>
            <div className="mb-4">
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-300">First Name</label>
                <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border-2 ${
                        errors.firstName ? 'border-red-500' : 'border-gray-600'
                    } rounded-md shadow-sm focus:outline-none ${
                        errors.firstName ? 'focus:border-red-500' : 'focus:ring-blue focus:border-blue-500'
                    } sm:text-sm text-black`}
                />
                {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>}
            </div>
            <div className="mb-4">
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-300">Last Name</label>
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border-2 ${
                        errors.lastName ? 'border-red-500' : 'border-gray-600'
                    } rounded-md shadow-sm focus:outline-none ${
                        errors.lastName ? 'focus:border-red-500' : 'focus:ring-blue focus:border-blue-500'
                    } sm:text-sm text-black`}
                />
                {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>}
            </div>
            <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border-2 ${
                        errors.email ? 'border-red-500' : 'border-gray-600'
                    } rounded-md shadow-sm focus:outline-none ${
                        errors.email ? 'focus:border-red-500' : 'focus:ring-blue focus:border-blue-500'
                    } sm:text-sm text-black`}
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>
            <div className="mb-4">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-300">Phone Number</label>
                <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border-2 ${
                        errors.phone ? 'border-red-500' : 'border-gray-600'
                    } rounded-md shadow-sm focus:outline-none ${
                        errors.phone ? 'focus:border-red-500' : 'focus:ring-blue focus:border-blue-500'
                    } sm:text-sm text-black`}
                />
                {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
            </div>
            <div className={`flex ${(prevStep && !isSubmitting  && !paymentUrl) ? 'justify-between' : 'justify-center'} mt-6`}>
                {prevStep && !paymentUrl && !isSubmitting && (
                    <button
                        type="button"
                        onClick={prevStep}
                        className='px-7 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 bg-orange hover:bg-orange-700 text-white'
                    >
                        Back
                    </button>
                )}
                {isSuccess ? (
                    <a
                        href={paymentUrl}
                        className="px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 bg-orange hover:bg-orange-700 text-white"
                    >
                        Go to Secure Payment Portal
                    </a>
                ) : (
                    <button
                        type="submit"
                        className={`px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                            isSubmitting ? 'bg-orange-transparent cursor-not-allowed' : 'bg-orange hover:bg-orange-700'
                        } text-white`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <div className="flex items-center justify-center px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 bg-orange hover:bg-orange-700 text-white">
                                <svg
                                    className="animate-spin h-5 w-5 mr-3"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 2.22.904 4.221 2.367 5.657l1.633-1.366z"
                                    ></path>
                                </svg>
                                Processing...
                            </div>
                        ) : (
                            'Confirm'
                        )}
                    </button>
                )}
            </div>
        </form>
    );
};

export default BuyPremiumForm;
