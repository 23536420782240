import React, { useState, useEffect, useRef } from 'react';
import GmailAuth from './GmailAuth';
import { useAuth } from '../../contexts/AuthProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { TextField, Autocomplete, Chip, Popper, Paper } from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import styled from '@emotion/styled';

const clientId = process.env.REACT_APP_GMAIL_CLIENT_ID;

const colleges = [
  { label: 'Harvard University' },
  { label: 'Stanford University' },
  { label: 'MIT' },
  { label: 'Yale University' },
  { label: 'Princeton University' },
  { label: 'Columbia University' },
  { label: 'University of Chicago' },
  { label: 'University of Pennsylvania' },
  { label: 'California Institute of Technology' },
  { label: 'Johns Hopkins University' },
  { label: 'Duke University' },
  { label: 'Northwestern University' },
  { label: 'Brown University' },
  { label: 'Vanderbilt University' },
  { label: 'Rice University' },
  { label: 'Washington University in St. Louis' },
  { label: 'Cornell University' },
  { label: 'University of Notre Dame' },
  { label: 'University of California, Berkeley' },
  { label: 'Emory University' },
  { label: 'Georgetown University' },
  { label: 'University of Michigan, Ann Arbor' },
  { label: 'University of Virginia' },
  { label: 'University of California, Los Angeles' },
  { label: 'University of North Carolina, Chapel Hill' },
  { label: 'Tufts University' },
  { label: 'University of Southern California' },
  { label: 'Wake Forest University' },
  { label: 'University of California, Santa Barbara' },
  { label: 'University of Florida' },
  { label: 'University of Miami' },
  { label: 'University of Texas, Austin' },
  { label: 'Boston College' },
  { label: 'Georgia Institute of Technology' },
  { label: 'New York University' },
  { label: 'University of California, Irvine' },
  { label: 'University of Washington' },
  { label: 'University of Wisconsin, Madison' },
  { label: 'Brandeis University' },
  { label: 'Case Western Reserve University' },
  { label: 'College of William and Mary' },
  { label: 'George Washington University' },
  { label: 'Northeastern University' },
  { label: 'Pepperdine University' },
  { label: 'Santa Clara University' },
  { label: 'Tulane University' },
  { label: 'University of California, Davis' },
  { label: 'University of California, San Diego' },
  { label: 'University of Illinois, Urbana-Champaign' },
  { label: 'University of Maryland, College Park' },
  { label: 'University of Massachusetts, Amherst' },
  { label: 'University of Pittsburgh' },
];

const StyledTextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: black;
  background: white;
  border: 1px solid #434D5B;
  box-shadow: 0px 2px 2px #1C2025;

  &:hover {
    border-color: '#3399FF';
  }

  &:focus {
    border-color: '#3399FF';
    box-shadow: 0 0 0 3px ${'#b6daff'};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

// Custom Popper to ensure proper alignment
const StyledPopper = styled(Popper)({
  '.MuiAutocomplete-paper': {
    margin: '4px 0',
  },
});

const EmailForm = () => {
  const [emailIsAuthenticated, setEmailIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [recipients, setRecipients] = useState([]);
  const { token } = useAuth();
  const chipContainerRef = useRef(null);
  const [chipRows, setChipRows] = useState(1);

  useEffect(() => {
    if (chipContainerRef.current) {
      const chipContainerHeight = chipContainerRef.current.clientHeight;
      const rowHeight = 32; // approximate height of one row of chips
      setChipRows(Math.ceil(chipContainerHeight / rowHeight));
    }
  }, [recipients]);

  const handleSendEmail = async () => {
    try {
      const response = await fetch('/api/email/user-send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          subject,
          body,
          recipients: "colehoward98@gmail.com, colehowa@usc.edu",
          accessToken,
        }),
      });
      if (!response.ok) {
        throw new Error('Error sending email: ', response.statusText);
      }
      const result = await response.json();
      console.log(result);
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteRecipient = (recipientToDelete) => {
    setRecipients((prevRecipients) =>
      prevRecipients.filter((recipient) => recipient.label !== recipientToDelete.label)
    );
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="max-w-lg mx-auto mt-10">
        {!emailIsAuthenticated && (
          <GmailAuth
            emailIsAuthenticated={emailIsAuthenticated}
            setEmailIsAuthenticated={setEmailIsAuthenticated}
            setAccessToken={setAccessToken}
            setUserEmail={setUserEmail}
          />
        )}
        {emailIsAuthenticated && (
          <div className="mt-6">
            <TextField
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              className="mb-4"
            />
            <TextField
              label="Email Body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              multiline
              rows={4}
              fullWidth
              margin="normal"
              variant="outlined"
              className="mb-4"
            />
            <Autocomplete
              multiple
              options={colleges}
              getOptionLabel={(option) => option.label}
              value={recipients}
              onChange={(event, newValue) => {
                setRecipients(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.label}
                    {...getTagProps({ index })}
                    style={{ margin: '4px 2px' }}
                    onDelete={() => handleDeleteRecipient(option)}
                  />
                ))
              }
              renderInput={(params) => (
                <div ref={params.InputProps.ref} style={{ position: 'relative' }}>
                  <div
                    ref={chipContainerRef}
                    style={{ position: 'absolute', width: '100%', top: 0, left: 0, display: 'flex', flexWrap: 'wrap', gap: '4px', padding: "5px" }}
                  >
                    {recipients.map((recipient, index) => (
                      <Chip
                        key={recipient.label}
                        label={recipient.label}
                        style={{ margin: '2px' }}
                        onDelete={() => handleDeleteRecipient(recipient)}
                      />
                    ))}
                  </div>
                  <StyledTextareaAutosize
                    {...params.inputProps}
                    minRows={1}
                    maxRows={10}
                    placeholder="Select colleges"
                    className="mb-4"
                    style={{ paddingTop: `${chipRows * 32 + 8}px` }} // Adjust paddingTop based on the number of rows of chips
                  />
                </div>
              )}
              PaperComponent={({ children }) => (
                <Paper style={{ margin: '4px 0' }}>{children}</Paper>
              )}
              PopperComponent={StyledPopper}
              className="mb-4"
            />
            <button
              onClick={handleSendEmail}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
            >
              Send Email
            </button>
          </div>
        )}
      </div>
    </GoogleOAuthProvider>
  );
};

export default EmailForm;