import React from 'react'
import { Link } from 'react-router-dom'
import CollegeConnectImage from '../images/college-connect.png'
import ProspectVideo from '../videos/prospect-video.mp4'
function TorqAI() {
  return (
        <div className=" bg-black text-white text-center text-2xl flex flex-col items-center gap-12 px-4 sm:px-32 py-24">
            
            <div className="flex flex-col gap-8 items-start">
                <h1 className="text-5xl md:text-7xl font-bold">The TORQ Approach</h1>
                <div className="border w-full"/>
            </div>
            <div className="flex flex-col gap-8">
                <p className="text-wrap text-2xl font-thin text-start">TORQ’s seamless integration of technology and sports ensures that your hard work is noticed by those who matter—family, fans, and recruiters alike</p>
                <p className="text-wrap text-2xl font-thin text-start">The <span className="text-blue font-normal">College Connect Filter</span> is TORQ's proprietary tool developed to help YOU make the most informed recruiting decisions possible. Simply enter your academic information along with personal and financial preferences and within seconds, a list of personalized schools will appear. Continue adjusting filters to curate your targeted schools. Then, simply click <Link to="/highlights" className="text-orange font-normal hover:underline">get started</Link> to begin contacting the desired schools.</p>
            </div>
            <img src={CollegeConnectImage} alt="College Connect"/>

            <div className="flex flex-col gap-8 ">
                <p className="text-wrap text-start text-2xl font-thin">The <span className="text-blue font-normal">Highlight</span> is TORQ's software developed to make YOU the stand out in a sea of recruits. If you are using the tool as a guest, simply fill out the form with your information, or if you already have an account we will import the information directly from your account (check to make sure it is up to date). Next, upload your clip(s). Within seconds, your finished highlight reel is complete! The video will be available on your profile–ready for download and will be posted directly on TORQ's social media channels. Feel free to post on your own personal accounts as well.</p>
            </div>
            <video 
                autoPlay
                loop
                muted
                no-controls
                className="object-fit w-full md:w-2/3 border-solid border-4 border-orange"
            >
                <source src={ProspectVideo}/>
            </video>
        </div>
  )
}

export default TorqAI
