import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthProvider.jsx';
import { useNavigate } from 'react-router-dom';

const AccountInformationForm = () => {
  const { user, userId, token} = useAuth();
  const navigate = useNavigate();
  
  // Set formData to current user data but change if the user updates the form
  const [formData, setFormData] = useState({
    fullname: user.fullname,
    email: user.email,
    phone: user.phone,
    username: user.username,
    // Not sure if this is correct.
    id: userId,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form Data:', formData);

    const res = await fetch('/api/edit-profile/account-information', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({data: formData}),
    });

    if(res.ok) {
        // Take user back to the profile page
        navigate(`/profile/${userId}`);
    } else {
        console.log("Failed to update account information");
    }
  };

  return (
    <div className="mx-auto py-4 text-black text-nowrap">
      <form onSubmit={handleSubmit} className="flex flex-col gap-8">
        {/* General Section */}
        <h2 className=" text-blue text-3xl font-bold">General</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">

        <label htmlFor="fullname" className="text-white mt-2 md:mt-0">
            Full Name:
        </label>
        <input
            type="text"
            id="fullname"
            name="fullname"
            value={user.fullname}
            onChange={handleChange}
            className="w-full rounded-md border border-blue pl-2 focus:outline-none focus:ring-1 focus:ring-white"
        />

        <label htmlFor="username" className="text-white mt-2 md:mt-0">
            Username:
        </label>
        <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="w-full rounded-md border border-blue pl-2 focus:outline-none focus:ring-1 focus:ring-white"
        />

        </div>
        
        <button type="submit" className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50">
          Save
        </button>
      </form>
    </div>
  );
};

export default AccountInformationForm;