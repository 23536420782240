import React,{ useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import ProfileDataContext from '../config/profileDataContext';
import DefaultImage from '../../images/pfp.png';
import UploadProfilePhoto from '../popups/ProfileImagePopup';
import Loading from '../utilities/Loading';
import { useAuth } from '../../contexts/AuthProvider';
import PublicVideoFeed from './PublicVideoFeed';



const PlayerInformation = () => {
    const { authentication, token, loading } = useAuth();
    let [user, setUser] = useState(null);
    const [profilePicture, setProfilePicture] = useState(sessionStorage.getItem('profilePicture') || null);
    const navigate = useNavigate();

    //   useEffect(() => {
    //     const getProfilePicture = async () => {
    //       if (sessionStorage.getItem('profilePicture')) {
    //         console.log('got profile picture from session storage')
    //         setProfilePicture(sessionStorage.getItem('profilePicture'));
    //         console.log('profile picture:', profilePicture);
    //         return;
    //       }
    //       try {
    //         console.log("Getting profile picture...");

    //         const response = await fetch('/api/upload/profile-picture/', {
    //           method: 'GET',
    //           headers: {
    //             'Authorization': `Bearer ${token}`,
    //           },
    //         });
    //         if(response.ok) {
    //           var { url } = await response.json();
    //           if (!url || url === "undefined") {
    //             throw new Error("No profile picture found");
    //           }
    //           console.log('url:', url);
    //           setProfilePicture(url);
    //           sessionStorage.setItem('profilePicture', url);
    //         } else {
    //           throw new Error(response.statusText);
    //         }
    //       } catch (error) {
    //         console.error("Error fetching profile picture:", error);
    //       }
    //     }
    //     getProfilePicture();
    // }, [token])
    useEffect(() => {
        const userId = window.location.pathname.split('/').pop();
        const getUserInfo = async () => {
          try {
            const response = await axios.get(`/api/user/get/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if(response.status === 200) {
                const data = await response.data;
                data['userId'] = userId;
                setUser(data);
            }
         }catch (error) {
            console.error('Error fetching user:', error);
          }
        }
        getUserInfo();
    }, []);
    
    if (!authentication) {
      navigate('/');
      return null; // Redirect to login if not authenticated
    }
  
  
    if (loading) {
      return <Loading isLoading={loading}/>; // Show loading spinner or message while data is fetching
    }
  
    return (
        <>
          <div className="flex justify-center items-center w-full min-h-screen bg-gray-100 bg-black pt-20">
            <div className="flex flex-col justify-between gap-4 p-4 sm:p-0 bg-white rounded w-full max-w-7xl">
              <div className="flex flex-col lg:flex-row justify-between gap-4 p-4 rounded-lg">
                <div className="flex flex-col items-center gap-1 w-full lg:w-1/3 h-[456px] p-4 rounded-lg">
                  {profilePicture ? (
                    <img
                      src={profilePicture}
                      alt="Profile"
                      className="object-fill aspect-4/3 border-solid border-4 border-orange w-[350px] h-full"
                    />
                  ) : (
                    <img
                      src={DefaultImage}
                      alt="Default Profile"
                      className="object-fill aspect-4/3 border-solid border-4 border-orange w-[350px] h-full"
                    />
                  )}
                </div>
                <div className="flex flex-col justify-center items-center gap-4 w-full lg:w-1/3 h-[456px] p-4 rounded-lg">
                  <div className="flex flex-col justify-center items-center gap-1 text-2xl text-center">
                    <h1 className="text-5xl sm:text-6xl font-bold font-a4">{user?.fullname}</h1>
                    <h2 className="text-gray-400">@{user?.username}</h2>
                    <h2 className="bg-black w-fit rounded-lg px-4 text-orange font-semibold">
                      {user?.currentSchool ? user.currentSchool : "School not listed"}
                    </h2>
                  </div>
                  <div className="flex flex-col justify-center items-center gap-4 text-2xl">
                    <span className="font-semibold text-nowrap">
                      {user?.fromCity ? `${user.fromCity}, ${user.fromState}` : "-"}
                    </span>
                    <span className="italic">
                      Graduating class of <span className="font-bold">{user?.gradYear ? user.gradYear : "-"}</span>
                    </span>
                    <span className="font-semibold text-nowrap">
                      {user?.position || user?.secondaryPosition ? `${user.position}, ${user.secondaryPosition}` : "-"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row justify-around lg:mx-0 w-full lg:w-1/3 text-white text-md mx-auto h-[456px] p-8 rounded-lg">
                  <div className="grid grid-flow-row-dense grid-cols-3 font-sm border-solid border-4 border-orange h-full">
                    <div className="flex flex-col bg-black col-span-2 sm:col-span-1 text-center p-4">
                      <span className="font-light text-xs text-blue">Height</span>
                      <span className="text-3xl sm:text-3xl">{user?.height ? user.height : "-"}</span>
                    </div>
                    <div className="flex flex-col bg-black col-span-2 sm:col-span-1 text-center p-4">
                      <span className="font-light text-xs text-blue">Weight</span>
                      <span className="text-3xl sm:text-3xl gap-1">
                        {user?.weight ? user.weight : "-"}
                        <span className="text-sm"></span>
                      </span>
                    </div>
                    <div className="flex flex-col bg-black col-span-4 sm:col-span-2 text-center p-4">
                      <span className="font-light text-xs text-blue">Handedness</span>
                      <span className="text-3xl sm:text-3xl">{user?.handedness ? user.handedness : "-"}</span>
                    </div>
                    <div className="flex flex-col bg-black col-span-4 text-center p-4">
                      <span className="font-light text-xs text-blue">Commitment</span>
                      <span className="text-3xl sm:text-3xl">{user?.commitment ? user.commitment : "Uncommitted"}</span>
                    </div>
                    {user?.travelClub && (
                      <div className="flex flex-col bg-black col-span-4 text-center p-4">
                        <span className="font-light text-xs text-blue">Travel Club</span>
                        <span className="text-3xl sm:text-3xl">{user.travelClub}</span>
                      </div>
                    )}
                   
                  </div>
                </div>
              </div>
              <PublicVideoFeed userId={user?.userId} />
            </div>
          </div>
        </>
      );
      
      
      
      
}
      
  
  export default PlayerInformation;