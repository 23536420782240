import React from 'react'
import { Link } from 'react-router-dom'
import TorqLogo from "../../images/icons/Torq-Logo-TightCrop.png"

function Upgrade() {
  return (
    <div className="bg-white text-orange flex flex-col items-center gap-8 py-8 text-center shadow-inner-black">
        <img src={TorqLogo} alt="TORQ Logo" className="w-32"/>
        <span className="text-3xl font-semibold">Upgrade to get access to personalized highlights</span>
        <Link to="/memberships" className="bg-white text-orange text-xl border-solid border-2 border-orange px-4 py-2 rounded-full hover:text-white hover:bg-orange duration-300 ease-in-out">Upgrade</Link>
    </div>
  )
}

export default Upgrade