import React from 'react'

function ComingSoon() {
  return (
    
    <div className='bg-black text-white text-3xl text-center h-screen flex flex-col justify-center items-center gap-4'>
        <h1 className="text-5xl text-orange">Coming Soon</h1>
        <p>This page is not completely ready yet. We will email you with updates on our newest features!</p>
    </div>
  )
}

export default ComingSoon