import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthProvider';
import AccountInformationForm from './AccountInformationForm';
import InformationItem from './InformationItem';
// Component for rendering individual information item with an edit button

function AccountInformation() {
  const { authentication, user, userId } = useAuth();
  const profile = JSON.parse(user);

  const [editMode, setEditMode] = useState(false);

  // Function to handle edit mode toggle
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <div className="">
      <h1 className="text-5xl font-bold text-white">Account Information</h1>
      <div className="flex gap-4 py-4">
        <button onClick={toggleEditMode} className="w-fit border-solid border-2 border-blue py-1 px-4 rounded-lg hover:bg-sky-800 duration-200 ease-in">
          {editMode ? 'View Account Information' : 'Edit Account Settings'}
        </button>
      </div>
      
      {(editMode && authentication) ? (
        <AccountInformationForm/>
      ) : (
        <div className="flex flex-col gap-4 font-bold">
          <InformationItem label="User ID" value={userId} />
          <InformationItem label="Full Name" value={profile?.fullname || ""} />
          <InformationItem label="Username" value={profile?.username || ""} />
        </div>
      )}
    </div>
  );
}

export default AccountInformation;
