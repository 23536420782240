import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const LogOutButton = () => {
  const { logOut } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const handleSignOut = async () => {
    logOut()
    navigate('/');
    };

  return (
    <button onClick={handleSignOut} className="flex flex-row-reverse items-center lg:flex-col gap-1 hover:text-orange duration-200 ease-in">
      <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
      <span className="text-sm">Sign Out</span>
  </button>
  );
};

export default LogOutButton;