import React, {useState} from 'react'
import PlayerProfileForm from './PlayerProfileForm'
import { useAuth } from '../../contexts/AuthProvider';
// Render athletic information section
function renderPublicInformation(profile) {
  return (
    <>
      <span>City: <span className="font-normal">{profile?.fromCity || ""}</span></span>
      <span>State: <span className="font-normal">{profile?.fromState || ""}</span></span>
      <span>Graduation Year: <span className="font-normal">{profile?.gradYear || ""}</span></span>
      <span>Current School: <span className="font-normal">{profile?.currentSchool || ""}</span></span>
      <span>Sport: <span className="font-normal">{profile?.sport || ""}</span></span>
      <span>Position: <span className="font-normal">{profile?.position || ""}</span></span>
      <span>Secondary Position: <span className="font-normal">{profile?.secondaryPosition || ""}</span></span>
      <span>Height: <span className="font-normal">{profile?.height || ""}</span></span>
      <span>Weight: <span className="font-normal">{profile?.weight || ""}</span></span>
      <span>Handedness: <span className="font-normal">{profile?.handedness || ""}</span></span>
      <span>GPA: <span className="font-normal">{profile?.gpa || ""}</span></span>

    </>
  );
}

function PlayerProfile() {
  const { user } = useAuth();
  const profile = JSON.parse(user);
  const [editFlag, setEditFlag] = useState(true);
  const handleClick = () => setEditFlag(!editFlag)

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-5xl font-bold">Player Profile</h1>
      <div>
        <button onClick={handleClick} className="w-fit bg-gray-700 border-solid border-2 border-blue py-1 px-4 rounded-lg hover:bg-sky-800 duration-200 ease-in">
          Edit Player Settings
        </button>
      </div>
      <p className="text-lg text-blue">Edit your player profile to share with coaches, recruiters and other athletes.</p>
      {editFlag ? 
        <div className="flex flex-col gap-4 font-bold">
        {renderPublicInformation(profile)}
        </div>
       : <PlayerProfileForm />}
    </div>
  )
}

export default PlayerProfile