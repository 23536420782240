import React, { useState }  from 'react';
import { useAuth } from '../../contexts/AuthProvider'; // Import your AuthContext
import { useNavigate, Link } from 'react-router-dom';
import Loading from '../utilities/Loading';

const MobileEmailSignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const { loginUser } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const email = e.target.email.value;
        const password = e.target.password.value;
        loginUser(email, password)
        e.target.reset();
        navigate("/")
    };

    return <>
    <div className='w-full bg-black bg-opacity-70 p-8 rounded-md'>
        <div className='flex flex-col items-center justify-center lg:gap-4'>
                <span 
                className="text-5xl font-a4 text-orange"
                >
                Login
                </span>
                <span className="font-semibold lg:mb-2">Sign in to continue</span>  
        </div>
        <form onSubmit={handleSubmit} className="text-2xl flex flex-col gap-3 lg:gap-4 ">
                <section className="flex flex-col gap-1">
                    <label htmlFor="email" className='font-a4 ml-2 lg:text-orange'>Email</label>
                    <input
                        type="email"
                        id="email"
                        className="bg-orange bg-opacity-70 rounded-xl p-2 text-black lg:bg-white border-solid border lg:border-orange"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </section>
                <section className="flex flex-col gap-1">
                    <label htmlFor="password" className='font-a4 ml-2 lg:text-orange'>Password</label>
                    <input
                        type="password"
                        id="password"
                        className="bg-orange bg-opacity-70 rounded-xl p-2 text-black lg:bg-white border-solid border lg:border-orange"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </section>
                {error && <p className="text-red-400 text-xl content-center">{error}</p>}
                <div className="flex flex-col gap-2 lg:gap-4 mt-2">
                    <button type="submit" className="bg-orange font-a4 rounded-lg p-1 lg:p-2 text-white lg:text-white">Login</button>
                    <Link to="/forgot-password" className=" font-a4 text-orange text-base mx-auto">Forgot your password?</Link>
                    <button type="button" className="bg-blue font-a4 rounded-lg p-1 lg:p-2 text-white" onClick={() => navigate("/signup")}>Sign Up</button>
                </div>
            </form>
        </div>
    </>
};

export default MobileEmailSignIn;