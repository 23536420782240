import React from 'react'
import { Link } from 'react-router-dom'

function NationalClassic() {
  return (
    <div className="bg-orange text-white text-center p-4 flex flex-col items-center py-4 text-lg gap-2">
        <h1 className="text-4xl font-bold">Watch the National Classic</h1>
        <p>All game footage will be posted on TORQ Sports Youtube </p>
        <p>Baseball Icon</p>
        <Link to="https://www.youtube.com/@torqsports" className="text-xl text-nowrap font-bold px-12 rounded-full border-solid border border-white">Youtube</Link>
    </div>
  )
}

export default NationalClassic