import React from 'react'
import MultiStepSignUp from '../components/signup/MultiStepSignUp'

function Signup() {
  return (
        <div className="bg-pitch-black text-black text-xl md:mx-auto flex flex-col items-center font-semibold"> 
            <MultiStepSignUp />
        </div>
  )
}

export default Signup
