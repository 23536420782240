import React, {useState} from 'react';
import BuyPremiumSubscriptionForm from '../payment/BuyPremiumForm';
import { useAuth } from '../../contexts/AuthProvider';

function PaymentStep({ prevStep, formData }) {

  return (
    <div className="bg-black min-h-screen flex flex-col items-center pt-10 text-white">
      <div className="flex flex-col bg-gray-800 p-8 rounded-lg shadow-orange mb-8">
        <h1 className='text-3xl md:text-4xl lg:text-5xl'>Create an Account</h1>
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">Step 3: Payment Information</h2>
        <div className="border-solid border-4 border-black"></div>

        <BuyPremiumSubscriptionForm prevStep={prevStep} signupData={formData} />

      </div>
    </div>
  );
}

export default PaymentStep;
