import { useState } from 'react';
import InitialStep from './InitialStep';
import PaymentStep from './PaymentStep';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthProvider';

const MultiStepSignUp = () => {
  const { createUser } = useAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "", // Combine first and last name
    lastName: "",
    phone: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    terms: "",
    affiliation: "",
    sport: "",
    accountType: "",
    terms: "",
    nameOnCard: "",
    creditCardNumber: "",
    expiryDate: "",
    cvv: "",
    gender: "",
    tokens: 0,
    teamCode: "",
  });

  // Function to format phone number with dashes
  const formatPhoneNumber = (value) => {
    value = value.replace(/\D/g, ''); // Remove all non-digit characters

    if (value.length > 6) {
      return value.replace(/^(\d{3})(\d{3})(\d{0,4})$/, '$1-$2-$3').trim();
    } else if (value.length > 3) {
      return value.replace(/^(\d{3})(\d{0,3})$/, '$1-$2').trim();
    } else {
      return value.replace(/^(\d{0,3})$/, '$1').trim();
    }
  };

  // Set the form data whenever it is changed.
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'phone') {
      const formattedPhone = formatPhoneNumber(value);
      setFormData((prevState) => ({ ...prevState, [name]: formattedPhone }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  // Take the form data and update the user's profile in the database.
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    const {password, confirmPassword} = formData;
    console.log("Form Data: ", formData)

    if(password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    // Needs to return a user ID
    createUser(formData);
    navigate("/login");
  };  
  
    
  switch(step) {
    case 1:
      return (
        <InitialStep formData={formData} setFormData={setFormData} handleChange={handleChange} handleSubmit={handleSubmit} nextStep={nextStep}/>
      );
    case 2:
      return (
        <PaymentStep formData={formData} handleChange={handleChange} prevStep={prevStep} handleSubmit={handleSubmit} />
      )
    default:
      return (
        <InitialStep formData={formData} handleChange={handleChange} nextStep={nextStep}/>
      );
  }

};

export default MultiStepSignUp;
