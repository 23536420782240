import React from 'react'

function PageWrapper({children}) {
  return (
    <div className=''>
        {/* Page content goes here */}
        {children}
    </div>
  )
}

export default PageWrapper