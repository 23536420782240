import React from 'react'
import { useMultistepContext } from '../contexts/MultistepProvider'

function BuyTokens() {
  const { selectedPackage } = useMultistepContext()

  if(selectedPackage === null) {
    return (
        <div>No package selected.</div>
    )
  }

  return (
    <div className="text-9xl">Buy Tokens</div>
  )
}

export default BuyTokens