import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthProvider';


const retrieveFullToken = async (tokenHash) => {
    try {
        const response = await axios.post('/api/payment/retrieve-token', { tokenHash });
        if (response.data.success) {
            return response.data.token;
        } else {
            console.error('Error retrieving token:', response.data.error);
            return null;
        }
    } catch (error) {
        console.error('Error retrieving token:', error);
        return null;
    }
};

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const { userId, token, fetchUserData, logOut, setAuthentication } = useAuth();
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('orderId');
    const tokenArg = searchParams.get('tokenArg') ? decodeURIComponent(searchParams.get('tokenArg')) : null;
    const userIdArg = searchParams.get('userIdArg') ? decodeURIComponent(searchParams.get('userIdArg')) : null;
    const [isInitialized, setIsInitialized] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const validateTokenAndUserId = async () => {
            console.log('Validating token and userId...');
            console.log('tokenArg:', tokenArg, 'Length:', tokenArg ? tokenArg.length : 'N/A');
            console.log('userIdArg:', userIdArg);
            console.log('Current token:', token, 'Length:', token ? token.length : 'N/A');
            console.log('Current userId:', userId);

            if (!tokenArg || !userIdArg || !userId || !token) {
                return false;
            }

            const fullToken = await retrieveFullToken(tokenArg);

            const isTokenValid = fullToken === token;
            const isUserIdValid = userIdArg === userId;

            console.log('isTokenValid:', isTokenValid);
            console.log('isUserIdValid:', isUserIdValid);
            console.log('url:', window.location.href);

            if (isTokenValid && isUserIdValid) {
                setAuthentication(true);
                return true;
            } else {
                console.log('Token or userId mismatch. Logging out...');
                logOut();
                navigate('/login');
                return false;
            }
        };

        const confirmPayment = async () => {
            console.log('Confirming payment...');
            console.log('orderId:', orderId);
            console.log('token:', token);

            if (orderId && token) {
                try {
                    // Confirm payment with the backend
                    const response = await axios.post('/api/payment/confirm', { orderId, tempToken: tokenArg }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (response.status === 200) {
                        // Update user's payment history
                        await axios.post('/api/payment/update-user', { uid: userId }, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });

                        await fetchUserData(); // Refetch user data to update role
                    } else {
                        console.error('Payment confirmation failed:', response.data);
                    }
                } catch (error) {
                    console.error('Error confirming payment:', error);
                }
            } else {
                console.log('url:', window.location.href);
                console.log('url args:', searchParams.toString());
                console.error('Order ID or token is missing');
            }
            navigate('/');
        };

        const initialize = async () => {
            const authResult = await validateTokenAndUserId();
            setIsAuthenticated(authResult);
            if (authResult) {
                confirmPayment();
            }
            setIsInitialized(true);
        };

        if (!isInitialized && !isAuthenticated) {
            initialize();
        }
    }, [userId, token, searchParams, navigate, fetchUserData, logOut, setAuthentication, orderId, tokenArg, userIdArg, isInitialized, isAuthenticated]);

    return (
        <>{isAuthenticated && isInitialized ? (
            <>
                <h1>Payment Successful!</h1>
                <p>Redirecting you to the homepage...</p>
            </>
        ) : (
            <p>Confirming payment...</p>
        )}</>
    );
};

export default PaymentSuccess;