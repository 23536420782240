import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faPhotoVideo, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useMultistepContext } from '../../contexts/MultistepProvider';

function Card({ title, price, description, buttonText, icon, handleButtonClick, isFileInput, handleFileChange }) {
  return (
      <div className="flex flex-col gap-2 justify-start items-start border-solid border-2 border-blue p-2 w-[90%] md:w-[40%] rounded-md m-1">
          <span className="text-2xl font-bold">{title}</span>
          <span className="text-xl text-orange">{price}</span>
          {isFileInput ? (
              <label className="w-full hover:bg-sky-800 duration-300 ease-in text-white font-bold py-1 px-2 rounded cursor-pointer text-center border-solid border-2">
                  <FontAwesomeIcon icon={icon} size="lg" className="mr-1" />
                  {buttonText}
                  <input
                      type="file"
                      onChange={handleFileChange}
                      accept=".mp4,.mov"
                      className="hidden"
                  />
              </label>
          ) : (
              <button
                  className="w-full hover:bg-sky-800 duration-200 ease-in text-white font-bold py-1 px-2 rounded cursor-pointer text-center border-solid border-2"
                  onClick={handleButtonClick}
              >
                  <FontAwesomeIcon icon={icon} size="lg" className="mr-1" />
                  {buttonText}
              </button>
          )}
          <span className="text-sm h-[5ch]">{description}</span>
      </div>
  );
}

function VideoUpload() {
  const { handleFileChange, handleCreateVideoClick, selectedFiles, errorMessage, successMessage, setStep } = useMultistepContext();
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    if (successMessage) {
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000); // Hide after 3 seconds
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 3000); // Hide after 3 seconds
    }
  }, [errorMessage]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileChange(event);
      setUploadedFileName(file.name);
    }
  };

  const cancelUpload = () => {
    setUploadedFileName('');
  };

  const toCollegeConnect = () => {
    setStep(5);
  };

  return (
    <div className="bg-[#000000] flex flex-col gap-1 justify-start items-start text-white p-2">
        <h2 className="text-3xl text-bold text-blue text-center mb-2">Choose your highlight option</h2>
        <div className="flex flex-col md:flex-row gap-1 items-start">
            <Card
                title="Upload Your Own"
                price="FREE"
                description="Upload a video up to 60 seconds long for FREE"
                buttonText="Upload Video"
                icon={faFileUpload}
                isFileInput={true}
                handleFileChange={handleFileUpload}
            />
            <Card
                title="Create a MyLight"
                price="+ $19.95"
                description="Get your own personal video through our automation software for an extra $19.95"
                buttonText="Create"
                icon={faPhotoVideo}
                handleButtonClick={handleCreateVideoClick}
            />
        </div>

        {uploadedFileName && (
            <div className="flex items-center gap-2 ml-1">
                <span className="text-sm">{uploadedFileName}</span>
                <button onClick={cancelUpload} className="text-red-500 hover:text-red-700">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
        )}

        {showSuccessMessage && (
            <span className="bg-green-200 ml-1 px-2 py-1 text-black rounded-md text-center text-sm">
                {successMessage}
                <FontAwesomeIcon icon={faCheck} className="ml-1" />
            </span>
        )}

        {showErrorMessage && (
            <span className="bg-red-200 ml-1 px-2 py-1 text-black rounded-md text-center text-sm">
                {errorMessage}
            </span>
        )}
    </div>
  );
}

export default VideoUpload;
