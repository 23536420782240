import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const debug = +process.env.REACT_APP_DEBUG;


// used to clear player search state from session storage
const useNavigationListener = () => {
  const location = useLocation();

  useEffect(() => {
    const handleNavigation = () => {
      if (!location.pathname.startsWith('/search-players')) {
        if (debug) console.log('Clearing search state...');
        sessionStorage.removeItem('searchFilters');
        sessionStorage.removeItem('searchResults');
      }
    };

    handleNavigation();
  }, [location.pathname]);
};

 
const NavigationListner = ({ children }) => {
    useNavigationListener();
    return (<div>{children}</div>)
  };


export default NavigationListner;

