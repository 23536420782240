import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeUser, faUserAstronaut, faMoneyBillWave, faLock } from '@fortawesome/free-solid-svg-icons';
const EditSettingsNav = ({ onSelectSetting, currentSetting }) => {
  const [selectedSetting, setSelectedSetting] = useState(currentSetting); // Initial selection

  // Function to update the selected setting in the UI.
  const handleLinkClick = (setting) => {
    setSelectedSetting(setting);
    onSelectSetting(setting); // Call parent function to update content
  };

  return (
    <nav className="w-full md:w-fit bg-black text-white text-lg justify-between p-4 text-nowrap border-solid sm:border-r-2 border-gray-700">
      <ul className="flex flex-col gap-4">
        <li onClick={() => handleLinkClick('account-information')} className={`px-3 py-2 rounded-md hover:bg-sky-800 cursor-pointer duration-200 ease-in ${selectedSetting === 'account-information' ? 'bg-sky-800 font-bold' : ''}`}>
          <FontAwesomeIcon icon={faHomeUser} className="mr-4" size="xl" />
          <span 
             >
            Account Information
          </span>
        </li>
        <li onClick={() => handleLinkClick('player-profile')} className={`px-3 py-2 rounded-md hover:bg-sky-800 cursor-pointer duration-200 ease-in ${selectedSetting === 'player-profile' ? 'bg-sky-800 font-bold' : ''}`}>
          <FontAwesomeIcon icon={faUserAstronaut} className="mr-4" size="xl" />
          <span 
             >
            Edit Profile
          </span>
        </li>
        <li onClick={() => handleLinkClick('billing')} className={`px-3 py-2 rounded-md hover:bg-sky-800 cursor-pointer duration-200 ease-in ${selectedSetting === 'billing' ? 'bg-sky-800 font-bold' : ''}`}>
          <FontAwesomeIcon icon={faMoneyBillWave} className="mr-4" size="xl"/>
          <span>Billing</span>
        </li>
        <li onClick={() => handleLinkClick('security')} className={`px-3 py-2 rounded-md hover:bg-sky-800 cursor-pointer duration-200 ease-in ${selectedSetting === 'security' ? 'bg-sky-800 font-bold' : ''}`}>
          <FontAwesomeIcon icon={faLock} className="mr-4" size="xl" />
          <span 
             >
            Password and Security
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default EditSettingsNav;
