import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthProvider.jsx';
import CollegeConnectFilter from "./CollegeConnectFilter.jsx";
import CollegeConnectResults from './CollegeConnectResults.jsx';
import CollegeConnectSearch from './CollegeConnectSearch.jsx';
import EmailCounter from './EmailCounter.jsx';
import Loading from '../utilities/Loading.jsx';
import { useMultistepContext } from '../../contexts/MultistepProvider.jsx';

const debug = +process.env.REACT_APP_DEBUG;

const CollegeConnect = () => {
  const { token, user, tokens } = useAuth();
  const userGPA = user?.gpa;
  const { nextStep, selectedSport } = useMultistepContext();
  const [loading, setLoading] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [filteredColleges, setFilteredColleges] = useState(colleges);
  const [emailCount, setEmailCount] = useState(0);
  const [divisions, setDivisions] = 
    useState((selectedSport === "Football") 
    ? ["FBS", "FCS", "II", "III"] 
    : ["I", "II", "III"]);
  
  const [formData, setFormData] = useState({
    region: '',
    state: '',
    division: '',
    tuition: '',
    gpa: ''
  });
  
  var totalResults = filteredColleges.length;

  useEffect(() => {
    applyFilters();
  }, [formData])

  const handleSearchSubmit = (query) => {
    let data = colleges;
    const filtered = data.filter((college) => {
      console.log(college)
      return college.school_name.toLowerCase().includes(query.toLowerCase())}
    );
    setFilteredColleges(filtered);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const applyFilters = () => {
    let filtered = colleges;
    if (formData.region) {
      filtered = filtered.filter(college => college.Region === formData.region);
    }
    if (formData.state) {
      filtered = filtered.filter(college => college.State === formData.state);
    }
    if (formData.division) {
      filtered = filtered.filter(college => college.division === formData.division);
    }
    if (formData.tuition) {
      const [minTuition, maxTuition] = formData.tuition.split('-').map(Number);
      filtered = filtered.filter(college => college.MaxTuition >= minTuition && college.MaxTuition <= maxTuition);
    }
    if (formData.gpa) {
      const [minGPA, maxGPA] = formData.gpa.split('-').map(Number);
      filtered = filtered.filter(college => college.AverageGPA >= minGPA && college.AverageGPA <= maxGPA);
    }

    setFilteredColleges(filtered);
  };

  const reset = () => {
    setFilteredColleges(colleges);
    setFormData({
      region: '',
      state: '',
      division: '',
      tuition: '',
      gpa: ''
    });
  };

  useEffect(() => {
    const fetchColleges = async () => {
      setLoading(true);
      const sport = selectedSport.replace(/-/g, ' '); // Convert sportName with "-" back to spaces
      let apiUrl = `/api/college-connect/search/${sport}`;

      try {
        const res = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          method: 'GET'
        });

        if (res.ok) {
          const data = await res.json();
          setColleges(data);
          setFilteredColleges(data);
        } else {
          console.error("Failed to fetch filtered colleges");
        }
      } catch (error) {
        console.error("Error fetching filtered colleges:", error);
      }
      setLoading(false);
    };

    fetchColleges();
  }, []); // Fetch colleges when selectedSport or token changes

  const states = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
    "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
    "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
    "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota",
    "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada",
    "New Hampshire", "New Jersey", "New Mexico", "New York",
    "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon",
    "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
    "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington",
    "West Virginia", "Wisconsin", "Wyoming"
  ];

  const incrementEmailCount = () => {
    setEmailCount(prevCount => Math.min(prevCount + 1, 10));
  };

  const decrementEmailCount = () => {
    setEmailCount(prevCount => Math.max(prevCount - 1, 0));
  };

  return (loading ? (
        <Loading isLoading={loading} />
      ) : (
        <div className="bg-[#000000] text-white text-center text-2xl flex flex-col items-center gap-12 py-24 min-h-screen">
          <div className=" text-black text-nowrap space-y-4 py-[5%]">
            <h1 className="text-3xl md:text-5xl font-bold text-white font-a4 text-center text-wrap">College Connect</h1>
            <CollegeConnectSearch handleSearchSubmit={handleSearchSubmit} handleReset={reset} />
            <div className="flex flex-col md:flex-row justify-between gap-8 px-[10%]">
              <CollegeConnectFilter handleFilterChange={handleFilterChange} formData={formData} states={states} divisions={divisions} totalResults={totalResults} />
              <EmailCounter currentCount={emailCount} maxEmails={tokens} nextStep={nextStep} />
            </div>
            <div className="border-solid border-b-2 border-white mx-[10%]"></div>
            <CollegeConnectResults results={filteredColleges} incrementEmailCount={incrementEmailCount} decrementEmailCount={decrementEmailCount} />
          </div>
        </div>
      ));
};

export default CollegeConnect;