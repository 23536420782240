import React, { useState, useEffect } from 'react';
import EditSettingsNav from '../components/edit/EditSettingsNav';
import AccountInformation from '../components/edit/AccountInformation'; // Import your ProfileSettings component
import ComingSoon from '../components/utilities/ComingSoon'; // Import your ProfileSettings component
import PlayerProfile from '../components/edit/PlayerProfile';
import Billing from '../components/edit/Billing';
import Security from '../components/edit/Security';
import { useAuth } from '../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
// Import other setting components

const Edit = ({selected}) => {
  const [selectedSetting, setSelectedSetting] = useState(selected);
  const { authentication } = useAuth(); 
  const navigate = useNavigate();
  // Function used in the EditSettingsNav component to update the selected setting
  const handleSelectSetting = (setting) => {
    setSelectedSetting(setting);
  };

  // Get all information for the user. 
  // Pass the specific information to each setting component

  return (
    <>
      {authentication ? (
        <div className="flex flex-col md:flex-row border-solid border-b-2 sm:border-t-2  bg-black text-white min-h-screen px-[10%] py-[5%]">
          <EditSettingsNav onSelectSetting={handleSelectSetting} currentSetting={selectedSetting} />
          <div className="flex-grow px-[10%] py-[5%] text-lg">
            {selectedSetting === 'account-information' && <AccountInformation />}
            {selectedSetting === 'player-profile' && <PlayerProfile />}
            {selectedSetting === 'billing' && <Billing />}
            {selectedSetting === 'security' && <Security />}
            {selectedSetting === 'notifications' && <ComingSoon />}
          </div>
        </div>
      ) : (
        navigate("/login")
      )}
    </>
  );
};

export default Edit;