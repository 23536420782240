import LoginPopup from '../components/popups/LoginPopup';
import CommercialVideo from './../components/home/CommercialVideo';
import NationalClassic from './../components/home/NationalClassic';
import SportsEcosystem from './../components/home/SportsEcosystem';
import LivestreamInformation from './../components/home/LivestreamInformation';
import MembershipInformation from '../components/home/MembershipInformation';

const Home = () => {
    

    return (
        <>
        <main>
            <CommercialVideo />
            {/* <SportsEcosystem />
            <LivestreamInformation />
            <MembershipInformation /> */}
            {/* <LoginPopup /> */}
        </main>
      </>)
}

export default Home;