import React, { useState, useEffect, useMemo } from 'react';



const SelfRating = ({ selfRating, isEditing, handleSliderChange }) => {
  const parsedSelfRating = useMemo(() => {
    if (typeof selfRating === 'string') {
      try {
        return JSON.parse(selfRating);
      } catch (e) {
        console.error('Error parsing selfRating in SelfRating component:', e);
        return {};
      }
    }
    return selfRating;
  }, [selfRating]);

  const getColor = (value) => {
    if (value < 50) {
      return '#FF6B6B';
    } else if (value < 70) {
      return '#FFD166';
    } else {
      return '#4CAF50';
    }
  };

  return (
    <div className="w-full flex flex-col justify-between self-rating-section p-6 rounded-lg shadow-lg" style={{ backgroundColor: "#1E1F1F" }}>
      <div className="flex items-center mb-4 relative">
        <h2 className="text-2xl text-orange font-a4 mr-2">Self-Rating</h2>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 flex-grow">
        {Object.keys(parsedSelfRating).map((attribute) => (
          <div key={attribute} className="flex flex-col my-2">
            <span className="text-md" style={{ color: "gray" }}>
              {attribute.charAt(0).toUpperCase() + attribute.slice(1)}
            </span>
            {isEditing ? (
              <input
                type="range"
                min="0"
                max="100"
                value={parsedSelfRating[attribute]}
                onChange={(e) => handleSliderChange(attribute, e.target.value)}
                className="mt-2 accent-blue"
              />
            ) : (
              <div className="slider-container relative w-full" style={{ height: "3px" }}>
                <span className="absolute right-0 -top-5 text-sm text-white">
                  {parsedSelfRating[attribute]}
                </span>
                <div className="flex flex-row align-end h-2 bg-gray-700" style={{ height: "3px" }}>
                  <div
                    className="absolute top-2 left-0"
                    style={{ 
                      width: `${parsedSelfRating[attribute]}%`, 
                      height: "3px", 
                      backgroundColor: getColor(parsedSelfRating[attribute]) 
                    }}
                  ></div>
                  <div
                    className="absolute top-2 left-0 h-full"
                    style={{
                      width: `${100 - parsedSelfRating[attribute]}%`,
                      left: `${parsedSelfRating[attribute]}%`,
                      backgroundColor: "gray",
                      height: "3px"
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelfRating;