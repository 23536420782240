import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthProvider';
function EmailCounter({ currentCount, tokens=0, nextStep }) {
  const { role } = useAuth();
  const componentRef = useRef(null);
  const maxEmails = tokens;

  useEffect(() => {
    if (currentCount >= maxEmails) {
      componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentCount, maxEmails]);

  let nextButton;
  if (currentCount >= 1) {
    nextButton = (
      <div className="flex">
        <button onClick={nextStep} className="bg-orange text-black p-2 font-black">
          NEXT <FontAwesomeIcon icon={faArrowAltCircleRight} size="xl" />
        </button>
      </div>
    );
  } else {
    nextButton = <></>;
  }

  return (
    <>
      <div className="flex justify-end gap-2" ref={componentRef}>
        <div className="text-white font-a4 flex items-end border-solid border-b-2 border-orange px-2">
          <span className="text-7xl">{currentCount}</span>
          <span className="text-2xl">/</span>
          {(role === "premium user") ? <span className="text-2xl">&#8734;</span> : <span className="text-2xl">{maxEmails}</span>}
        </div>
        {nextButton}
      </div>
    </>
  );
}

export default EmailCounter;
