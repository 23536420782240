import React, {useRef, useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import defaultImage from '../../images/pfp.png';

const ProfileHeader = ({ profile, isEditing, handleChange, toggleEdit, handleFileChange, saveProfile, cancelEdit, setProfile }) => {
  const imgRef = useRef(null);
  const [isHorizontalAdjustable, setIsHorizontalAdjustable] = useState(true);
  const [isVerticalAdjustable, setIsVerticalAdjustable] = useState(true);

  useEffect(() => {
    if (imgRef.current) {
      const imgWidth = imgRef.current.naturalWidth;
      const imgHeight = imgRef.current.naturalHeight;
      const containerWidth = imgRef.current.clientWidth;
      const containerHeight = imgRef.current.clientHeight;

      if (imgWidth / imgHeight > containerWidth / containerHeight) {
        setIsHorizontalAdjustable(true);
        setIsVerticalAdjustable(false);
      } else {
        setIsHorizontalAdjustable(false);
        setIsVerticalAdjustable(true);
      }
    }
  }, [profile.photoUrl, profile.profilePicture]);

  const handlePositionChange = (type, value) => {
    setProfile({ ...profile, [type]: value });
  };

  const renderProfilePhoto = () => (
    <div className="profile-photo-container">
      <img 
        ref={imgRef}
        src={profile?.photoUrl || profile?.profilePictureUrl || defaultImage}  
        alt="Profile" 
        className="profile-photo w-64 h-64 rounded-full object-cover mb-3" 
        style={{ objectPosition: `${profile.horizontalPosition}% ${profile.verticalPosition}%`}}
      />
    </div>
  );

  const renderProfileInfo = () => (
    <>
      <h1 className="text-4xl text-orange font-bold font-a4">{profile?.fullname}</h1>
      <div className="inline">
        <span className="font-bold text-blue">School: </span>
        <span className="text-lg text-white ml-1">{profile?.currentSchool}</span>
      </div>
      <div className="inline">
        <span className="font-bold text-blue">Club Team: </span>
        <span className="text-lg text-white ml-1">{profile?.travelClub}</span>
      </div>
      <div className="inline">
        <span className="font-bold text-blue">Commitment: </span>
        <span className="text-lg text-white ml-1">{profile?.commitment ? profile.commitment : "Uncommitted"}</span>
      </div>
    </>
  );

  const renderEditControls = () => (
    <>
      {isVerticalAdjustable && (
        <input 
          type="range" 
          min="0" 
          max="100" 
          value={profile.verticalPosition} 
          onChange={(e) => handlePositionChange('verticalPosition', e.target.value)} 
          className="absolute sm:right-3/4 w-24 h-64 transform -rotate-90 w-8/12 accent-blue"
        />
      )}
      {renderProfilePhoto()}
      {isHorizontalAdjustable && (
        <input 
          type="range" 
          min="0" 
          max="100" 
          value={profile.horizontalPosition} 
          onChange={(e) => handlePositionChange('horizontalPosition', e.target.value)} 
          className="w-64 mt-4 w-9/12"
        />
      )}
      <div className="text-white mt-4">
        <label htmlFor="profilePicture" className="block text-white">Upload Profile Picture</label>
        <input 
          type="file"
          name="profilePicture"
          accept="image/*" 
          id="profilePicture"
          onChange={(e) => handleFileChange(e, "profilePicture")} 
          className="w-10/12 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none"
        />
      </div>
      <label target="fullname" className="text-white">Name</label>
      <input
        type="text"
        name="fullname"
        value={profile.fullname}
        onChange={handleChange}
        className="mt-2 p-2 w-10/12 text-orange bg-black rounded-md border border-gray focus:outline-none"
      />
      <label target="currentSchool" className="text-white">School</label>
      <input
        type="text"
        name="currentSchool"
        value={profile.currentSchool}
        onChange={handleChange}
        className="mt-2 p-2 w-10/12 text-orange bg-black rounded-md border border-gray focus:outline-none"
      />
      <label target="travelCLub" className="text-white">Club Team</label>
      <input
        type="text"
        name="travelClub"
        value={profile.travelClub}
        onChange={handleChange}
        className="mt-2 p-2 w-10/12 text-orange bg-black rounded-md border border-gray focus:outline-none"
      />
      <label target="commitment" className="text-white">Commitment</label>
      <input
        type="text"
        name="commitment"
        value={profile?.commitment}
        onChange={handleChange}
        className="mt-2 p-2 w-10/12 text-orange bg-black rounded-md border border-gray focus:outline-none"
      />
      <div className="text-white mt-4">
        Upload Transcript
        <br />
        <input 
          type="file" 
          name="transcript"
          accept=".pdf,.png,.jpg,.jpeg" 
          onChange={(e) => handleFileChange(e, "transcript")} 
          className="w-10/12 p-2 text-orange bg-black rounded-md border border-gray focus:outline-none"
        />
      </div>
      <div className="flex justify-between w-full mt-4">
        <button
          className="bg-blue text-black py-2 px-4 rounded-md transition-transform transition-colors duration-200 hover:scale-105 hover:bg-orange-500"
          onClick={saveProfile}
        >
          Save Changes
        </button>
        <button
          className="bg-red-500 text-white py-2 px-4 rounded-md transition-transform transition-colors duration-200 hover:scale-105 hover:bg-red-600"
          onClick={cancelEdit}
        >
          Cancel
        </button>
      </div>
    </>
  );

  return (
    <div className="flex-shrink-0 w-full md:w-1/3 flex flex-col items-center md:items-start relative">
      {!isEditing && (
        <button 
          onClick={toggleEdit} 
          className="absolute -top-5 -left-5 mt-2 mr-2 text-white bg-blue-500 rounded-full p-2 hover:bg-orange-500 transition-transform duration-200"
          aria-label="Edit Profile"
        >
          <FontAwesomeIcon icon={faPenToSquare} style={{ color: 'orange' }} />
        </button>
      )}
      {isEditing ? renderEditControls() : renderProfilePhoto()}
      {!isEditing && renderProfileInfo()}
    </div>
  );
};

export default ProfileHeader;
