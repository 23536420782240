import React from 'react';
import {Link} from 'react-router-dom';
import MobileEmailSignIn from '../components/auth/MobileEmailSignIn';
import Girl from '../images/girl.png'
import GirlTransparent from '../images/girl-transparent.png'

const Login = () => {
    return (
        <div className="min-h-screen bg-pitch-black text-white flex flex-col lg:flex-row items-center justify-center lg:justify-between relative px-[10%]">
            {/* Desktop view for large screen sizes */}
            <div className="hidden lg:flex lg:flex-col lg:w-[380px] lg:items-center lg:justify-center gradient-top-to-bottom lg:h-screen relative z-10">
                <div className="flex flex-col gap-4 relative top-32 w-[80%] " >
                    <h2 className='text-5xl font-a4 text-wrap text-orange'>Create New Account</h2>
                    <span className='text-black text-xl'>Join our community and start your recruitment journey today!</span>
                    <Link to="/signup" className='bg-orange font-a4 text-white w-fit font-semibold rounded-xl text-nowrap py-1 px-4'>Create Account</Link>
                </div>
                <img src={GirlTransparent} alt="Torq Logo" className="relative h-[80%] top-24" />
            </div>

            {/* Mobile and desktop view */}
            <div className="bg-[#000000] z-20 lg:w-[40%] flex flex-col justify-center items-center rounded-lg lg:mx-auto ">
                <MobileEmailSignIn />
            </div>
        </div>
    );
};

export default Login;
