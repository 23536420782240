import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay, faVideo, faCameraRetro } from '@fortawesome/free-solid-svg-icons'

function MembershipInformation() {
  return (
    <div className="bg-black text-white p-4 flex flex-col gap-8">
        <div className="flex flex-col items-center gap-8 text-center">
            <span className="text-4xl font-semibold">Choose the Membership That Fits You Best</span>
            <span className="text-xl font-thin w-1/2 text-center">Enjoy unlimited access to livestreams, highlights, and recruiting benefits. All with our <span className="text-orange">Premium Plan</span></span>
        </div>
        <div className="flex flex-col lg:flex-row justify-center gap-16">
            <div className="flex flex-col gap-2">
                <FontAwesomeIcon icon={faCirclePlay} size="4x"/>
                <div className="my-4 text-white text-center">
                    <span className="text-6xl font-bold">$0</span>
                    <span className="text-md">/month</span>
                </div>
                <div className="text-center">
                    Basic Member
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-xl font-thin">Unlimited Live Stream Access</span>
                    <span className="text-xl font-thin">Custom Player Profile</span>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <FontAwesomeIcon icon={faVideo} size="4x" />
                <div className="my-4 text-white text-center">
                    <span className="text-6xl font-bold">$29.99</span>
                    <span className="text-sm text-gray-600">/month</span>
                </div>
                <div className="text-center">
                    Premium Member
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-xl font-thin">Unlimited Personalized Highlights</span>
                    <span className="text-xl font-thin">College Connect Access</span>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <FontAwesomeIcon icon={faCameraRetro} size="4x"/>  
                <div className="my-4 text-white text-center">
                    <span className="text-6xl font-bold">$4.99</span>
                    <span className="text-sm text-gray-600">/per video</span>
                </div>
                <div className="text-center">
                    Personalized Highlight
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-xl font-thin">1 Personalized Highlight Video</span>
                    <span className="text-xl font-thin">Upload up to 5 video clips</span>
                </div>
            </div>
            
            
        </div>
        <div className="flex justify-center pt-16">
            <Link to="/memberships" className="bg-orange text-white border-solid border rounded-full p-2 cursor-pointer">View Memberships</Link>
        </div>
    </div>
  )
}

export default MembershipInformation