import ProfileInformation from '../components/profile/ProfileInformation';
import VideoFeed from '../components/profile/VideoFeed';
function Profile() {

  return (
    <div className="py-24" style={{backgroundColor: "#151616"}}>
        {/* <div className="bg-white text-black max-w-2xl lg:max-w-7xl mx-auto p-4 border-solid border border-orange rounded-lg flex flex-col gap-8"> */}
          <ProfileInformation />
          {/* <div className="border-solid border border-gray-700"></div>
          <VideoFeed/>
        </div> */}
    </div>
  );
}

export default Profile