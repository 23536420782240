import React, {useState} from 'react';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import axios from 'axios';

const GmailAuth = ({ emailIsAuthenticated, setEmailIsAuthenticated, setAccessToken, setUserEmail }) => {
  const [error, setError] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setEmailIsAuthenticated(true);
      console.log('code response:', codeResponse);
      const accessToken = codeResponse.access_token;
      setAccessToken(accessToken);
      fetchUserProfile(accessToken);
    },
    onError: (error) => {
      console.log('Login Failed:', error);
      setError('Login Failed. Please try again.');
    },
    scope: 'https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/userinfo.email',
    access_type: 'offline',
    cancel_on_tap_outside: true
  });

  const fetchUserProfile = async (token) => {
    try {
      const response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      const email = response.data.email;
      setUserEmail(email);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Error fetching user profile');
    }
  };

  const handleLogout = () => {
    googleLogout();
    setEmailIsAuthenticated(false);
    setAccessToken('');
    setUserEmail('');
    setError(null);
    console.log('Logout Success');
  };

  return (
    <div>
      {error && <p className="text-red-500">{error}</p>}
      {emailIsAuthenticated ? (
        <button
          onClick={handleLogout}
          className="gsi-material-button bg-[#EF8D31] text-white hover:bg-orange-600 py-2 px-4 rounded"
        >
          Logout
        </button>
      ) : (
        <button
          onClick={login}
          className="gsi-material-button bg-white border border-gray-300 rounded flex items-center px-4 py-2"
        >
          <div className="gsi-material-button-icon mr-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              style={{ display: 'block', width: '18px', height: '18px' }}
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              />
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              />
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              />
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              />
              <path fill="none" d="M0 0h48v48H0z" />
            </svg>
          </div>
          <span className="gsi-material-button-contents text-black">Sign in with Google</span>
        </button>
      )}
    </div>
  );
};

export default GmailAuth;
