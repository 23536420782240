
const InformationItem = ({ label, value }) => (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2">
        <span className="font-bold">{label}: </span>
        <span className="font-normal">{value}</span>
      </div>
    </div>
  );

  export default InformationItem;