import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthProvider';
import videoFile from '../../videos/prospect-video.mp4';
import BuyPremiumForm from './BuyPremiumForm';

const BuyPremiumSubscription = () => {
  const { role } = useAuth();

  if (role === 'premium') {
    return (
      <div className="bg-black min-h-screen flex flex-col items-center pt-10 text-white">
        <div className="w-full max-w-4xl p-4">
          <div className="flex flex-col bg-gray-800 p-8 rounded-lg shadow-orange mb-8">
            <div className="p-4">
              <h2 className="text-4xl font-bold mb-4 text-center">You Already Have Premium Access</h2>
              <p className="text-lg md:text-xl leading-relaxed text-center">
                You already have access to all the premium features. Thank you for being a valued member!
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-black min-h-screen flex flex-col items-center pt-10 text-white">
      <div className="w-full max-w-4xl p-4">
        <div className="flex flex-col bg-gray-800 p-8 rounded-lg shadow-orange mb-8">
          <div className="p-4">
            <h2 className="text-4xl font-bold mb-4 text-center">Maximize Your Recruitment Opportunities</h2>
            <p className="text-lg md:text-xl leading-relaxed text-center">
              For only $29.99 per month, our premium service empowers you to create unlimited highlight tapes featuring your standout performances. Our user-friendly software helps you craft professional-quality videos that set you apart and capture the attention of scouts and recruiters.
              <br /><br />
              Our College Connect feature is designed to help you find the best college fit for your athletic and academic goals. Gain access to personalized coaching and scouting insights, making your college search more efficient and effective.
              <br /><br />
              Take the next step towards your dream college with our comprehensive tools and services.
            </p>
          </div>
          <div className="flex justify-center p-4">
            <video 
              src={videoFile} 
              autoPlay 
              loop 
              muted 
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>

          <BuyPremiumForm />
        </div>
      </div>
    </div>
  );
};

export default BuyPremiumSubscription;
