import React, {useEffect, useState} from 'react'
import Loading from '../utilities/Loading'
import TORQicon from '../../images/icons/Torq-Logo-TightCrop.png'
import CovinaIcon from '../../images/logos/partners/covina.png'
import BeachCityIcon from '../../images/logos/partners/beachcity.png'
import WindwardIcon from '../../images/logos/partners/windward.png'
import CubsScoutIcon from '../../images/logos/partners/cubs-scout.png'
import CaliforniaIcon from '../../images/logos/partners/california.png'
import CampbellHallIcon from '../../images/logos/partners/campbell-hall.png'
import { Link } from 'react-router-dom'

function Information() {
    const [isLoading ] = useState(false);
    const [error] = useState(null);

  return (
    <>
      {isLoading ? ( // Optional: display loading message
          <Loading />
        ) : error ? ( // Optional: display error message
          <p>Error fetching livestreams: {error.message}</p>
        ) : (
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-8 justify-start items-start">
                  <h1 className="text-5xl md:text-7xl font-bold">Partnered Organizations</h1>
                  <div className="border w-full "/>
                  <div className="flex flex-wrap justify-center items-center gap-4 p-2 bg-[#ffffff] rounded">
                    <Link to="https://www.youtube.com/@torqsports" className="border-solid border-orange ">
                      <img src={TORQicon} alt="TORQ Logo" className="size-32" />
                    </Link>
                    <Link to="https://www.youtube.com/@torqcovina" className="border-solid border-orange">
                      <img src={CovinaIcon} alt="TORQ Logo" className="size-32" />
                    </Link>
                    <Link to="/teams/bcvc" className="border-solid border-orange ">
                      <img src={BeachCityIcon} alt="TORQ Logo" className="size-32" />
                    </Link>
                    <Link to="https://seasoncast.com/TORQCHVikings" className="border-solid border-orange ">
                      <img src={CampbellHallIcon} alt="TORQ Logo" className="size-32" />
                    </Link>
                    <Link to="https://seasoncast.com/TORQWindward" className="border-solid border-orange ">
                      <img src={WindwardIcon} alt="TORQ Logo" className="size-32" />
                    </Link>
                    <Link to="https://seasoncast.com/TORQCubs" className="border-solid border-orange ">
                      <img src={CubsScoutIcon} alt="TORQ Logo" className="size-32" />
                    </Link>
                    <Link to="https://seasoncast.com/TORQsports" className="border-solid border-orange ">
                      <img src={CaliforniaIcon} alt="TORQ Logo" className="size-32" />
                    </Link>
                  </div>
              </div>
            </div>
        )}
    </>
    
  )
}

export default Information