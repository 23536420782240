import React, { useEffect, useState, useRef } from 'react';
import { useMultistepContext } from '../../contexts/MultistepProvider';
import GmailAuth from '../email/GmailAuth';
import VideoUpload from './VideoUpload'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useAuth } from '../../contexts/AuthProvider';

const clientId = process.env.REACT_APP_GMAIL_CLIENT_ID;

function MessageTemplate() {

  const { 
    handleSubjectChange, 
    handleSignatureChange, signature, message, subject,
    handleIncludeCoachRecommendationsChange,
    handleAttachHighlightChange,
    handleIncludeTorqLinkChange,
    includeCoachRecommendations,
    attachHighlight,
    includeTorqLink,
    schoolNameList, 
    submitCollegeConnect,
    setGmailAccessToken,
    selectedSport,
    setMessage
  } = useMultistepContext();

  const {user} = useAuth();
  let { fullname, gradYear, position, secondaryPosition, currentSchool, fromState } = JSON.parse(user);

  fullname = `${fullname ? fullname : "[Full Name]"}`
  currentSchool = `${currentSchool ? currentSchool : "[Current School]"}`
  fromState = `${fromState ? fromState : "[State]"}`
  position = `${position ? position : "[Primary Position]"}`
  secondaryPosition = `${secondaryPosition ? secondaryPosition : "[Secondary Position]"}`

  const message_templates = {
    "Baseball":
      `Dear Coach {COACH_LAST_NAME},\n\nMy name is ${fullname}, and I am a senior at ${currentSchool} in [City], ${fromState}. I am excited to express my interest in joining the `
      + "{UNIVERSITY_NAME} baseball team. Your program's reputation for excellence both on the field and in the classroom aligns perfectly with my goals as a student-athlete. "
      + "\n\nAs a versatile player who excels as both a pitcher and an infielder, I have developed a strong athletic foundation with impressive statistics, "
      + "including a [specific stat, e.g., 1.85 ERA] and [another specific stat, e.g., .375 batting average]. My commitment to continuous improvement "
      + "is evident in my disciplined training regimen and consistent performance."
      + "\n\nAcademically, I maintain a high GPA while challenging myself with advanced courses. My strong work ethic extends to both my athletic and academic "
      + "pursuits, ensuring I am prepared for the demands of college life. Coaches and teachers alike commend my leadership skills and good character, " 
      + "which I demonstrate through participation in community service and school clubs. I pride myself on my sports IQ and ability to anticipate plays, "
      + "which have earned me recognition as a smart, strategic player. While my competitive nature drives me to give my best effort in every practice and game, my "
      + "hustle and determination inspire my teammates to do the same. I am eager to bring my maturity, honesty, and ambition to the {UNIVERSITY_NAME} "
      + "baseball program. I have attached my athletic resume and highlight video for your review. I look forward to discussing how I can contribute to "
      + "your team's success. "
      + "Thank you for your time and consideration.",
    
    "Football":
      `Dear Coach {COACH_LAST_NAME},\n\nMy name is ${fullname}, and I am a senior at ${currentSchool} in [City], ${fromState}. I am writing to express my interest in joining `
      + "the {UNIVERSITY_NAME} football team. Your program's success and commitment to developing well-rounded student-athletes make it an ideal fit "
      + `for my ambitions.\n\nAs a versatile player who excels as both a ${position} and a ${secondaryPosition}, `
      + "I have developed a strong athletic foundation with impressive statistics, including [specific stats, e.g., 2,500 passing yards and 25 touchdowns "
      + "this season]. My commitment to continuous improvement is evident in my disciplined training regimen and consistent performance."
      + "\n\nAcademically, I maintain a high GPA while challenging myself with advanced courses. My strong work ethic extends to both my athletic and academic "
      + "pursuits, ensuring I am prepared for the demands of college life. Coaches and teachers alike commend my leadership skills and good character, "
      + "which I demonstrate through participation in community service and school clubs.\n\nI pride myself on my sports IQ and ability to anticipate plays, "
      + "which have earned me recognition as a smart, strategic player. My competitive nature drives me to give my best in every practice and game, "
      + "and my hustle and determination inspire my teammates to do the same.\n\nI am eager to bring my maturity, honesty, and ambition to the "
      + "{UNIVERSITY_NAME} football program. I have attached my athletic resume and highlight video for your review. I look forward to discussing "
      + "how I can contribute to your team's success. "
      + "\n\nThank you for your time and consideration.",
    
    "Men's Volleyball": 
      `Dear Coach {COACH_LAST_NAME},\n\nMy name is {PLAYER_NAME}, and I am a senior at ${currentSchool} in [City], ${fromState}. I am writing to express my strong interest in `
      + "joining the {UNIVERSITY_NAME} men's volleyball team. I have been following your program and am impressed by your team's achievements "
      + "and the academic excellence at {UNIVERSITY_NAME}. As a versatile player who excels as both an outside hitter and a libero, I have "
      + "developed a strong athletic foundation with impressive statistics, including [specific stats, e.g., a hitting percentage of .350 "
      + "and an average of 3.5 digs per set]. My commitment to continuous improvement is evident in my disciplined training regimen and consistent "
      + "performance. Academically, I maintain a high GPA while challenging myself with advanced courses. My strong work ethic extends to both "
      + "my athletic and academic pursuits, ensuring I am prepared for the demands of college life. Coaches and teachers alike commend my leadership "
      + "skills and good character, which I demonstrate through participation in community service and school clubs. I pride myself on my sports IQ "
      + "and ability to anticipate plays, which have earned me recognition as a smart, strategic player. My competitive nature drives me to give my "
      + "best in every practice and game, and my hustle and determination inspire my teammates to do the same. I am eager to bring my maturity, "
      + "honesty, and ambition to the {UNIVERSITY_NAME} men's volleyball program. I have attached my athletic resume and highlight video "
      + "for your review. I look forward to discussing how I can contribute to your team's success. "
      + "\n\nThank you for your time and consideration.",
    
    "Women's Volleyball":
      `Dear Coach {COACH_LAST_NAME},\n\nMy name is ${fullname}, and I am a senior at ${currentSchool} in [City], ${fromState}. I am writing to express my interest in joining `
      + "the {UNIVERSITY_NAME} women's volleyball team. Your program's reputation for excellence both on the court and in the classroom aligns "
      + "perfectly with my goals as a student-athlete.\n\nAs a versatile player who excels as both a setter and an outside hitter, I have developed "
      + "a strong athletic foundation with impressive statistics, including [specific stats, e.g., an average of 10 assists per set and a hitting "
      + "percentage of .320]. My commitment to continuous improvement is evident in my disciplined training regimen and consistent performance. "
      + "\n\nAcademically, I maintain a high GPA while challenging myself with advanced courses. My strong work ethic extends to both my athletic and "
      + "academic pursuits, ensuring I am prepared for the demands of college life. Coaches and teachers alike commend my leadership skills and "
      + "good character, which I demonstrate through participation in community service and school clubs.\n\nI pride myself on my sports IQ and "
      + "ability to anticipate plays, which have earned me recognition as a smart, strategic player. My competitive nature drives me to give my "
      + "best in every practice and game, and my hustle and determination inspire my teammates to do the same.\n\nI am eager to bring my maturity, "
      + "honesty, and ambition to the {UNIVERSITY_NAME} women's volleyball program. I have attached my athletic resume and highlight video "
      + "for your review. I look forward to discussing how I can contribute to your team's success. "
      + "\n\nThank you for your time and consideration.",
    
    "Women's Beach Volleyball": 
      `Dear Coach {COACH_LAST_NAME},\n\nMy name is ${fullname}, and I am a senior at {HIGH_SCHOOL} in [City], ${fromState}. I am writing to express `
      + "my strong interest in "
      + "joining the {UNIVERSITY_NAME} women's beach volleyball team. Your program's reputation for excellence both on the court and in the "
      + "classroom aligns perfectly with my goals as a student-athlete.\n\nAs a versatile player who excels in various positions on the court, "
      + "I have developed a strong athletic foundation with impressive statistics, including [specific stats, e.g., a hitting percentage of "
      + ".350 and an average of 3.5 digs per set]. My commitment to continuous improvement is evident in my disciplined training regimen and "
      + "consistent performance.\n\nAcademically, I maintain a high GPA while challenging myself with advanced courses. My strong work ethic "
      + "extends to both my athletic and academic pursuits, ensuring I am prepared for the demands of college life. Coaches and teachers "
      + "alike commend my leadership skills and good character, which I demonstrate through participation in community service and school "
      + "clubs.\n\nI pride myself on my sports IQ and ability to anticipate plays, which have earned me recognition as a smart, strategic player. "
      + "My competitive nature drives me to give my best in every practice and game, and my hustle and determination inspire my teammates "
      + "to do the same.\n\nI am eager to bring my maturity, honesty, and ambition to the {UNIVERSITY_NAME} women's beach volleyball program. "
      + "I have attached my athletic resume and highlight video for your review. I look forward to discussing how I can contribute to "
      + "your team's success. \
      \n\nThank you for your time and consideration.",
  
    "Women's Basketball":
      "Dear Coach {COACH_LAST_NAME},\n\n"
      + `My name is ${fullname}, and I am a senior at ${currentSchool} in [City], ${fromState}. I am writing to express my interest in joining `
      + "the {UNIVERSITY_NAME} women's basketball team. Your program's reputation for excellence both on the court and in the classroom "
      + "aligns perfectly with my goals as a student-athlete.\n\nAs a versatile player who excels as both a guard and a forward, I have "
      + "developed a strong athletic foundation with impressive statistics, including [specific stats, e.g., an average of 15 points, 8 assists, "
      + "and 4 steals per game]. My commitment to continuous improvement is evident in my disciplined training regimen and consistent performance. "
      + "\n\nAcademically, I maintain a high GPA while challenging myself with advanced courses. My strong work ethic extends to both my athletic "
      + "and academic pursuits, ensuring I am prepared for the demands of college life. Coaches and teachers alike commend my leadership skills "
      + "and good character, which I demonstrate through participation in community service and school clubs.\n\nI pride myself on my sports IQ "
      + "and ability to anticipate plays, which have earned me recognition as a smart, strategic player. My competitive nature drives me to give "
      + "my best in every practice and game, and my hustle and determination inspire my teammates to do the same. I am eager to bring my maturity, "
      + "honesty, and ambition to the {UNIVERSITY_NAME} women's basketball program. I have attached my athletic resume and highlight video for your "
      + "review. I look forward to discussing how I can contribute to your team's success. "
      + "\n\nThank you for your time and consideration.",
    
    "Men's Basketball":
      "Dear Coach {COACH_LAST_NAME},\n\n"
      + `My name is ${fullname}, and I am a senior at ${currentSchool} in [City], ${fromState}. I am writing to express my interest in joining the `
      + "{UNIVERSITY_NAME} men's basketball team. Your program's reputation for excellence both on the court and in the classroom aligns perfectly "
      + "with my goals as a student-athlete.\n\nAs a versatile player who excels as both a guard and a forward, I have developed a strong athletic "
      + "foundation with impressive statistics, including [specific stats, e.g., an average of 20 points, 7 assists, and 5 rebounds per game]. "
      + "My commitment to continuous improvement is evident in my disciplined training regimen and consistent performance.\n\nAcademically, I "
      + "maintain a high GPA while challenging myself with advanced courses. My strong work ethic extends to both my athletic and academic pursuits, "
      + "ensuring I am prepared for the demands of college life. Coaches and teachers alike commend my leadership skills and good character, which "
      + "I demonstrate through participation in community service and school clubs.\n\nI pride myself on my sports IQ and ability to anticipate plays, "
      + "which have earned me recognition as a smart, strategic player. My competitive nature drives me to give my best in every practice and game, "
      + "and my hustle and determination inspire my teammates to do the same.\n\nI am eager to bring my maturity, honesty, and ambition to the "
      + "{UNIVERSITY_NAME} men's basketball program. I have attached my athletic resume and highlight video for your review. I look forward to "
      + "discussing how I can contribute to your team's success. "
      + "\n\nThank you for your time and consideration.",
  }

  const [emailIsAuthenticated, setEmailIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');  
  const messageTextareaRef = useRef(null);

  const sport = selectedSport.replace(/-/g, ' '); // Convert sportName with "-" back to spaces

  const dynamicVariables = {
    COACH_LAST_NAME: "Coach's Last Name",
    UNIVERSITY_NAME: "University Name"
  };


  useEffect(() => {
    if (message_templates[sport]) {
      setMessage(message_templates[sport])
    }
  }, [sport, setMessage]);


  const renderPreview = (text, signature) => {
    const fullMessage = `${text}\n\n${signature}`;
    return fullMessage.split(/(\{[^}]+\}|\[[^\]]+\]|\n)/g).map((part, index) => {
      if (part === '\n') {
        return <br key={index} />;
      }
      
      const variableName = part.slice(1, -1);
  
      // Check for square brackets
      if (part.startsWith('[') && part.endsWith(']')) {
        return (
          <span key={index} className="text-orange font-bold underline">
            {part}
          </span>
        );
      }
      
      if (part.startsWith('{') && part.endsWith('}') && dynamicVariables[variableName]) {
        return (
          <span key={index} className="text-blue underline">
            {dynamicVariables[variableName]}
          </span>
        );
      }
      
      return part;
    });
  };

  // Adjust the height of the textarea based on its content
  const adjustTextareaHeight = () => {
    const textarea = messageTextareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight(); // Adjust height on mount
  }, [message]); // Also adjust height whenever the message content changes

  

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="bg-[#000000] text-white text-xl flex flex-col gap-2 px-20 py-10">
        <div className="w-full lg:w-[75%] text-wrap flex flex-col gap-2">
          <h1 className="text-5xl font-bold mb-2 mt-20">Email Engine</h1>
          <span>Edit the message you want to send to all the universities. Your message will be addressed to the coach associated with the email we have on record.</span>
          <span className="text-blue text-3xl font-semibold">Instructions</span>
          <span className="text-blue text-xl font-normal">You will be able to direct your message to each school by typing these placeholders anywhere in your message.</span>
          <ul className="text-white list-disc list-inside">
            <li><strong>{'{COACH_LAST_NAME}'}</strong> - Will be replaced with the coach's last name.</li>
            <li><strong>{'{UNIVERSITY_NAME}'}</strong> - Will be replaced with the university's name.</li>
            <li><strong>*Note:</strong> Text highlighted <span className="text-orange">orange</span> will <u><strong>NOT</strong></u> be automatically replaced and should instead be replaced by the user.</li>
          </ul>
          <span className="text-blue text-2xl font-semibold">Selected Schools</span>
          <div className="flex flex-wrap gap-2">
            {schoolNameList.map((school, index) => (
              <span key={index} className="border-solid border border-orange text-orange font-bold p-2 text-lg rounded-full">{school}</span>
            ))}
          </div>
          <div className="mt-1 mb-1">
            <VideoUpload />
          </div>
        </div>
        <div className="flex flex-col bg-black bg-opacity-50 text-white rounded-lg">
          <div className="border-solid border-b border-blue p-2">
            <label htmlFor="subject" className="text-blue text-md">Subject</label> 
            <input 
              value={subject} 
              onChange={handleSubjectChange} 
              type="text" 
              name="subject" 
              id="subject" 
              maxLength={50} 
              className="text-white p-2 rounded-lg text-xl bg-black bg-opacity-50 w-full"
            />
          </div>

          <div className="p-2 border-solid border-b border-blue">
            <label htmlFor="signature" className="text-blue">Signature</label>
            <textarea 
              value={signature}
              onChange={handleSignatureChange}
              type="text" 
              name="signature" 
              id="signature" 
              rows={6} 
              maxLength={100} 
              className="text-white w-full p-2 text-xl rounded-lg bg-black bg-opacity-50" 
            />
          </div>   

          <div className="p-2 border-solid border-b border-blue flex flex-col md:flex-row gap-4">
            <div className="w-full md:w-1/2">
              <label htmlFor="message" className="mb-2 text-blue">Message</label> 
              <textarea 
                value={message} 
                onChange={(e) => {
                  setMessage(e.target.value); // Update the message state
                  adjustTextareaHeight(); // Adjust height when typing
                }}
                name="message" 
                id="message" 
                ref={messageTextareaRef}
                style={{resize: "none"}}
                className="text-white rounded-lg p-2 text-xl bg-black bg-opacity-50 w-full mt-2"
              />
            </div>


            {/* Preview Area */}
            <div className="w-full md:w-1/2">
              <span className="text-blue text-md">Message Preview</span>
              <div className="text-white rounded-lg p-2 text-xl bg-black bg-opacity-50 w-full mt-2">
                {renderPreview(message, signature)}
              </div>
            </div>
          </div>
          
          <div className="flex flex-col gap-2 p-2">
            <span className="text-blue">Extra</span>
            <div className="flex justify-between bg-black rounded-lg">
              <div className='flex flex-col gap-2 p-2 text-xl'>
                <label htmlFor="coach-recommendations">Include coach recommendations</label>
                {attachHighlight ? <label htmlFor="highlight">Attach your highlight</label> : <></> }
                <label htmlFor="torq-link">Include TORQ Profile Link</label>
              </div>

              <div className='flex flex-col gap-2 p-2'>
                <input 
                  type="checkbox" 
                  name="coach-recommendations" 
                  id="coach-recommendations" 
                  className="size-8 cursor-pointer"
                  checked={includeCoachRecommendations}
                  onChange={handleIncludeCoachRecommendationsChange}
                />
                {attachHighlight ?
                <input 
                  type="checkbox" 
                  name="highlight" 
                  id="highlight" 
                  className="size-8 cursor-pointer"
                  checked={attachHighlight}
                  onChange={handleAttachHighlightChange}
                /> : <></> }
                <input
                  type="checkbox"
                  name="torq-link"
                  id="torq-link"
                  className="size-8 cursor-pointer"
                  checked={includeTorqLink}
                  onChange={handleIncludeTorqLinkChange}
                />
              </div>
            </div>
          </div>
          {!emailIsAuthenticated && (
            <div className="google-signin-container h-20 bg-green w-full p-4 flex justify-center align-center">
              <GmailAuth setAccessToken={setGmailAccessToken} setUserEmail={setUserEmail} setEmailIsAuthenticated={setEmailIsAuthenticated}
              emailIsAuthenticated={emailIsAuthenticated} /> 
            </div>
            )}
          {emailIsAuthenticated && (
            <input type="submit" value="Send"  onClick={() => {submitCollegeConnect()}}
            className="bg-blue text-black border-solid border border-blue 
            rounded-lg p-2 text-2xl cursor-pointer hover:bg-[#000000] hover:text-blue duration-200 ease-in-out" /> 
          )}
          </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default MessageTemplate;