import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

function Terms() {
  return (
    <div className="pt-20 bg-black">
    <div className="p-6 max-w-4xl mx-auto bg-white shadow-md rounded-md pt-20">
      <h1 className="text-2xl font-bold mb-4">Terms of Service for TORQ Sports</h1>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">1. Introduction</h2>
        <p>Welcome to TORQ Sports. By accessing our website or using our services, you agree to be bound by these Terms of Service.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">2. Services Offered</h2>
        <p>TORQ Sports provides video creation services for sports recruiting highlights and tools to help student athletes select colleges based on their preferences and profiles.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">3. User Accounts</h2>
        <p>Users must register to create an account. You are responsible for maintaining the confidentiality of your account and password.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">4. User Responsibilities</h2>
        <p>You agree to provide accurate and complete information about yourself and to update this information as necessary.</p>
        <p>You agree to use our services for lawful purposes only and not to engage in any conduct that could damage, disable, or impair our services.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">5. Intellectual Property</h2>
        <p>All content provided on our platform, including text, graphics, logos, and software, is the property of TORQ Sports or its content suppliers and protected by intellectual property laws.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">6. Termination</h2>
        <p>We may terminate or suspend your account and bar access to the services immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever, including without limitation if you breach the Terms.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">7. Disclaimer of Warranties</h2>
        <p>Our services are provided on an "as is" and "as available" basis. TORQ Sports makes no warranties, expressed or implied.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">8. Limitation of Liability</h2>
        <p>In no event shall TORQ Sports, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of our services.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">9. Governing Law</h2>
        <p>These Terms shall be governed by the laws of the jurisdiction in which our company is based, without regard to its conflict of law provisions.</p>
      </div>

      <h1 className="text-2xl font-bold mt-8 mb-4">Privacy Policy for TORQ Sports</h1>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">1. Introduction</h2>
        <p>At TORQ Sports, we respect your privacy and are committed to protecting it through our compliance with this policy.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">2. Information Collection and Use</h2>
        <p>We collect several types of information from and about users of our services, including personal information that is provided by the users (such as name, email address, and athletic performance data), which is necessary for creating your recruiting profile and video.</p>
        <br />
        <strong>Google Account Information:</strong>
        <p>If you choose to sign in using your Google account, please note that we do not store any information related to your Google account other than the email address, if it was used to sign up for our services. Your TORQ Sports account remains separate from your Google account.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">3. Data Sharing</h2>
        <p>We do not sell or rent personal information to third parties. We may share personal information with third parties to maintain and improve our services, including third parties who assist us in processing information.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">4. Data Security</h2>
        <p>We employ measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">5. Rights of Users</h2>
        <p>You may inquire as to the nature of the personal information stored or processed about you by TORQ Sports. You have the right to request access, correction, amendment, or deletion of your personal information.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">6. Changes to Our Privacy Policy</h2>
        <p>It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you through a notice on the website's home page.</p>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold">7. Contact Information</h2>
        <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
      </div>
      <div className="flex flex-col">
        <div className='flex items-center gap-2'>
            <FontAwesomeIcon icon={faEnvelope} className="text-xl" style={{color: "rgb(0, 0, 0)",}}/>
            <span>contact@torqsports.com</span>
        </div>
        <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faPhone} className="text-xl" style={{color: "rgb(0, 0, 0)",}}/>
            <span>+1 (123) 456-7890</span>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Terms;

