import SocialMediaContent from '../components/social-media/SocialMediaContent'
import Upgrade from '../components/utilities/Upgrade'
import LoginPopup from '../components/popups/LoginPopup'
import YoutubeVideoList from '../components/social-media/YoutubeVideoList'
import IconGrid from '../components/social-media/IconGrid'

function SocialMedia() {
    return (
        <div className="bg-black text-white flex flex-col justify-center py-24 gap-16">
          <SocialMediaContent />
          <IconGrid />
          {/* <YoutubeVideoList /> */}
          {/* <LoginPopup /> */}
        </div>
    )
}

export default SocialMedia