import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthProvider';


// Utility function to format date
const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const month = date.getMonth() + 1; // Months are zero-indexed
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};


const Billing = () => {
    const { token, userId } = useAuth();
    const [orderHistory, setOrderHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrderHistory = async () => {
            try {
                const response = await axios.get('/api/payment/order-history', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'X-User-Id': userId
                    }
                });
                setOrderHistory(response.data);
            } catch (error) {
                setError('Error fetching order history.');
                console.error('Error fetching order history:', error);
            } finally {
                setLoading(false );
            }
        };

        fetchOrderHistory();
    }, [token]);

    if (loading) {
        return <div className="text-center text-white">Loading...</div>;
    }

    if (error) {
        return <div className="text-center text-red-500">{error}</div>;
    }

    return (
        <div className="bg-black min-h-screen flex flex-col items-center pt-10 text-white">
            <div className="w-full max-w-4xl p-4">
                <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
                    <h2 className="text-4xl font-bold mb-8 text-center">Order History</h2>
                    {orderHistory.length > 0 ? (
                        <table className="min-w-full bg-gray-900 rounded-lg">
                            <thead className="text-left">
                                <tr>
                                    <th className="py-2 px-4 border-b-2 border-gray-700">Order ID</th>
                                    <th className="py-2 px-4 border-b-2 border-gray-700">Payment Date</th>
                                    <th className="py-2 px-4 border-b-2 border-gray-700">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderHistory.map(order => (
                                    <tr key={order.id} className="hover:bg-gray-700">
                                        <td className="py-2 px-4 border-b border-gray-700">{order.orderId}</td>
                                        <td className="py-2 px-4 border-b border-gray-700">{formatDate(order.paymentDate)}</td>
                                        <td className="py-2 px-4 border-b border-gray-700">${order.amount.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-center text-gray-400">No order history found.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Billing;