import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const InitialStep = ({ formData, setFormData, handleSubmit, nextStep }) => {
  const [errors, setErrors] = useState({});
  const [isPlayer, setIsPlayer] = useState(false);

  const types = [
    "College Coach",
    "HS Coach",
    "Player",
    "Parent",
    "Scout",
    "Fan",
    "Travel Team Coach",
    "Agent"
  ];

  const sports = [
    'Men\'s Volleyball', 'Women\'s Volleyball', 'Beach Volleyball', 'Baseball', 'Softball',
    'Men\'s Basketball', "Women\'s Basketball", "Football"
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'affiliation' && value === 'Player') {
      setIsPlayer(true);
    }
    setFormData({
      ...formData,
      [name]: value
    });
      validateField(name, value);
  };

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$|^\d{3}-\d{3}-\d{4}$/;

    switch (name) {
      case 'firstName':
        if (!value) {
          fieldErrors.firstName = 'First name is required';
        } else {
          delete fieldErrors.firstName;
        }
        break;
      case 'lastName':
        if (!value) {
          fieldErrors.lastName = 'Last name is required';
        } else {
          delete fieldErrors.lastName;
        }
        break;
      case 'email':
        if (!value && !formData.phone) {
          fieldErrors.email = 'Either email or phone number is required';
          fieldErrors.phone = 'Either email or phone number is required';
        } else if (value && !emailRegex.test(value)) {
          fieldErrors.email = 'Invalid email format';
        } else {
          delete fieldErrors.email;
          delete fieldErrors.phone;
        }
        break;
      case 'phone':
        if (!value && !formData.email) {
          fieldErrors.phone = 'Either email or phone number is required';
          fieldErrors.email = 'Either email or phone number is required';
        } else if (value && !phoneRegex.test(value)) {
          fieldErrors.phone = 'Invalid phone number format';
        } else {
          delete fieldErrors.phone;
          delete fieldErrors.email;
        }
        break;
      case 'password':
        if (!value) {
          fieldErrors.password = 'Password is required';
        } else if (formData.confirmPassword && value !== formData.confirmPassword) {
          fieldErrors.password = 'Passwords do not match';
          fieldErrors.confirmPassword = 'Passwords do not match';
        } else {
          delete fieldErrors.password;
          delete fieldErrors.confirmPassword;
        }
        break;
      case 'confirmPassword':
        if (!value) {
          fieldErrors.confirmPassword = 'Confirm Password is required';
        } else if (formData.password && value !== formData.password) {
          fieldErrors.password = 'Passwords do not match';
          fieldErrors.confirmPassword = 'Passwords do not match';
        } else {
          delete fieldErrors.password;
          delete fieldErrors.confirmPassword;
        }
        break;
      case 'username':
        if (!value) {
          fieldErrors.username = 'Username is required';
        } else {
          delete fieldErrors.username;
        }
        break;
      case 'terms':
        if(!value) {
          fieldErrors.terms = 'You must agree to the terms and privacy policy';
        } else {
          delete fieldErrors.terms;
        }
        break;
      default:
        break;
    }

    setErrors(fieldErrors);
  };

  const validate = () => {
    let formErrors = { ...errors };

    if (!formData.firstName) {
      formErrors.firstName = 'First name is required';
    }
    if (!formData.lastName) {
      formErrors.lastName = 'Last name is required';
    }
    if (!formData.email && !formData.phone) {
      formErrors.email = 'Either email or phone number is required';
      formErrors.phone = 'Either email or phone number is required';
    }
    if (formData.password !== formData.confirmPassword) {
      formErrors.password = 'Passwords do not match';
      formErrors.confirmPassword = 'Passwords do not match';
    }
    if (!formData.username) {
      formErrors.username = 'Username is required';
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  return (
    <>
      <div className="bg-white flex flex-col gap-4 px-8 py-24">
        <h1 className='text-3xl md:text-4xl lg:text-5xl'>Create an Account</h1>
        <p className="font-thin">Already have an account? <Link to="/login" className="text-orange font-normal hover:underline">Login here</Link></p>
        <h2 className="text-xl sm:text-2xl">Step 1: Basic Information</h2>
        <div className="border-solid border-4 border-black"></div>
        
        <section className="flex flex-col lg:flex-row lg:justify-between">
          <div className="flex flex-col lg:w-2/3">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="bg-white pl-2 border-solid border border-black rounded-md font-normal"
              required
            />
            {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
          </div>
        </section>

        <section className="flex flex-col lg:flex-row lg:justify-between">
          <div className="flex flex-col lg:w-2/3">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="pl-2 bg-white border-solid border border-black rounded-md font-normal"
              required
            />
            {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
          </div>
        </section>

        <section className="flex flex-col lg:flex-row lg:justify-between">
          <div className="flex flex-col lg:w-2/3">
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
              className="pl-2 bg-white border-solid border border-black rounded-md font-normal"
              pattern="\d{3}-\d{3}-\d{4}"
              title="Please enter a valid phone number in the format XXX-XXX-XXXX"
            />
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
          </div>
        </section>

        <section className="flex flex-col lg:flex-row lg:justify-between">
          <div className="flex flex-col lg:w-2/3">
            <label htmlFor="email">Email:</label>
            <input 
              type="email"
              name="email" 
              id="email"
              value={formData.email} 
              onChange={handleChange} 
              className="pl-2 bg-white border-solid border border-black rounded-md font-normal" 
              required
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>
        </section>

        <section className="flex flex-col lg:flex-row lg:justify-between">
          <div className="flex flex-col lg:w-2/3">
            <label htmlFor="username">Username:</label>
            <input 
              type="text"
              name="username" 
              id="username"
              value={formData.username} 
              onChange={handleChange} 
              className="pl-2 bg-white border-solid border border-black rounded-md font-normal" 
              required
            />
            {errors.username && <p className="text-red-500 text-sm">{errors.username}</p>}
          </div>
        </section>

        <section className="flex flex-col lg:flex-row lg:justify-between">
          <div className="flex flex-col">
            <label htmlFor="password">Password:</label>
            <input 
              type="password" 
              name="password" 
              id="password"
              value={formData.password} 
              onChange={handleChange} 
              className="pl-2 bg-white border-solid border border-black rounded-md font-normal" 
              required
            />
          </div>
        </section>
        <section className="flex flex-col lg:flex-row lg:justify-between">
          <div className="flex flex-col">
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input 
              type="password"
              name="confirmPassword" 
              id="confirmPassword"
              value={formData.confirmPassword} 
              onChange={handleChange} 
              className="pl-2 bg-white border-solid border border-black rounded-md font-normal" 
              required
            />
            {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
          </div>
        </section>
          
       <section className="flex flex-col lg:flex-row lg:justify-between">
        <label htmlFor="affiliation" className="mr-4">Who are you?</label>
        <select
          required
          name="affiliation"
          id="affiliation"
          value={formData.affiliation}
          onChange={handleChange}
          className="bg-white py-1 pl-2 border-solid border border-black rounded-md font-normal lg:w-2/3"
        >
          <option value="" disabled>Please select one</option>
          {types.map((personType, index) => (
            <option key={index} value={personType}>
              {personType}
            </option>
          ))}
        </select>
      </section>
      <section className="flex flex-col lg:flex-row lg:justify-between">
        <label htmlFor="sport" className="mr-4">Choose your sport:</label>
        <select
          id="sport"
          name="sport"
          value={formData.sport}
          onChange={handleChange}
          className="bg-white py-1 pl-2 border-solid border border-black rounded-md font-normal lg:w-2/3"
          required
        >
          <option value="" disabled>Select a sport</option>
          {sports.map((sport) => (
            <option key={sport} value={sport}>
              {sport}
            </option>
          ))}
        </select>
      </section>
      <section className="flex flex-col lg:flex-row lg:justify-between">
        <label htmlFor="accountType" className="mr-4">What type of account would you like to sign up for?</label>
        <select
          required
          name="accountType"
          id="accountType"
          value={formData.accountType}
          onChange={handleChange}
          className=" bg-white border-solid border border-black rounded-md font-normal py-1 lg:w-1/4"
        >
          <option value="" disabled>Please select one</option>
          <option value="free">Free</option>      
          {/* <option value="premium">Premium</option> */}
        </select>
      </section>

      <section className="flex flex-col lg:flex-row lg:justify-between">
        <label htmlFor="accountType" className="mr-4">TEAM CODE:</label>
        <input
          type="text"
          name="teamCode"
          id="teamCode"
          value={formData.teamCode}
          onChange={handleChange}
          className="bg-white py-1 pl-2 border-solid border border-black rounded-md font-normal lg:w-1/4"
        />
       </section>
      

      <h1 className='text-3xl md:text-4xl lg:text-5xl mt-8'>Terms and Privacy</h1>
      <h2 className="text-xl sm:text-xl w-full text-nowrap pb-4 border-solid border-b-8 border-black">Step 2: Review terms and policies.</h2>
      <section className="flex flex-col items-start gap-4 text-wrap font-light">

      <p>
        By choosing "I Agree" below you agree to TORQ Sports{' '}
        <span className="font-bold">Terms of Use</span>
        {' '}and{' '}
        <span className="font-bold">Privacy Policy</span>
      </p>
      <p>You can view our terms at these links
      {' '}<Link to="/terms" target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">
         Terms of Use
        </Link>{' '} and {' '}
        <Link to="/terms" target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">
          Privacy Policy
        </Link>.
        </p>

        <div className="flex items-center">
          <input
            type="radio"
            id="agree"
            name="terms"
            value="agree"
            checked={formData.terms === 'agree'}
            onChange={handleChange}
            className="mr-2"
            required
          />
          <label htmlFor="agree" className="text-gray-700">I Agree</label>
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            id="disagree"
            name="terms"
            value="disagree"
            checked={formData.terms === 'disagree'}
            onChange={handleChange}
            className="mr-2"
            required
          />
          <label htmlFor="disagree" className="text-gray-700">I Disagree</label>
        </div>
      </section>
      <section>
        {errors.terms && <p className="bg-red-500 text-white text-xl">{errors.terms}</p>}
      </section>
      <div className="flex justify-between items-center">
        {formData.accountType === "premium" ? 
          <button onClick={() => {
            if (validate()) nextStep();
          }} className='border-solid border border-black  px-12 py-2 hover:bg-black hover:text-orange duration-200 ease-in'>
            Next
          </button>
          :
          <button onClick={(e) => {
            if (validate()) handleSubmit(e);
          }} className='border-solid border border-black  px-12 py-2 hover:bg-black hover:text-orange duration-200 ease-in'>
            Sign Up
          </button>
        }
        </div>
      </div>
   </>
  );
}

export default InitialStep;
