import React, {useContext} from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth} from '../../contexts/AuthProvider'; // Adjust the import based on your actual context file

const ProtectedRoute = ({ element: Component }) => {
  const { authentication } = useAuth();

  return authentication ? Component : <Navigate to="/login" />;
};

export default ProtectedRoute;
