import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faDesktop, faMobileAlt, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import Loading from '../components/utilities/Loading';

function VideoAutomation() {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [title, setTitle] = useState('');
    const [sizes, setSizes] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const { token } = useAuth();

    const handleOpenClick = () => {
        setIsOpen(true);
        setSelectedFiles([]);
        setErrorMessage(null);
    };

    const handleCloseClick = () => {
        setIsOpen(false);
        setSelectedFiles([]);
        setErrorMessage(null);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        const allowedExtensions = ['mp4', 'mov'];
        let newFiles = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const extension = file.name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(extension)) {
                setErrorMessage('Invalid file format. Please upload only MP4 or MOV videos.');
                setSelectedFiles([]);
                return;
            }

            newFiles.push(file);
        }

        setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
        setErrorMessage(null);
    };

    const handleSizeChange = (event) => {
        const value = event.target.value;
        setSizes((prevSizes) =>
            prevSizes.includes(value)
                ? prevSizes.filter((size) => size !== value)
                : [...prevSizes, value]
        );
    };

    const handleUpload = async () => {
        setIsLoading(true);

        if (selectedFiles.length === 0) {
            setErrorMessage('Please select at least one video file to upload.');
            setIsLoading(false);
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append('videos', file);
        });
        formData.set('title', title);
        formData.set('sizes', sizes.join(',')); // Pass sizes as a comma-separated string
        formData.set('original_date', new Date().toISOString());
        formData.set('last_modified_date', new Date().toISOString());

        try {
            const response = await fetch('/api/upload/premium-video/', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                setSelectedFiles([]);
                setTitle('');
                setSizes([]);
                setIsOpen(false);
                setErrorMessage(null);
            } else {
                throw new Error('Failed to upload video(s).');
            }
        } catch (error) {
            setErrorMessage('An error occurred while uploading the video(s). Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const shortenFileName = (fileName) => {
        const maxLength = 20; 
        if (fileName.length <= maxLength) {
            return fileName;
        } else {
            const firstPart = fileName.slice(0, maxLength / 2);
            const lastPart = fileName.slice(-(maxLength / 2) + 2);
            return `${firstPart}...${lastPart}`;
        }
    };

    return (
        <div className="bg-black text-white flex flex-col items-center justify-center py-8 gap-4 min-h-screen">
            {!isLoading ? (
            <div className="max-w-lg w-full bg-gray-800 rounded-lg p-6">
                <h1 className="text-3xl text-center mb-4">Automate Video Creation</h1>
                <div className="flex flex-col gap-4">
                    <label className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded cursor-pointer w-full text-center border-solid border-2">
                        <FontAwesomeIcon icon={faFileUpload} size="lg" className="mr-2" />
                        Upload Videos
                        <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            accept=".mp4,.mov"
                            className="hidden"
                        />
                    </label>
                    {selectedFiles.length > 0 &&
                        <div className="flex flex-col gap-2 mt-2">
                            {selectedFiles.map((file, index) => (
                                <div key={index} className="flex items-center justify-between border-solid border-2 border-blue bg-sky-800 py-2 px-4 rounded">
                                    <span className="text-white text-center text-xl">{shortenFileName(file.name)}</span>
                                </div>
                            ))}
                        </div>
                    }
                    <input
                        type="text"
                        placeholder="Enter title"
                        value={title}
                        onChange={handleTitleChange}
                        className="bg-gray-700 text-black py-2 px-4 rounded w-full"
                    />
                    <div className="flex flex-col sm:flex-row gap-4 w-full">
                        <div className="flex-1">
                            <label className={`${sizes.includes("Desktop") ? "bg-sky-700 bg-opacity-60" : "bg-transparent"} flex items-center justify-center bg-gray-700 text-blue-500 border-2 border-blue-800 py-4 rounded cursor-pointer w-full hover:bg-sky-800 hover:bg-opacity-60 duration-300 ease-in-out`}>
                                <FontAwesomeIcon icon={faDesktop} size="lg" className="mr-2" />
                                <input type="checkbox" value="Desktop" onChange={handleSizeChange} className="hidden" />
                                Desktop
                            </label>
                        </div>
                        <div className="flex-1">
                            <label className={`${sizes.includes("Mobile") ? "bg-sky-700 bg-opacity-60" : "bg-transparent"} flex items-center justify-center bg-gray-700 text-blue-500 border-2 border-blue-800 py-4 rounded cursor-pointer w-full hover:bg-sky-800 hover:bg-opacity-60 duration-300 ease-in-out`}>
                                <FontAwesomeIcon icon={faMobileAlt} size="lg" className="mr-2" />
                                <input type="checkbox" value="Mobile" onChange={handleSizeChange} className="hidden" />
                                Mobile
                            </label>
                        </div>
                        <div className="flex-1">
                            <label className={`${sizes.includes("Tablet") ? "bg-sky-700 bg-opacity-60" : "bg-transparent"} flex items-center justify-center bg-gray-700 text-blue-500 border-2 border-blue-800 py-4 rounded cursor-pointer w-full hover:bg-sky-800 hover:bg-opacity-60 duration-300 ease-in-out`}>
                                <FontAwesomeIcon icon={faTabletAlt} size="lg" className="mr-2" />
                                <input type="checkbox" value="Tablet" onChange={handleSizeChange} className="hidden" />
                                Tablet
                            </label>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <button onClick={handleUpload} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full">
                            Upload
                        </button>
                        <button onClick={handleCloseClick} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full">
                            Cancel
                        </button>
                    </div>
                    {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
                </div>
            </div>
            ) : (
                <Loading isLoading={isLoading} />
            )}
        </div>
    );
}

export default VideoAutomation;
