import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthProvider'

function PlayerProfileForm() {
  const navigate = useNavigate();
  const { user, token ,userId } = useAuth();
  const { sport } = JSON.parse(user);
  const [error, setError] = useState(null);
  // Dropdown options for sports, positions, etc. (Replace with your data)
  const positions = {
    'Baseball': ['P', 'C', '3B', 'SS', '2B', '1B', 'OF'],
    'Softball': ['P', 'C', '3B', 'SS', '2B', '1B', 'OF'],
    'Mens Basketball': ['PG', 'SG', 'SF', 'PF', 'C'],
    'Womens Basketball': ['PG', 'SG', 'SF', 'PF', 'C'],
    'Mens Volleyball': ['S', 'OH', 'MB', 'RS', 'L'],
    'Womens Volleyball': ['S', 'OH', 'MB', 'RS', 'L'],
    'Volleyball': ['S', 'OH', 'MB', 'RS', 'L'],
    'Football': ['QB', 'RB', 'WR', 'TE', 'OT', 'OG', 'C', 'DE', 'DT', 'LB', 'CB', 'S'],
    'Soccer': ['GK', 'RB', 'CB', 'LB', 'CDM', 'CM', 'CAM', 'RW', 'LW', 'ST'],
    'Mens Tennis': ['Singles', 'Doubles'],
    'Womens Tennis': ['Singles', 'Doubles'],
    'Mens Golf': ['Golfer'],
    'Womens Golf': ['Golfer'],
    'Mens Cross Country': ['Runner'],
    'Womens Cross Country': ['Runner'],
    'Mens Track & Field': ['Runner', 'Jumper', 'Thrower'],
    'Womens Track & Field': ['Runner', 'Jumper', 'Thrower'],
    'Mens Swimming': ['Swimmer'],
    'Womens Swimming': ['Swimmer'],
    'Mens Diving': ['Diver'],
    'Womens Diving': ['Diver'],
    'Mens Water Polo': ['Driver', 'Defender', 'Utility', 'Keeper'],
    'Womens Water Polo': ['Driver', 'Defender', 'Utility', 'Keeper'],
    'Mens Lacrosse': ['A', 'M', 'D', 'G'],
    'Womens Lacrosse': ['A', 'M', 'D', 'G'],
    'Mens Ice Hockey': ['F', 'D', 'G'],
    // ... other sports and positions
  };
  const sports = [
    'Baseball', 'Softball', 'Mens Basketball', 'Womens Basketball',
    'Mens Tennis', 'Womens Tennis', 'Mens Volleyball', 'Beach Volleyball',
    'Womens Beach Volleyball', 'Football', 'Soccer', 'Mens Golf', 'Womens Golf',
    'Mens Cross Country', 'Womens Cross Country', 'Mens Track & Field',
    'Womens Track & Field', 'Mens Swimming', 'Womens Swimming', 'Mens Diving',
    'Womens Diving', 'Mens Water Polo', 'Womens Water Polo', 'Mens Lacrosse',
    'Womens Lacrosse', 'Mens Ice Hockey', 'Womens Ice Hockey', 'Field Hockey',
  ];
  const gradYears = [...Array(6)].map((_, i) => new Date().getFullYear() + i);
  const states = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", 
    "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", 
    "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", 
    "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", 
    "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", 
    "New Hampshire", "New Jersey", "New Mexico", "New York", 
    "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", 
    "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", 
    "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", 
    "West Virginia", "Wisconsin", "Wyoming"
  ];

  const gender = ['male', 'female']

  const [playerData, setPlayerData] = useState({
    fromCity: "",
    fromState: "",
    currentSchool: "",
    gradYear: "",
    position: "",
    secondaryPosition: "",
    height: 0,
    weight: 0,
    handedness: "",
    travelClub: "",
    gender: "",
    gpa: ""
  });

  const formatHeight = (height) => {
    if (!height || isNaN(height)) {
      console.log("Invalid height:", height);
      return ''; // Return an empty string or default value
    }

    const feet = Math.floor(height / 12);
    const inches = height % 12;

    return `${feet}' ${inches}"`;
  }

  // Take the form data and update the user's profile in the database.
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    console.log("Form Data: ", playerData)

    // Needs to return a user ID
    //createUser(playerData);
    const response = await fetch("/api/edit-profile/player-profile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ data:playerData })
    });

    //console.log("Response: \n", response);

    navigate(`/profile/${userId}`);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'gpa') {
        if (value === '') {
            setError(''); // Clear the error if the value is empty
        } else if (isNaN(value)) {
            setError('GPA must be a number');
            return; // Exit early if there's an error
        } else {
            const gpaRegex = /^[0-4](\.\d{0,2})?$/;
            if (!gpaRegex.test(value)) {
                setError('GPA must be in the format x.xx');
                return; // Exit early if there's an error
            } else {
                setError(''); // Clear the error if the value is valid
            }
        }
    } 

    // Update state
    setPlayerData((prevState) => ({ ...prevState, [name]: value }));
  }
  
  return (
    <div className='flex flex-col gap-4'>

    <section className="flex flex-col">
      <label htmlFor="fromCity" >
            What city are you from?
        </label>
        <input
            type="text"
            id="fromCity"
            name="fromCity"
            value={playerData.fromCity}
            onChange={handleChange}
            className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
        />
    </section>
    <section className="flex flex-col">
      <label htmlFor="fromState" >
          What state are you from?
      </label>
      <select
          id="fromState"
          name="fromState"
          value={playerData.fromState} // Access nested state value
          onChange={handleChange}
          className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
          >
          <option value="">Select State</option>
          {states.map((state) => (
          <option key={state} value={state}>
              {state}
          </option>
          ))}
      </select>
    </section>
    <section className="flex flex-col lg:justify-between">
      <label htmlFor="currentSchool">
        Current School:
      </label>
      <input
        type="text"
        id="currentSchool"
        name="currentSchool"
        value={playerData.currentSchool}
        onChange={handleChange}
        className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
      />
    </section>
    <section className="flex flex-col lg:justify-between">
    <label htmlFor="gradYear">
          Graduation Year:
        </label>
        <select
          id="gradYear"
          name="gradYear"
          value={playerData.gradYear}
          onChange={handleChange}
          className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
        >`
        <option value="">Select Year</option>
        {gradYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </section>

    <section className="flex flex-col lg:justify-between">
      <label htmlFor="position">
        Position:
      </label>
      <select
        id="position"
        name="position"
        value={playerData.position}
        onChange={handleChange}
        className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
        disabled={!sport} // Disable position dropdown if no sport selected
      >
        <option value="">Select Position</option>
        {sport &&
          positions[sport].map((position) => (
            <option key={position} value={position}>
              {position}
            </option>
          ))}
      </select>
    </section>
    <section className="flex flex-col lg:justify-between">
      <label htmlFor="secondaryPosition">
        Secondary Position:
      </label>
      <select
        id="secondaryPosition"
        name="secondaryPosition"
        value={playerData.secondaryPosition}
        onChange={handleChange}
        className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
        disabled={!sport} // Disable position dropdown if no sport selected
      >
        <option value="">Select Position</option>
        {sport &&
          positions[sport].map((position) => (
            <option key={position} value={position}>
              {position}
            </option>
          ))}
      </select>
    </section>
    <section className="flex flex-col lg:justify-between">
      <label htmlFor="height">
        Height:
      </label>
      <input
        type="text"
        id="height"
        name="height"
        value={playerData.height}
        onChange={handleChange}
        className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
      />
    </section>
    <section className="flex flex-col lg:justify-between">
      <label htmlFor="weight">
        Weight:
      </label>
      <input
        type="number"
        id="weight"
        name="weight"
        value={playerData.weight}
        onChange={handleChange}
        className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
      />
    </section>
    <section className="flex flex-col lg:justify-between">
      <label htmlFor="handedness">
          Handedness:
      </label>
      <select
          id="handedness"
          name="handedness"
          value={playerData.handedness}
          onChange={handleChange}
          className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
          >
          <option value="">Select Side</option>
          <option key="Right" value="Right">Right</option>
          <option key="Left" value="Left">Left</option>
          <option key="Switch" value="Switch">Switch</option>
      </select>
    </section>

    <section className="flex flex-col lg:justify-between">
      <label htmlFor="gender">
          Gender:
      </label>
      <select
          id="gender"
          name="gender"
          value={playerData.gender}
          onChange={handleChange}
          className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
          >
          <option value="">Select Side</option>
          <option key="male" value="male">Male</option>
          <option key="female" value="female">Female</option>
      </select>
    </section>

    <section className="flex flex-col lg:justify-between">
      <label htmlFor="gpa">
          GPA:
      </label>
      <input
          id="gpa"
          name="gpa"
          value={playerData.gpa}
          onChange={handleChange}
          placeholder="4.0"
          className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
          />
    </section>
            
    <section className="flex flex-col lg:justify-between">
      <label htmlFor="travelClub">
          Club/Travel Team:
      </label>
      <input
          type="text"
          id="travelClub"
          name="travelClub"
          placeholder="TORQ Sports"
          value={playerData.travelClub}
          onChange={handleChange}
          className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
      />
    </section>
      {error && <span className="bg-red-500 text-white text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50">{error}</span>}
      <button type="submit" onClick={handleSubmit} className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 border-solid border-white border-0 focus:ring-white focus:ring-opacity-50 hover:bg-black hover:border-2 duration-200 ease-in hover:border-white hover:text-white">
        Save
      </button>
    </div>
  )
}

export default PlayerProfileForm