import React, {useEffect} from 'react'
import { useAuth } from '../contexts/AuthProvider'
import SportSelection from '../components/college-connect/SportSelection'
import VideoUpload from '../components/college-connect/VideoUpload'
import VideoAutomation from './VideoAutomation'
import CollegeConnect from '../components/college-connect/CollegeConnect'
import PaymentOptions from '../components/college-connect/PaymentOptions'
import MessageTemplate from '../components/college-connect/MessageTemplate'
import Results from '../components/college-connect/Results'
import ComingSoon from '../components/utilities/ComingSoon'
import Signup from './Signup'
import Login from './Login'
import { MultistepProvider, useMultistepContext } from '../contexts/MultistepProvider'


function Multistep() {
  const { authentication } = useAuth()
  const { step, setStep } = useMultistepContext()

  // Infinite Loop bug may be here. Was fixed before
  useEffect(() => {
    if (step === 1 && authentication) {
      setStep(2);
    } 
  }, [step, authentication, setStep]);
  
  switch(step)
    {
        case 2:
            return (<SportSelection />)
        case 3:
            return (<VideoUpload />)
        case 4:
            return (<VideoAutomation />)
        case 5:
            return (<CollegeConnect/>)
        case 6:
            return (<MessageTemplate />)
        case 7:
            return (<PaymentOptions />)
        case 8:
            return (<ComingSoon />)
        default:
            return (<Login />)
    }
}

export default function MultistepPage() {
  return (
    <MultistepProvider>
      <Multistep />
    </MultistepProvider>
  );
}