import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Pages
import Home from "./pages/Home.jsx";
import Partners from './pages/Partners';
import SocialMedia from './pages/SocialMedia';
import Login from './pages/Login';
import Signup from './pages/Signup.jsx';
import Profile from './pages/Profile'
import Success from './pages/Success'
import Edit from './pages/Edit'
import TorqAI from './pages/TorqAI'
import Terms from './pages/Terms'
import Highlights from './pages/Highlights'
import Test from './pages/Test';
import RequestQuote from './pages/RequestQuote';
import GoPremium from './pages/GoPremium.jsx';
import VideoAutomation from './pages/VideoAutomation';
import SearchPlayers from './pages/SearchPlayers.jsx';
import PlayerInformationPage from './pages/PlayerInformationPage.jsx';
import Multistep from './pages/Multistep';
import SendMassEmail from './pages/SendMassEmail';
import BuyTokens from './pages/BuyTokens';
import BeachCity from './pages/BeachCity';


// Components
import Navbar from "./components/utilities/Navbar.jsx";
import Footer from "./components/utilities/Footer.jsx";
import ProtectedRoute  from './components/auth/ProtectedRoute';
import ErrorHandler from './components/utilities/ErrorHandler.jsx'
import ComingSoon from './components/utilities/ComingSoon';
import VerifyEmail from './components/auth/VerifyEmail';
import PaymentSuccess from './components/payment/PaymentSuccess';
import PageWrapper from './components/utilities/PageWrapper';

// Context
import AuthProvider from './contexts/AuthProvider.jsx';
import NavigationListener from './contexts/NavigationListener';


function App() {


  return (
    <AuthProvider>
      <BrowserRouter>
        <NavigationListener>
          <Navbar />
          <PageWrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/buy-tokens" element={<BuyTokens />} />
            <Route path="/college-connect" element={<ProtectedRoute element={<Multistep />} />}/>
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/go-premium" element={<GoPremium />} />
            <Route path="/edit-profile" element={<ProtectedRoute element={<Edit selected="account-information" />} />} />
            <Route path="/error" element={<ErrorHandler />} />
            <Route path="/highlights" element={<Highlights />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/social-media" element={<SocialMedia />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/torq-ai" element={<TorqAI />} />
            <Route path="/request-quote" element={<RequestQuote />} />
            <Route path="/verify-email" element={<ComingSoon />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/video-automation" element={<ComingSoon />} />
            <Route path="/teams/bcvc" element={<BeachCity />} />
            <Route path="/search-players" element={<SearchPlayers />} />
            <Route path="/search-players/:id" element={<PlayerInformationPage />} />
            <Route path="/email-engine" element={<SendMassEmail />} />
          </Routes>
          </PageWrapper>
          <Footer />
        </NavigationListener>
      </BrowserRouter>
    </AuthProvider>
  );
};



export default App;
