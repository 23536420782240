import React from 'react';
import { Link } from 'react-router-dom';

const ErrorHandler = () => {

    return (
        <div className='bg-black text-white flex flex-col justify-center items-center text-wrap gap-4 min-h-screen'>
            <span className="text-2xl">We are working on a fix to this problem! Please try again later.</span>
            <Link to="/" className="underline text-orange">Go back to the home page</Link>
        </div>
    )
}

export default ErrorHandler;